import React, { useState } from "react";
import "../assests/css/navbar/navbar.css"
// import Subheader from "./subheader";
import sattviklogo from "../assests/images/icons/scsg.png"
// import { useLocation } from "react-router-dom";
import satcon from "../assests/images/icons/image.png"
import { NavLink } from "react-router-dom";
// import { useTranslation } from 'react-i18next'
// import indo from "../assests/images/indo/indonesia.png"
// import uk from "../assests/images/indo/uk.png"
// import i18next from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';



const Navbar = () => {
  // const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // useEffect(() => {
  //   let currentlang = localStorage.getItem('lang')
  //   i18next.changeLanguage(currentlang)

  // }, [])

  // const changelang = (l) => {

  //   i18next.changeLanguage(l)
  //   localStorage.setItem('lang', l)
  // };



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>

      <header className="header">
        <div className=' container-fluid language-nav d-flex justify-content-end'>

          <div className="today-image">
            {/* <p className="choose" >CHOOSE LANGUAGE</p> */}
            <div className="social-media-icons-navbar">
              <NavLink to="https://www.facebook.com/profile.php?id=100091746805925">
                <span className="me-4 link-secondary">
                  <i className="ri-facebook-fill icons"></i>
                </span>
              </NavLink>

              <NavLink to="https://x.com/Sattvikid?t=mDeN0UCH_yX-dsZwYmCtpg&s=09">
                <span className="me-4 link-secondary ">
                  <FontAwesomeIcon icon={faXTwitter} className='x-ixon-footer ' />
                </span>
              </NavLink>

              <NavLink to="https://www.instagram.com/sattvikcertificationssg/?igsh=YzVkODRmOTdmMw%3D%3D">
                <span className="me-4 link-secondary">
                  <i className="ri-instagram-fill icons "></i>
                </span>
              </NavLink>

              <NavLink to="https://www.linkedin.com/company/scsg-sg/">
                <span className="me-4 link-secondary">
                  <i className="ri-linkedin-fill icons "></i>
                </span>
              </NavLink>


              <NavLink to="https://www.sattvikconsciousness.com/">
                <img src={satcon}  loading="auto" className="eng-nav-list image-photo" alt="Eng" />
              </NavLink>

            </div>
            {/* <img className='indo-nav' src={indo} onClick={() => changelang('id')} alt="indo" />
            <img src={uk} className="eng-nav" onClick={() => changelang('en')} alt="Eng" />
            <img src={uk} className="eng-nav" onClick={() => changelang('en')} alt="Eng" />
            <img src={uk} className="eng-nav" onClick={() => changelang('en')} alt="Eng" /> */}
          </div>
        </div>



        <nav className="nav container" id="sidebar">

          <div className="nav__data">
            <NavLink to="/" className="nav__logo">
              <img src={sattviklogo} loading="auto" className="saatviklog" alt="logo" />
            </NavLink>

            <div className={`nav__toggle ${isMenuOpen ? "show-icon" : ""}`} id="nav-toggle" onClick={toggleMenu}>
              {isMenuOpen ? (
                <i className="ri-close-line nav__close"></i>
              ) : (
                <i className="ri-menu-line nav__burger"></i>
              )}
            </div>
          </div>


          {/* NAV MENU */}
          <div className={`nav__menu ${isMenuOpen ? "show-menu" : ""}`} id="nav-menu">
            <ul className="nav__list">

              <li className="">
                <NavLink to="/home" className="nav__link " onClick={closeMenu}>Home</NavLink>
              </li>

              {/* about start */}
              <li className="dropdown__item">

                <div className="nav__link ">
                  <p className="drop-down-about-width">About</p> <i className="ri-arrow-down-s-line dropdown__arrow"></i>
                </div>


                <ul className="dropdown__menu">

                  <li>
                    <NavLink to="/whyus" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-group-2-line"></i><p className="drop-down-all-width-process"> Why Us </p>
                    </NavLink>
                  </li>



                  <li>
                    <NavLink to="/Whoweare" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-user-fill"></i><p className="drop-down-all-width-process"> Who We Are </p>
                    </NavLink>
                  </li>




                  <li>
                    <NavLink to="/ourteam" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-team-fill"></i> Our Team
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/services" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-customer-service-fill"></i> Services
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/representative" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-group-line"></i> Representative
                    </NavLink>
                  </li>







                  {/* DROPDOWN SUBMENU */}
                  <li className="dropdown__subitem">
                    <div className="dropdown__link">
                      <i className="ri-edit-2-fill"></i> Apply Now<i className="ri-add-line dropdown__add"></i>
                    </div>

                    <ul className="dropdown__submenu ">
                      <li className="">
                        <NavLink to="https://www.sattvikcertifications.com/" className="dropdown__link" onClick={closeMenu}>
                          <i className="ri-file-list-line"></i> Certification
                        </NavLink>

                      </li>
                    </ul>
                  </li>
                </ul>
              </li>


              {/* about end */}


              {/* company start */}

              {/* DROPDOWN 1 */}
              <li className="dropdown__item">
                <div className="nav__link icon-shift">
                  <p className="drop-down-all-width">Our Company</p> <i className="ri-arrow-down-s-line dropdown__arrow"></i>
                </div>

                <ul className="dropdown__menu">
                  <li>
                    <NavLink to="/ourhistory" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-chat-history-fill"></i><p className="drop-down-all-width-process">Our History</p>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/ourvision" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-team-fill"></i>Our Vision
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/ourfuture" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-projector-2-fill"></i> {t('Our Future')}
                    </NavLink>
                  </li> */}

                  <li>
                    <NavLink to="/operatingcountry" className="dropdown__link " onClick={closeMenu}>
                      <i className="ri-map-pin-fill"></i>Operating Country
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/bussiness" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-survey-line"></i> {t('Business Principle')}
                    </NavLink>
                  </li> */}

                  {/* <li>
                    <NavLink to="/integrity" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-command-line"></i> {t('Integrity')}
                    </NavLink>
                  </li> */}

                  {/* DROPDOWN SUBMENU */}
                  <li className="dropdown__subitem">

                    <ul className="dropdown__submenu">

                    </ul>
                  </li>
                </ul>
              </li>

              {/* company end */}


              {/* carrier start */}
              {/* <li className="dropdown__item">
                <div className="nav__link ">
                  <li>
                    <NavLink to="/asita" className="nav__link" onClick={closeMenu}>{t('ASITA')}</NavLink>
                  </li>
                </div>

                <ul className="dropdown__menu"> */}
              {/* DROPDOWN SUBMENU */}
              {/* <li className="dropdown__subitem">

                    <ul className="dropdown__submenu">

                    </ul>
                  </li>
                </ul>
              </li>
 */}






              <li className="dropdown__item">
                <div className="nav__link ">
                  <p className="drop-down-carrier-width own-custom-width">Resources</p> <i className="ri-arrow-down-s-line dropdown__arrow custom-arrow"></i>
                </div>

                <ul className="dropdown__menu">

                  <li>
                    <NavLink to="/Sattvikscheme" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-user-fill"></i><p className="drop-down-all-width-process">SattvikScheme </p>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/Sattviksingapore" className="dropdown__link" onClick={closeMenu}>
                      <i class="ri-chat-smile-3-line"></i>Sattvik Singapore
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/news" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-newspaper-line"></i> News
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/gallery" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-image-2-line"></i> Gallery
                    </NavLink>
                  </li>








                  {/* DROPDOWN SUBMENU */}
                  <li className="dropdown__subitem">


                    <ul className="dropdown__submenu">

                    </ul>
                  </li>
                </ul>
              </li>




              <li className="dropdown__item">
                <div className="nav__link ">
                  <p className="drop-down-carrier-width own-custom-width">KnowMore</p> <i className="ri-arrow-down-s-line dropdown__arrow custom-arrow"></i>
                </div>

                <ul className="dropdown__menu">
                  {/* <li>
                    <NavLink to="/tourism" className="dropdown__link" onClick={closeMenu}>
                      <i className="ri-newspaper-line"></i> {t('Tourism')}
                    </NavLink>
                  </li> */}


                  <li>
                    <NavLink to="/contactus" className="dropdown__link" onClick={closeMenu}>
                      <i class="ri-contacts-book-2-fill"></i> Contact
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/india&indonesia" className="dropdown__link" onClick={closeMenu}>
                      <i class="ri-school-fill"></i> {t('India/Indonesia')}
                    </NavLink>
                  </li> */}





                  <li className="dropdown__subitem">
                    <div className="dropdown__link">
                      <i className="ri-edit-2-fill"></i>Carrier<i className="ri-add-line dropdown__add"></i>
                    </div>

                    <ul className="dropdown__submenu ">
                      <li className="">
                        <NavLink to="https://e-skvm.org/" className="dropdown__link" onClick={closeMenu}>
                          <i className="ri-file-list-line"></i> SKVM
                        </NavLink>

                      </li>
                    </ul>
                  </li>






                  {/* DROPDOWN SUBMENU */}
                  <li className="dropdown__subitem">

                    <ul className="dropdown__submenu">

                    </ul>
                  </li>
                </ul>
              </li>



            </ul>
          </div>
        </nav>

      </header>


    </>


  )
}

export default Navbar
