import React , {useEffect} from 'react'
import Compsubheader from '../components/training/trainingsubheader'
import Contactuscomponenet from "../components/contactNav/contactnav"


const Contactnav = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

    const Props = {
        titleone: "Contact Us",
        titlesecond: " ",
        titlethird: " ",
        Imgurl: 'https://cdn.dribbble.com/users/1195555/screenshots/10684674/media/ad21d1dffe31f02c296d4b1d499d5b7c.gif',
    };
    return (

        <>
            <section>
                <Compsubheader Props={Props} />
            </section>
            <section>
                <Contactuscomponenet />
            </section>
        </>
    )
}

export default Contactnav