import React , {useEffect} from 'react'
import "../assests/css/training/trainingsubheader.css"
import AOS from "aos"
import 'aos/dist/aos.css'


const Compsubheader = ({Props}) => {
  useEffect(()=>{
 AOS.init({duration:2000})
  },[])

  const styles = {
    position: 'relative',
    backgroundImage: `url(${Props.Imgurl}`,
    height: '50vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (


    <>
      {/* <div className='container-fluid main-conatiner'> */}
        <div className="container   ">
          <div className="row text-margin">
            <div className="col-sm-12 text-center">
              <h3 className='main-heading' data-aos="fade-up-left">{Props.titleone}</h3>
            </div>
          </div>
        </div>

        <div className='container-fluid custom-html' style={styles}>
          <h1 className="training-codex">{Props.titlesecond}</h1>
          <p className="becoming-a-professionel">{Props.titlethird}</p>

        </div>
      {/* </div> */}
    </>
  )
}

export default Compsubheader

