import React , {useEffect} from 'react'
import SATCON from "../components/SATCON/satcon"

const Satconpage  = () => {
  useEffect (() => {
    window.scrollTo(0,0)
    },[])
  return (
    <section>
        <SATCON/>
    </section>

  )
}

export default Satconpage