import React from 'react'
import "../assests/css/codex/codex.css"

const Ayurvedic = () => {
  
  return (
    <>
    <h1 className='color-code-code'>Ayurvedic processed food</h1>
   {/* <h3 className='color-code'>{selectedCertificationId}.Country{industry}{selectedvikram2079id}{selectProfiledetailsid}{subclasssvalue}{microindexvalue}{comissioncosde}</h3> */}
   <p className="color-code-new">A food which is based on the principles of Ayurvedic medicine and focuses on balancing different types of energy within your body, which is said to improve health.</p>
   </>
  )
}

export default Ayurvedic