import React,{useEffect} from 'react'
import "../assests/css/whoweare/wowearesubheader.css"
import "../assests/css/whoweare/whoweareheading.css"
import Whoweareone from "../assests/images/indo/whosinga.jpg"
// import Whowearesecond from "../assests/images/indo/whoweare-minified (2).jpg"
import AOS from "aos"
import 'aos/dist/aos.css'


const Whowearesubheader = () => {
    useEffect(()=>{
        AOS.init({duration : 1000})
    },[])
    return (
        <>

            <div className="  text-dark my-5 text-center ">
                <h1 className="whoweare-css-heading " data-aos="fade-up" data-aos-anchor-placement="top-bottom">Who We Are ?</h1>
                <p className="whoweare-css-heading" data-aos="fade-up" data-aos-anchor-placement="top-bottom">An Introduction to Sattvik Certifications Singapore</p>
            </div>

            <div className="container-fluid gap-0 ">
                <div className="row new-direction">
                    <div className="col-sm-6 full-width-data">
                        <p className='main-content'>
                            <span className='first'>Welcome to the Sattvik Certifications Singapore !!!!</span>
                             We are the Worlds first and leading vegetarian/vegan food & lifestyle certification body.This certification fetches its inspiration from
                            <span className="second"> VEDAS and PURANAS </span>
                            the heritage of India.With the requirement for the new SOP’s and standards in the food supply chain,
                            <span className="second">Sattvik MARK guarantees the trust & Belief for</span>
                             100% vegetarian/vegan Products





                           <span className='first'> “Sattvik Certification in a modern-day furnish”
                            </span> Vegetarian/Vegan food safety regulation and Quality Management System to the vegetarian/vegan consumers which is developed on sound scientific and hardcore Vedic ancient principles to protect consumers from risks of food-borne illness and death.
                            <span className="second"> Adopting industry-leading best practices, food safety regulations are getting increasingly outcome-based and transparent.</span>
                    


                            <span className='first'> As the world’s leading Certification body, </span>
                            we ensure the quality and quantity of commodities conform to the internationally recognized standards in scope.
                            <span className="second"> We research, inspect, verify, and analyse.This is done through ingredient research, supply chain investigation, surveillance sampling, on-site inspections, lab testing, and follow up as dictated by findings to ensure conformity to the Sattvik vegetarian conformity assessment program anywhere in the world.</span>



                            Protecting the Consumer is a primary goal of Sattvik Singapore, the leader in vegetarian/vegan/jain/sattvam/buddhist certification. <span className='first'> The Sattvik certification trademark stands for the independent verification of quality and integrity, and products and services that carry the</span>
                          
                            <span className="second"> “100% Pure” </span>societies of the world & aims at meeting the consumers’ expectations by providing<span className="second"> ‘safe food supply’ </span>,
                            capable enough to meet the basic quality and safety standards and requirements referring to food hygiene, labeling and certification, use of food additives, limits for pesticide residues etc.
                            <span className="second">mark represent unmatched reliability for meeting the strictest of standards.</span>
                         



                        </p>

                    </div>
                    <div className="col-sm-4 new-col-sm4">
                        <div className="container newone-css">
                            <img src={Whoweareone} loading="auto" alt="error" className='new-image' />
                        </div>

                        {/* <div className="container newsecond-css">
                            <img src={Whowearesecond} alt="error" className='new-image' />
                        </div> */}

                    </div>

                </div>
            </div>
        </>
    )
}

export default Whowearesubheader