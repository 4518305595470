import React, { useState } from 'react';
import config from './config.js';
import MessageParser from './MessageParser.js';
import ActionProvider from './ActionProvider.js';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import "../assests/css/bot/bot.css";  

const MyComponent = () => {
  const [showBot, toggleBot] = useState(false);

  return (
    <div>
      {showBot && (
        <div className="floating-chatbot">
          <Chatbot
            config={config}
            actionProvider={ActionProvider}
            messageParser={MessageParser}
          />
        </div>
      )}
      {showBot ? (
  <i className="ri-close-line floating-icon" onClick={() => toggleBot((prev) => !prev)}></i>
) : (
  <i className="ri-discuss-fill floating-icon" onClick={() => toggleBot((prev) => !prev)}></i>
)}

    </div>
  );
};

export default MyComponent;
