
import React, { useState, useEffect, useMemo } from 'react'
import "../assests/css/training/trainingcode.css"
import Brahma from './brahma';
import Daksha from "./Daksha"
import Laghu from "./Laghu"
import Madhya from './Madhya';
// import Postaudit from './postaudit';
// import Kitchen from "./kitchen"
// import Hospitality from './hospitality';
import { useTranslation } from 'react-i18next';
import Rte from "../codex/rte"
import Ayurvedic from "../codex/ayurvedic"
import Jain from "./jains"
import Organic from "../codex/organic"
import Vegan from './vegan';
import Vege from "./vegetarian"
import Satvam from "./sattvam"
import Hospitalitykitechen from './hospitalitykitechen';
import Laundry from './laundry';
import Trainingdata from "./training"
import Pantry from "./pantry"
import Hygin from "./hygin"





const Trainingcode = () => {
    const { t } = useTranslation()


    const Countryname = useMemo(() => [

        { name: "Canada", id: "CA" },
        { name: "India", id: "IN" },
        { name: "Indonesia", id: "ID" },
        { name: "Singapore", id: "SG" },
        { name: "South Africa", id: "ZA" },
    ], []);



    const Sattvikceryfy = useMemo(() => [
        {
            id: "SC",
            name: "Sattvik Certification"
        }

    ], []);

    // const Strainingprogm = useMemo(() => [
    //     {
    //         id: "SCTN",
    //         name: "Sattvik Training"
    //     }

    // ], []);


    const Vikram2079 = useMemo(() => [
        {
            id: "2079",
            name: "Vikram Samvat 2079"
        }

    ], []);


    const TrainingProgram = useMemo(() => [
        {
            id: "F",
            name: "Food",
            code: "2",
            idcode: ".FD"
        },

        {
            id: "H",
            name: "Hospitality",
            code: "2",
            idcode: ".HOS"
        },

        {
            id: "M",
            name: "Marines",
            code: "2",
            idcode: ".MR"
        },

        {
            id: "R",
            name: "Railway",
            code: "2",
            idcode: ".RLY"
        },


        {
            id: "A",
            name: "Agriculture",
            code: "2",
            idcode: ".AGR"
        },


        {
            id: "Dairy",
            name: "Dairy",
            code: "2",
            idcode: ".DRY"
        },


        {
            id: "C",
            name: "Cosmetics",
            code: "2",
            idcode: ".CO"
        },


        {
            id: "T",
            name: "Textile",
            code: "2",
            idcode: ".TX"
        },

        {
            id: "N",
            name: "Nutraceuticals",
            code: "2",
            idcode: ".NTR"
        },




    ], []);


    const Trainingprofile = useMemo(() => [
        {
            id: ".KT",
            code: "F",
            name: "Kitchens",
            newcode: "kktwo"




        },
        {
            id: ".PROC",
            code: "F",
            name: "Processed",
            newcode: "kktwo"

        },

        {
            id: ".KT",
            code: "H",
            name: "Kitchens",
            newcode: "kone"

        },

        {
            id: ".LNDRY",
            code: "H",
            name: "Laundry",
            newcode: "ktwo"

        },

        {
            id: ".HK",
            code: "H",
            name: "House Keeping",
            newcode: "ktwo"

        },

        {
            id: ".TRNG",
            code: "H",
            name: "Training",
            newcode: "kfour"

        },


        {
            id: ".CA",
            code: "M",
            name: "Cargo",
            newcode: "",
            trigger: "mr2",

        },


        {
            id: ".CR",
            code: "M",
            name: "Cruises",
            newcode: "",
            trigger: "mr1",
            newtrigger: "newcrus"

        },

        {
            id: ".BH",
            code: "R",
            name: "Budget Hotels",
            newcode: ""

        },

        {
            id: ".LNGE",
            code: "R",
            name: "Lounge",
            newcode: ""

        },

        {
            id: ".BK",
            code: "R",
            name: "Base Kitchen",
            newcode: ""

        },

        {
            id: ".TR",
            code: "R",
            name: "Train",
            newcode: ""

        },

        {
            id: ".SU",
            code: "R",
            name: "Static Units",
            newcode: ""

        },

        {
            id: "'N/A",
            code: "A",
            name: "N/A",
            newcode: ""

        },


        {
            id: "'N/A",
            code: "Dairy",
            name: "N/A",
            newcode: ""

        },

        {
            id: "'N/A",
            code: "C",
            name: "N/A",
            newcode: ""

        },


        {
            id: "'N/A",
            code: "T",
            name: "N/A",
            newcode: ""

        },


        {
            id: ".PROC",
            name: "Processed",
            code: "N",
            idcode: ".NTR"
        },




    ], []);


    const classname = useMemo(() => [

        //food start
        {
            id: ".RT",
            code: "F",
            name: "RTE",
            newcode: "f1",
            trigger: "match"


        },

        {
            id: ".ARV",
            code: "F",
            name: "Ayurvedic",
            newcode: "f2",
            trigger: "match"

        },


        {
            id: ".ORG",
            code: "F",
            name: "Organic",
            newcode: "f3",
            trigger: "match"

        },

        {
            id: ".VN",
            code: "F",
            name: "Vegan",
            newcode: "f3",
            trigger: "match",


        },

        {
            id: ".VG",
            code: "F",
            name: "Vegetarian",
            newcode: "f3",
            trigger: "match"

        },
        {
            id: ".JN",
            code: "F",
            name: "Jain",
            newcode: "f3",
            trigger: "match",

        },

        {
            id: ".SM",
            code: "F",
            name: "Sattvam",
            newcode: "f3",
            trigger: "match",

        },


        //food End

        //Hospitality Start
        {
            id: ".VG",
            code: "H",
            name: "Vegetarian",
            newcode: "h1",
            trigger: "match",
            newtrigger: "newcrus"


        },


        {
            id: ".VN",
            code: "H",
            name: "Vegan",
            newcode: "h2",
            trigger: "match",
            newtrigger: "newcrus"


        },


        {
            id: ".JN",
            code: "H",
            name: "Jain",
            newcode: "h3",
            trigger: "match",
            newtrigger: "newcrus"


        },

        {
            id: ".SM",
            code: "H",
            name: "Sattvam",
            newcode: "h4",
            trigger: "match",
            newtrigger: "newcrus"


        },


        //Hospitality End

        //Marines Start

        {
            id: "'N/A",
            code: "M",
            name: "N/A",
            newcode: "",
            trigger: "mr2",


        },

      


        //Marines End

        //Railway Start

        {
            id: ".01",
            code: "R",
            name: "Brahma",

        },

        {
            id: ".02",
            code: "R",
            name: "Madhya",

        },

        {
            id: ".03",
            code: "R",
            name: "Laghu",

        },

        {
            id: ".04",
            code: "R",
            name: "Daksha",

        },


        //Railway End

        //Agriculture start

        {
            id: "'N/A",
            code: "A",
            name: "N/A",


        },

        // Agriculture End


        //Dairy start

        {
            id: "'N/A",
            code: "D",
            name: "N/A",

        },



        //Dairt End


        //Cosmetic Start
        {
            id: "'N/A",
            code: "C",
            name: "N/A",

        },

        //Csmetic End

        //Textile Start
        {
            id: "N/A",
            code: "T",
            name: "N/A",

        },

        //Texttile End


        //Nutra start

        {
            id: ".RT",
            code: "N",
            name: "RTE",


        },


        {
            id: ".ARV",
            code: "N",
            name: "Ayurvedic",


        },


        {
            id: ".ORG",
            code: "N",
            name: "Organic",


        },

        {
            id: ".VN",
            code: "N",
            name: "Vegan",


        },

        {
            id: ".VG",
            code: "N",
            name: "Vegetarian",


        },

        {
            id: ".JN",
            code: "N",
            name: "Jain",


        },

        {
            id: ".HRB",
            code: "N",
            name: "Herbal",


        },

        //Nutra End
    ], []);





    const Microclassdata = useMemo(() => [

        {
            id: ".01",
            code: "Brahma",
            name: "Brahma",
            newcode: "",
            trigger: "match",

        },


        {
            id: ".02",
            code: "Madhya",
            name: "Madhya",
            newcode: "",
            trigger: "match",

        },


        {
            id: ".03",
            code: "Laghu",
            name: "Laghu",
            newcode: "",
            trigger: "match",

        },


        {
            id: ".04",
            code: "Daksha",
            name: "Daksha",
            newcode: "",
            trigger: "match",

        },

        {
            id: "'N/A",
            code: "not",
            name: "N/A",
            newcode: "h8",
            trigger: "mr2",

        }

    ], []);


    const Yeardata = useMemo(() => [
        {
            id: "'24",
            code: "onecorrect",
            name: "2024",
        },

    ], [])


    //  setting state for dropdown start
    const [country, setCountry] = useState([]);
    const [newcertification, setnewcertification] = useState([])
    const [Training, setTraining] = useState([])
    const [TrainingProfile, setTrainingProfile] = useState([])
    const [classnamevalue, setclassvalue] = useState([])


    //  setting state for dropdown End

    // setting id START
    const [selectedCountryId, setSelectedCountryId] = useState("");
    const [selectedCertificationId, setCertificationId] = useState("");
    const [selectedvikram2079id, setselectedvikram2079id] = useState("");
    const [selectProfiledetailsid, setselectProfiledetailsid] = useState("")
    const [industry, setIndustry] = useState()
    const [subclasssvalue, setNewsubclassValue] = useState()
    const [microclassValue, setmicroclassvalue] = useState()
    const [microindexvalue, setmicroindexvalue] = useState()
    const [yearcommision, setyearcommision] = useState()
    const [comissioncosde, setnewcommisioncode] = useState()
    const [nacustom, setnacustom] = useState()
    // setting id End


    // visibilty start
    const [dropone, setdropdown1] = useState(false)
    const [droptwo, setdropdowntwo] = useState(false)
    const [dropthree, setdropthree] = useState(false)
    const [dropfour, setdropfour] = useState(false)
    const [dropfive, setdropfive] = useState(false)
    const [dropsix, setdropsix] = useState(false)
    const [dropseven, setdropseven] = useState(false)
    const [dropeight, setdropeight] = useState(false)
    const [dropnine, setdropnine] = useState(false)
    const [dropten, setdropten] = useState(false)



    const [one, setone] = useState(false)
    const [two, settwo] = useState(false)
    const [three, setthree] = useState(false)
    const [four, setfour] = useState(false)
    const [five, setfive] = useState(false)
    const [six, setsix] = useState(false)
    const [seven, setseven] = useState(false)
    const [Hone, sethone] = useState(false)
    const [Htwo, sethtwo] = useState(false)
    const [Hfour, sethfour] = useState(false)
    const [hidevalue, sethidevalue] = useState()
    // visibilty end

    //microclass start

    const [brahmaid, setbrahma] = useState(false)
    const [Madhyaid, setMadhya] = useState(false)
    const [Laghuid, setLaghu] = useState(false)
    const [Dakshaid, setDaksha] = useState(false)
    const [Pantryone, setPantry] = useState(false)
    const [Hyginone, setHyginone] = useState(false)
    const [pantrystatvalue, setpantryvalue] = useState("")
    const [pantryandhygin, setpantryandhygin] = useState("")

    //microclassEnd
    const handleProfilechangesubclass = (e) => {
        setyearcommision(Yeardata)
        const one = ".RT"
        const two = ".ARV"
        const three = ".ORG"
        const Four = ".VN"
        const Five = ".VG"
        const Six = ".JN"
        const Seven = ".SM"
        const Eight = ".HRB"
        const brahmaid = ".01"
        const Madhyaid = ".02"
        const Laghu = ".03"
        const Daksha = ".04"
        const food = ".FD"
        const hosp = ".HOS"
        const crusie = ".CR"

        //hospitality
        const Subclass = e.target.value

        const ConvertintoString = Subclass.split(',')
        // console.log(ConvertintoString)
        const getFirstIndexvalue = ConvertintoString[0]
        const getsecondvalueindex = ConvertintoString[3]
        const lasttrigger = ConvertintoString[4]
        const dtyear = Microclassdata.filter(elem => elem.trigger === lasttrigger)
        setmicroclassvalue(dtyear)
        if (getFirstIndexvalue === one || getFirstIndexvalue === two || getFirstIndexvalue === three || getFirstIndexvalue === Four || getFirstIndexvalue === Five || getFirstIndexvalue === Six || getFirstIndexvalue === Eight) {
            setdropeight(true);
            setdropfive(false);
        }

        if (
            (getFirstIndexvalue === one ||
                getFirstIndexvalue === two ||
                getFirstIndexvalue === three ||
                getFirstIndexvalue === Four ||
                getFirstIndexvalue === Five ||
                getFirstIndexvalue === Six ||
                getFirstIndexvalue === Seven) && (food === hidevalue || hosp === hidevalue)
        ) {
            setdropeight(false);
            setdropfive(true);
        }




        if (crusie === selectProfiledetailsid) {
            setdropten(true)
        }

        setone(false);
        settwo(false);
        setthree(false);
        setfour(false);
        setfive(false);
        setsix(false);
        setseven(false);

        //food start
        if (getFirstIndexvalue === one) {
            setone(true);
        } else if (getFirstIndexvalue === two) {
            settwo(true);
        } else if (getFirstIndexvalue === three) {
            setthree(true);
        } else if (getFirstIndexvalue === Four) {
            setfour(true);
        } else if (getFirstIndexvalue === Five) {
            setfive(true);
        } else if (getFirstIndexvalue === Six) {
            setsix(true);
        } else if (getFirstIndexvalue === Seven) {
            setseven(true);
        }

        // Handle the second value index
        if (getsecondvalueindex === "h1" || getsecondvalueindex === "h2" || getsecondvalueindex === "h3" || getsecondvalueindex === "h4") {
            setone(false);
            settwo(false);
            setthree(false);
            setfour(false);
            setfive(false);
            setsix(false);
            setseven(false);
        } else {
            // console.log("check the error");
        }
        // Other actions you need to perform
        setNewsubclassValue(getFirstIndexvalue);
        //food end


        if (brahmaid === getFirstIndexvalue) {
            setbrahma(true)
            setMadhya(false)
            setLaghu(false)
            setDaksha(false)
            setdropfive(false)
            setdropsix(true)
            // setmicroindexvalue(getnewmicroclassindex)

        } else if (Madhyaid === getFirstIndexvalue) {
            setMadhya(true)
            setbrahma(false)
            setLaghu(false)
            setDaksha(false)
            setdropfive(false)
            setdropsix(true)
            // setmicroindexvalue(getnewmicroclassindex)
        } else if (Laghu === getFirstIndexvalue) {
            setLaghu(true)
            setMadhya(false)
            setbrahma(false)
            setDaksha(false)
            setdropfive(false)
            setdropsix(true)
            // setmicroindexvalue(getnewmicroclassindex)
        } else if (Daksha === getFirstIndexvalue) {
            setDaksha(true)
            setLaghu(false)
            setMadhya(false)
            setbrahma(false)
            setdropfive(false)
            setdropsix(true)
            // setmicroindexvalue(getnewmicroclassindex)
        }

    };


    const custombrahma = (e) => {
        setyearcommision(Yeardata)
        const valuedata = e.target.value
        //     console.log(valuedata)
        const valueone = ".01"
        const valuetwo = ".02"
        const valuethree = ".03"
        const valuefour = ".04"


        setmicroindexvalue(valuedata)
        setdropsix(true)
        setbrahma(false)
        setMadhya(false)
        setLaghu(false)
        setDaksha(false)

        if (valuedata === valueone) {
            setbrahma(true)

        } else if (valuedata === valuetwo) {
            setMadhya(true)

        } else if (valuedata === valuethree) {
            setLaghu(true)
        } else if (valuedata === valuefour) {
            setDaksha(true)

        }



    }


    const handleProfilechangemicroclass = (e) => {
        setyearcommision(Yeardata)
        const brahmaid = ".01"
        const Madhyaid = ".02"
        const Laghu = ".03"
        const Daksha = ".04"
        const notapp = "'N/A"
        const notcruis = ".CA"
        const microclass = e.target.value
        const setnewmicroclass = microclass.split(',')
        const getnewmicroclassindex = setnewmicroclass[0]
        // console.log(setnewmicroclass)
        setmicroindexvalue(getnewmicroclassindex)
        // console.log(selectProfiledetailsid)

        setdropsix(true)
        // setdropfive(true)

        if (notapp === getnewmicroclassindex) {
            setdropten(true)
        }

        if (notapp === getnewmicroclassindex && selectProfiledetailsid === notcruis) {
            setdropten(false)
        }


        if (brahmaid === getnewmicroclassindex) {
            setbrahma(true)
            setMadhya(false)
            setLaghu(false)
            setDaksha(false)
            setmicroindexvalue(getnewmicroclassindex)

        } else if (Madhyaid === getnewmicroclassindex) {
            setMadhya(true)
            setbrahma(false)
            setLaghu(false)
            setDaksha(false)
            setmicroindexvalue(getnewmicroclassindex)
        } else if (Laghu === getnewmicroclassindex) {
            setLaghu(true)
            setMadhya(false)
            setbrahma(false)
            setDaksha(false)
            setmicroindexvalue(getnewmicroclassindex)
        } else if (Daksha === getnewmicroclassindex) {
            setDaksha(true)
            setLaghu(false)
            setMadhya(false)
            setbrahma(false)
            setmicroindexvalue(getnewmicroclassindex)
        }

    }

    const handleCountryChange = (e) => {
        setyearcommision(Yeardata)
        setSelectedCountryId(e.target.value);
        setdropdown1(true)
        setdropdowntwo(true)
        setyearcommision()
    };


    const profileyearcommission = (e) => {
        setyearcommision(Yeardata)
        const Commission = e.target.value
        const NewComission = Commission.split(',')
        console.log(NewComission)
        const newcommisionindex = NewComission[0]
        setnewcommisioncode(newcommisionindex)
    }


    const handleCertificationchange = (e) => {
        setyearcommision(Yeardata)
        setCertificationId(e.target.value)
        setdropdown1(true)
        setyearcommision()
    }

    const onhandlechangeTrainningProgram = (e) => {
        setyearcommision(Yeardata)

        const food = ".FD"
        const hosp = ".HOS"
        const marine = ".MR"
        const newvalueid = ".RLY"
        const Agri = ".AGR"
        const dairy = ".DRY"
        const cosmetic = ".CO"
        const Textile = ".TX"
        const Nutra = ".NTR"
        const selectedValue = e.target.value;
        const selectedId = selectedValue.split(',')
        console.log(selectedId)
        const newselectedidindex = selectedId[0]
        const newvalueis = selectedId[1]
        setIndustry(selectedId[1])
        const dt = Trainingprofile.filter(item => item.code === newselectedidindex);
        setTrainingProfile(dt);
        setdropfour(true)
        sethidevalue(newvalueis)


        if (newvalueis === marine || newvalueis === food || newvalueis === hosp || newvalueis === newvalueid || newvalueis === Nutra ) {
            setselectedvikram2079id()
            setselectProfiledetailsid()
            setNewsubclassValue()
            setmicroindexvalue()
            setpantryvalue()
            setnewcommisioncode()
            setnacustom()
            setbrahma(false)
            setMadhya(false)
            setLaghu(false)
            setDaksha(false)
            setone(false)
            settwo(false)
            setthree(false)
            setfour(false)
            setfive(false)
            setsix(false)
            setseven(false)
            sethone(false)
            sethtwo(false)
            sethfour(false)
            setPantry(false)
            setHyginone(false)
            setdropthree(false)
            setdropfive(false)
            setdropsix(false)
            setdropeight(false)
            setdropten(false)

        }

        if (newvalueis === newvalueid) {
            setone(false)
            settwo(false)
            setthree(false)
            setfour(false)
            setfive(false)
            setsix(false)
            setseven(false)
            sethone(false)
            sethtwo(false)
            sethfour(false)
        }

        if (newvalueis === Agri || newvalueis === dairy || newvalueis === cosmetic || newvalueis === Textile ) {
            setdropthree(false)
            setdropfour(false)
            setdropfive(false)
            setdropsix(false)
            setdropseven(true)
            setselectedvikram2079id("")
            setselectProfiledetailsid("")
            setNewsubclassValue("")
            setmicroindexvalue("")
            setyearcommision("")
            setnewcommisioncode("")
            setone(false)
            settwo(false)
            setthree(false)
            setfour(false)
            setfive(false)
            setsix(false)
            setseven(false)
            sethone(false)
            sethtwo(false)
            // seththree(false)
            sethfour(false)


            setbrahma(false)
            setMadhya(false)
            setLaghu(false)
            setDaksha(false)
            setpantryandhygin()
            setdropnine(false)
            setdropten(false)
            setdropeight(false)

        }

        if(Nutra === newvalueis){
     setdropseven(false)
        }

        

        if (newvalueis === food || newvalueis === hosp || newvalueis === marine || newvalueis === newvalueid) {
            setdropseven(false)
        }





    }



    const statictrain = (e) => {
        setyearcommision(Yeardata)
        const kitchenpanetry = e.target.value
        setpantryandhygin(kitchenpanetry)
        setPantry(false)
        setHyginone(false)
        setdropsix(true)
        if (kitchenpanetry === ".PNKT") {
            setPantry(true)

        } else if (kitchenpanetry === ".HYS") {
            setHyginone(true)

        }
    }

    const statichandlenotapplicable = (e) => {
        const newvaluedata = e.target.value
        setnacustom(newvaluedata)
        setyearcommision(Yeardata)

    }




    const handleProfilechange = (e) => {
        setyearcommision(Yeardata)
        const Hone = "kone"
        const Htwo = "ktwo"
        const Hthree = "ktwo"
        const Hfour = "kfour"
        const Train = ".TR"
        const cruis = ".CR"
        const cargo = ".CA"
        const kitchen = ".KT"
        const Proc = ".PROC"
        const targetedvalue = e.target.value
        const newtagetvalue = targetedvalue.split(',')
        console.log(newtagetvalue)
        const thirdindexvalue = newtagetvalue[3]
        const codetargetvalue = newtagetvalue[2]
        const newtargetcodevalue = newtagetvalue[0]
        // console.log(newtargetcodevalue)
        // const newdataindextarget = newtagetvalue[4]
        setselectProfiledetailsid(newtargetcodevalue)
        setdropthree(true)
        const newfilteredtarget = classname.filter(item => item.code === codetargetvalue)
        setclassvalue(newfilteredtarget)
        // const newtargetvalue = classname.filter(elem =>elem.newtrigger === newdataindextarget )
        // console.log(newtargetvalue)
   
   if(kitchen === newtargetcodevalue ){
       setdropthree(false)
        // setdropfive(true)
        setdropeight(true)
   }
   if(Proc === newtargetcodevalue ){
    // setdropthree(false)
     // setdropfive(true)
     setdropeight(false)
}



        setone(false);
        settwo(false);
        setthree(false);
        setfour(false);
        setfive(false);
        setsix(false);
        setseven(false);
        // setdropten(false)
        // console.log(hidevalue)

        if (newtargetcodevalue === cruis && ".MR" === hidevalue) {
            setdropten(false)

        }

        if (newtargetcodevalue === cargo) {
            setdropsix(true)
            setdropthree(false)
            setdropten(false)
        }

        if (newtargetcodevalue === cruis) {
            // setdropsix(true)
            setdropthree(false)
            setdropten(true)
        }

        setdropnine(false)


        if (thirdindexvalue === "kktwo" || thirdindexvalue === "kktwo") {
            sethtwo(false);
        }

        if (thirdindexvalue === Hone) {
            sethone(true);
            sethtwo(false);
            sethfour(false);
        } else if (thirdindexvalue === Htwo || thirdindexvalue === Hthree) {
            sethone(false);
            sethtwo(true);
            sethfour(false);
        } else if (thirdindexvalue === Hfour) {
            sethone(false);
            sethtwo(false);
            sethfour(true);
        }

        if (newtargetcodevalue === Train) {
            setdropnine(true)
        }
    }



    useEffect(() => {
        if (selectProfiledetailsid !== undefined) {
        }
    }, [selectProfiledetailsid]);






    useEffect(() => {
        setCountry(Countryname)
        setnewcertification(Sattvikceryfy)
        // setVikram(Vikram2079)
        setTraining(TrainingProgram)
        setTrainingProfile(Trainingprofile)
        setclassvalue(classname)
        setmicroclassvalue(Microclassdata)
        setyearcommision(Yeardata)
        // setcontentvalue(contentvalue)


    }, [Countryname, Vikram2079, TrainingProgram, Trainingprofile, Sattvikceryfy, classname, Microclassdata, Yeardata])




    return (
        <>

            <div className="container mt-5">
                <div className="row new-row">

                    {/* //for country start */}
                    <div className="col-sm-5 first-row">
                        <div className="row code-container">

                            <div className="select-container">
                                <select className="custom-select " onChange={handleCertificationchange}>
                                    <option value="">{t('Please-Select-your-certification')}</option>
                                    {
                                        newcertification &&
                                            newcertification !== undefined ?
                                            newcertification.map((Strain) => {
                                                return (
                                                    <option key={Strain.id} value={Strain.id}>{t(Strain.name)}</option>
                                                )
                                            })
                                            : "No Certification"
                                    }
                                </select>
                            </div>



                            <div className="select-container">
                                <select className={`custom-select ${dropone ? '' : 'drop1'}`} onChange={handleCountryChange}>
                                    <option value="" className='country-data'>{t('Select-Country')}</option>
                                    {country && country !== undefined ? (
                                        country.map((cname) => (
                                            <option key={cname.id} value={cname.id}>{t(cname.name)}</option>
                                        ))
                                    ) : (
                                        <option disabled>No countries available</option>
                                    )}
                                </select>
                            </div>


                            {/* training start */}
                            <div className="select-container">
                                <select className={`custom-select ${droptwo ? '' : 'drop2'}`} onChange={onhandlechangeTrainningProgram}>
                                    <option value="">{t('Select-Industry')}</option>
                                    {Training && Training !== undefined ? (
                                        Training.map((current, id) => {
                                            const value = `${current.id},${current.idcode}`;
                                            return (
                                                <option key={id} value={value}>{t(current.name)}</option>
                                            );
                                        })
                                    ) : (
                                        <option disabled>No Training Program Available</option>
                                    )}
                                </select>
                            </div>

                            {/* training end */}

                            {/* industrial profile start */}
                            {dropfour && <div className="select-container">
                                <select className={`custom-select ${dropfour ? '' : 'drop4'} `} onChange={handleProfilechange}>
                                    <option value="">{t('Select-Class')}</option>
                                    {
                                        TrainingProfile &&
                                            TrainingProfile !== undefined ?
                                            TrainingProfile.map((elem, id) => {

                                                const value = `${elem.id},${elem.name},${elem.code},${elem.newcode},${elem.newtrigger}`;

                                                return (
                                                    <option key={id} value={value} >{t(elem.name)}</option>
                                                )
                                            })

                                            : "NO PROFILE"

                                    }

                                </select>
                            </div>

                            }
                            {/* industrial profile End */}


                            {dropthree && <div className="select-container">
                                <select className={`custom-select ${dropthree ? '' : 'drop3'} `} onChange={handleProfilechangesubclass}>
                                    <option value="">{industry === ".RLY" ? "Select-Microclass" : "Select-Subclass"}</option>
                                    {
                                        classnamevalue &&
                                            classnamevalue !== undefined ?
                                            classnamevalue.map((elem, id) => {

                                                const value = `${elem.id},${elem.name},${elem.code},${elem.newcode},${elem.trigger},${elem.extra}`;
                                                return (
                                                    <option key={id} value={value} >{t(elem.name)}</option>
                                                )
                                            })

                                            : "NO PROFILE"

                                    }

                                </select>
                            </div>
                            }


                            {dropnine && <div className="select-container">
                                <select className={`custom-select ${dropnine ? '' : 'drop9'} `} onChange={statictrain}>
                                    <option value="">{t('-SelectSubclass')}</option>
                                    <option value=".PNKT">{('Pantry(Kitchen)')}</option>
                                    <option value=".HYS">{t('Hygiene')}</option>

                                </select>
                            </div>

                            }




                            {dropfive && <div className="select-container ">
                                <select className={`custom-select  ${dropfive ? '' : 'drop5'} `} onChange={handleProfilechangemicroclass}>
                                    <option value="">{t('Select-Microclass')}</option>
                                    {
                                        microclassValue &&
                                            microclassValue !== undefined ?
                                            microclassValue.map((elem, id) => {

                                                const value = `${elem.id},${elem.name},${elem.code}`;
                                                return (
                                                    <option key={id} value={value} >{t(elem.name)}</option>
                                                )
                                            })

                                            : "NO PROFILE"

                                    }

                                </select>
                            </div>
                            }








                            {dropseven && <div className="select-container">
                                <select className={`custom-select ${dropseven ? '' : 'drop7'} `} onChange={statichandlenotapplicable}>
                                    <option value="">{('-Selectclass')}</option>
                                    <option value="'N/A">N/A</option>
                                </select>
                            </div>

                            }





                            {dropeight && <div className="select-container">
                                <select className={`custom-select ${dropeight ? '' : 'drop8'} `} onChange={custombrahma} >
                                    <option value="">{('-Select-Microclass')}</option>
                                    <option value=".01">{t('Brahma')}</option>
                                    <option value=".02">{t('Madhya')}</option>
                                    <option value=".03">{('Laghu')}</option>
                                    <option value=".04">{('Daksha')}</option>
                                </select>
                            </div>

                            }

                            {dropten && <div className="select-container">
                                <select className={`custom-select ${dropten ? '' : 'drop10'} `} onChange={statictrain}>
                                    <option value="">{('-Selectclass')}</option>
                                    <option value=".VG">{t('Vegetarian')}</option>
                                    <option value=".VN">{t('Vegan')}</option>
                                    <option value=".JN">{t('Jain')}</option>
                                    <option value=".SM">{t('Sattvam')}</option>

                                </select>
                            </div>

                            }


                            {dropsix && <div className="select-container">
                                <select className={`custom-select ${dropsix ? '' : 'drop6'} `} onChange={profileyearcommission}>
                                    <option value="">{t('Year-of-comission')}</option>
                                    {
                                        yearcommision &&
                                            yearcommision !== undefined ?
                                            yearcommision.map((elem, id) => {

                                                const value = `${elem.id},${elem.name},${elem.code}`;
                                                return (
                                                    <option key={id} value={value} >{t(elem.name)}</option>
                                                )
                                            })

                                            : "NO PROFILE"

                                    }

                                </select>
                            </div>
                            }




                        </div>
                    </div>





                    <div className="col-sm-5 second-row">
                        <div className="code-border">
                            <h1 className='code'>{selectedCertificationId}{selectedCountryId}{industry}{selectedvikram2079id}{selectProfiledetailsid}{subclasssvalue}{microindexvalue}{pantryandhygin}{pantrystatvalue}{comissioncosde}{nacustom}</h1>
                        </div>
                        {brahmaid && <div><Brahma /></div>}
                        {Dakshaid && <div><Daksha /></div>}
                        {Laghuid && <div><Laghu /></div>}
                        {Madhyaid && <div><Madhya /></div>}


                        {one && <Rte />}

                        {two && <Ayurvedic />}

                        {three && <Organic />}

                        {four && <Vegan />}

                        {five && <Vege />}

                        {six && <Jain />}

                        {seven && <Satvam />}

                        {Hone && <Hospitalitykitechen />}

                        {Htwo && <Laundry />}
                        {Hfour && <Trainingdata />}

                        {Pantryone && <Pantry />}
                        {Hyginone && <Hygin />}



                    </div>
                </div>
            </div>

        </>
    )
}

export default Trainingcode