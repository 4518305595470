import React, { useEffect } from 'react'
import Trainingheader from '../components/training/trainingheader'
import Trainingcode from '../components/training/trainingcode'
import Compsubheader from "../components/training/trainingsubheader"
import Books from "../components/assests/images/indo/training.jpg"

const Training = () => {

    const Props = {
        titleone: "Welcome To Training Codex",
        titlesecond: "TRAINING CODEX",
        titlethird: "Becoming a professional of the future",
        Imgurl: Books
    }


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <section>
                <Compsubheader Props={Props} />
            </section>
            <section>
                <Trainingheader />
            </section>
            <section>
                <Trainingcode />
            </section>

        </>
    )
}

export default Training