import React, { useEffect } from 'react'
import Term from "../components/privacy/termandconditon"
import Sign from "../components/assests/images/privacy/sign.jpg"
import Compsubheader from "../components/training/trainingsubheader";

const Termsandcodition = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Props = {
        titleone: " ",
        titlesecond: " ",
        titlethird: " ",
        Imgurl: Sign,
    };
    return (
        <>
            <section>
                <Compsubheader Props={Props} />
            </section>
            <div>
                <section>
                    <Term />
                </section>
            </div>
        </>
    )
}

export default Termsandcodition