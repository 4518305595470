
import React from 'react'
import hospitality from "../assests/images/icons/hospitality.png"
import marines from "../assests/images/icons/resturants.png"
import agriculture from "../assests/images/icons/Agro.png"
import dairy from "../assests/images/icons/hospitality.png"
// import railway from "../assests/images/icons/railways.png"
import cosmetics from "../assests/images/icons/cosmetics.png"
import textile from "../assests/images/icons/industries.png"
import capsul from "../assests/images/icons/training.png"
import food from "../assests/images/icons/fast food.png"
import "../assests/css/ourservices/ourservices.css"




const Ourservices = () => {


  return (
    <>

      <section>
        <div className="container my-5">
          <h1 className="text-center our-services-serve-cust">Our Services</h1>
        </div>

        <div className="container my-5">
          <h1 className="text-center font-style-subheading">The Sattvik Council of Singapore wants to bring a vegetarian revolution where the consumers from vegetarian society should not have any second thought while consuming any product in terms of Food Safety.</h1>
        </div>

        <div className="container">

          <div className="row newbokk">


            <div className="mainconatiner">
              <div className="card-flip ">

                <div className="front">

                  <div className="card card-one  ">
                    <img src={food} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">FOOD</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">
                  <p className="card-text-flip-data card-text-eight  text-center ">We offer a comprehensive range of assurance and advisory services designed to assist vegetarian and vegan ....</p>
                </div> */}
              </div>


            </div>

            <div className="mainconatiner">
              <div className="card-flip ">

                <div className="front">

                  <div className="card card-one  ">
                    <img src={hospitality} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">HOSPITALITY</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">

                  <p className="card-text-flip-data  hos text-center ">The global tourism and hospitality industry faced a significant downturn during the pandemic In the face of ongoing challenges related to climate change and environmental degradation,......</p>
                </div> */}
              </div>


            </div>



            <div className="mainconatiner">
              <div className="card-flip ">

                <div className="front">

                  <div className="card card-one  ">
                    <img src={cosmetics} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">COSMETICS</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">

                  <p className="card-text-flip-data card-text-five text-center ">At Sattvik, we are dedicated to ensuring the safety and efficacy of your vegetarian and vegan cosmetics. Our local experts, in collaboration.......</p>
                </div> */}
              </div>


            </div>






            {/* <div className="mainconatiner">
              <div className="card-flip ">

                <div className="front">

                  <div className="card card-one  ">
                    <img src={railway} className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">{t('RAILWAY')}</h5>
                    </div>
                  </div>
                </div>

                <div className="back">

                  <p className="card-text-flip-data card-text-two text-center ">{t('Ensuring travellers with Sattvik Certified Food in railways offering products and services that accommodate the needs of vegetarian/vegan travellers to a varying extent.')}</p>
                </div>
              </div>


            </div> */}


            <div className="mainconatiner">
              <div className="card-flip ">

                <div className="front">

                  <div className="card card-one  ">
                    <img src={agriculture} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">AGRO-FOOD</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">
                  <p className="card-text-flip-data card-text-three text-center ">In a dynamic agricultural landscape marked by rising demand and escalating concerns regarding quality, safety, and sustainability, the agro-food industry faces complex challenges.....</p>
                </div> */}
              </div>


            </div>
            <div className="mainconatiner">
              <div className="card-flip ">
                <div className="front">
                  <div className="card card-one  ">
                    <img src={textile} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title   text-center font-style">TEXTILE</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="back">
                  <p className="card-text-flip-data text-center card-text-six "> Post-pandemic, evolving societal values have prompted consumers to expect fashion companies to be driven by purpose, support social initiatives, and reduce their environmental......</p>
                </div> */}
              </div>
            </div>


            <div className="mainconatiner">
              <div className="card-flip ">

                <div className="front">
                  <div className="card card-one  ">
                    <img src={marines} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">MARINES</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">
                  <p className="card-text-flip-data  card-text-one text-center ">Sattvik offers specialised food cargo inspection services designed for clients seeking reassurance not only during container loading and unloading but also confirmation that safety and......</p>
                </div> */}
              </div>
            </div>


            <div className="mainconatiner">
              <div className="card-flip ">
                <div className="front">
                  <div className="card card-one  ">
                    <img src={dairy} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center  font-style">CRUISE-SHIP</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">
                  <p className="card-text-flip-data card-text-four text-center "> Sattvik ensures safety no matter where you are. Cruise-ship establishments, including kitchens, restaurants, and rooms that meet Sattvik standards, are eligible for certification......</p>
                </div> */}
              </div>
            </div>




            <div className="mainconatiner">
              <div className="card-flip ">
                <div className="front">
                  <div className="card card-one  ">
                    <img src={capsul} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">SATTVIK LAP</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">
                  <p className="card-text-flip-data text-center card-text-seven">The "Sattvik Lead Auditor Program" is a specialised training and certification program designed to equip participants with the knowledge........</p>
                </div> */}
              </div>

            </div>


            <div className="mainconatiner">
              <div className="card-flip ">
                <div className="front">
                  <div className="card card-one  ">
                    <img src={capsul} loading="auto" className="card-img-top" alt="Img" />
                    <div className="card-body  ">
                      <h5 className="card-title text-center font-style">SATTVIK PROGG.</h5>
                    </div>
                  </div>
                </div>

                {/* <div className="back">
                  <p className="card-text-flip-data text-center card-text-seven">The mind’s psychological qualities are highly unstable and can quickly fluctuate between the different gunas. The predominant guna......
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Ourservices