import React from 'react'
import "../assests/css/tourism/tourism.css"



const Tourone = () => {
    
    return (
        <>
            <div className="container-fluid p-5 text-dark text-center">
                <h1 className="heading-font">Explore The Tourism Of Indonesia</h1>
            </div>

            <div className="container-fluid bg-dark text-white custom-css-one-two text-center">
                <p className='new-para'>Indonesia, a nation of over 17,000 islands, enchants visitors with its natural beauty, diverse cultures,and delectable cuisine.<span className='new-color'>As of 2023, Indonesia's allure remains as strong as ever, attracting travelers from across the globe</span> .In this, we delve into the latest insights on Indonesia's tourism landscape,highlighting its population, the rise of vegetarian and vegan preferences,and the unique blend of migrating populations.</p>
            </div>


        </>
    )
}

export default Tourone