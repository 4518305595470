import React from 'react'
import "../assests/css/codex/codex.css"

const Madhya = () => {

  return (
    <>
    <div>
    <h1 className="color-code">Madhya :</h1>
    <p className = "color-code-new">Madhya Kitchens are found in medium hotels, restaurants, kiosk and fast food restaurants. It has an area of 1000-2000 Sq. ft., capacity of 500 above meals per day, working hours of 12PM to 12 AM and number of employees 50-100.</p>
    </div>
 </>
 )
}

export default Madhya