import React from 'react'
import "../assests/css/codex/codex.css"


const Jains = () => {

  return (
    <>
    <h1 className='color-code-code'>Jain Medium Kitchen</h1>
    {/* <h3 className='color-code'>{selectedCertificationId}.Country{industry}{selectedvikram2079id}{selectProfiledetailsid}{subclasssvalue}{microindexvalue}{comissioncosde}</h3> */}
    <p className="color-code-new">A food prepared ensuring non-use of forbidden uneatable as defined in Jain Conduct, such as underground vegetables, roots, fruits & plants such as potatoes, garlic, Onion etc. to prevent injury to any living creature including insects, bacteria, microorganisms & alcohol based beverages. A Jain medium kitchen area is 1000-2000 sq. ft., capacity is 500+ meals per day, 12 hrs working hrs, no of employees working are 50-100.</p>
    </>
  )
}

export default Jains