import React ,{useEffect} from 'react'
import Newsdata from '../components/news/news'
import CoverImage from "../components/training/trainingsubheader"
import Newsimage from "../components/assests/images/news/newspaper.jpg"
import "../components/assests/css/bot/bot.css"

const News = () => {
  useEffect(()=>{
  window.scrollTo(0,0)
  },[])

  const Props = {
    titleone: "Resources",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Newsimage,
  };




  return (
    <>

      <section>
         <CoverImage Props ={Props}/>
      </section>
      <section>
          <Newsdata />
      </section>

    </>
  )
}

export default News