import One from "../assests/images/news/1.jpg";
import two from "../assests/images/news/2.jpg";
import three from "../assests/images/news/3.jpg"
import Four from "../assests/images/news/4.jpeg"
import six from "../assests/images/news/6.jpg"
import five from "../assests/images/news/5.jpg"
import seven from "../assests/images/news/7.jpg"
import eight from "../assests/images/news/8.jpg"
import meet from "../assests/images/news/meet.jpg"
import ten from "../assests/images/news/10.jpg"
import eleven from "../assests/images/news/13.jpg"
import twelve from "../assests/images/news/homemade.jpg"
import thirteen from "../assests/images/news/15.jpg"
import fourteen from "../assests/images/news/14.jpeg"
import sixteen from "../assests/images/news/16.jpeg"
import founder from "../assests/images/founder/abhishek-biswas.jpg"
import seventeen from "../assests/images/news/17.jpg"
import Eightteen from "../assests/images/news/18.jpg"
import Ninteen from "../assests/images/news/19.jpg"
import SattvikImage from "../assests/images/news/sattvik.webp"
import Tenali from "../assests/images/news/tenali.webp"
import Tenalipacket from "../assests/images/news/tenalipacket.webp"
import Tenaliyellow from "../assests/images/news/tenaliyellow.webp"
import Indiaretailing from "../assests/images/news/indiaretailing.jpg"
import together from "../assests/images/news/imagetogether.webp"
import People from "../assests/images/news/threepeople.webp"
import Satvikpeople from "../assests/images/news/Sattvik-Certifications-Founder.jpg"
import Gifting from "../assests/images/news/gifting.jpg"
import Rahul from "../assests/images/news/rahul.jpg"
import Hotel from "../assests/images/news/hotel.jpg"
import Virat from "../assests/images/news/virat.jpg"
import Rahultogether from "../assests/images/news/rahulwithother.jpg"
import Farmely from "../assests/images/news/Farmely.jpg"
import Fastweb from "../assests/images/news/fast.webp"
import Hayatt from "../assests/images/news/Sarvatt-Hyatt.webp"
import Hotels from "../assests/images/news/hotels.jpg"
import Taj from "../assests/images/news/Taj.webp"
import Allmember from "../assests/images/news/allmember.webp"
import Givecertificate from "../assests/images/news/givecertificate.webp"
import Foundere from "../assests/images/news/Sattvik-Certifications-Founder.jpg"


const news = [



  {
    id: "1",
    date: "January 31, 2024",
    Image: People,
    title: "Sattvik certifications grants esteemed Vegan Certification.......",
    content: "Sattvik Certifications, a leading certification authority dedicated to promoting ethical and sustainable practices, proudly announces......",
    link: "https://www.fnbnews.com/Top-News/sattvik-certifications-grants-esteemed-vegan-certification-to-blue-tribe-manufacturing-facility-76055#:~:text=Sattvik%20Certifications%2C%20a%20leading%20certification,located%20in%20Chandigarh%20and%20Bangalore",
    country: "India"

  },


  {
    id: "2",
    date: "January 31, 2024",
    Image: People,
    title: "Sattvik certifications grants esteemed Vegan Certification.......",
    content: "Sattvik Certifications, a leading certification authority dedicated to promoting ethical and sustainable practices, proudly announces......",
    link: "https://www.fnbnews.com/Top-News/sattvik-certifications-grants-esteemed-vegan-certification-to-blue-tribe-manufacturing-facility-76055#:~:text=Sattvik%20Certifications%2C%20a%20leading%20certification,located%20in%20Chandigarh%20and%20Bangalore",
    country: "India"

  },


  {
    id: "3",
    date: "January 18, 2024",
    Image: Indiaretailing,
    title: "“India and the Indian vegetarian........",
    content: "Veganism is on the rise across India and things like the have also been growing. ET Hospitality World spoke with Abhishek Biswas,..........",
    link: "https://hospitality.economictimes.indiatimes.com/news/operations/food-and-beverages/india-and-the-indian-vegetarian-and-vegan-diaspora-is-a-strong-market/106917130",
    country: "India"
  },



  {
    id: "4",
    date: "January 18, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group gets Sattvik Certification .....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://indiantelevision.com/mam/marketing/brands/tenali-double-horse-group-gets-sattvik-certification-240118",
    country: "India"
  },

  {
    id: "5",
    date: "January 18, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group becomes the .....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://hellokotpad.com/business/tenali-double-horse-group-becomes-the-first-south-indian-pulses-and-dals-brand-to-get-sattvik-certification/",
    country: "India"
  },

  {
    id: "6",
    date: "January 18, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group becomes the .....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://quickbiznews.com/business/tenali-double-horse-group-becomes-the-first-south-indian-pulses-and-dals-brand-to-get-sattvik-certification/",
    country: "India"
  },



  {
    id: "7",
    date: "January 18, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group becomes the .....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://businessmicro.in/business/tenali-double-horse-group-becomes-the-first-south-indian-pulses-and-dals-brand-to-get-sattvik-certification/",
    country: "India"
  },



  {
    id: "8",
    date: "January 17, 2024",
    Image: Tenaliyellow,
    title: "Tenali Double Horse Group becomes the .....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://www.passionateinmarketing.com/tenali-double-horse-group-becomes-the-first-south-indian-pulses-and-dals-brand-to-get-sattvik-certification/",
    country: "India"
  },



  {
    id: "9",
    date: "January 17, 2024",
    Image: Tenalipacket,
    title: "Tenali Double Horse Group to get Sattvik Certification.....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://www.foodtechbiz.com/business-updates/tenali-double-horse-group-to-get-sattvik-certification",
    country: "India"
  },

  {
    id: "10",
    date: "January 14, 2024",
    Image: Indiaretailing,
    title: "World’s first veg, vegan food auditing.....",
    content: "Started in 2008,  Sattvik Certifications is the world’s first vegetarian food safety and certification body........",
    link: "https://www.indiaretailing.com/2024/01/14/worlds-first-veg-vegan-food-auditing-firm-sattvik-certifications-builds-trust-in-consumers/",
    country: "India"
  },

  {
    id: "11",
    date: "January 03, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group Become the First.....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://www.fnbnews.com/Food-Safety/tenali-double-horse-first-south-indian-pulses-and-dals-brand-to-get-sattvik-76371",
    country: "India"
  },


  {
    id: "12",
    date: "January 03, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group Become the First.....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://www.foodinfotech.com/sattvik-certifications-awards-the-sattvik-vegetarian-certificate-to-tenali-double-horse-group/",
    country: "India"
  },


  {
    id: "13",
    date: "January 03, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group Become the First.....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik.......",
    link: "https://hospibuz.com/tenali-double-horse-group-becomes-the-first-south-indian-pulses-and-dals-brand-to-get-sattvik-certification/",
    country: "India"
  },

  {
    id: "14",
    date: "January 03, 2024",
    Image: Tenali,
    title: "Tenali Double Horse Group becomes.....",
    content: "Sattvik Certifications, the premier certification body for vegetarian, vegan, and Jain food & lifestyle, has awarded its Sattvik Vegetarian.......",
    link: "https://www.en.krishakjagat.org/farming-agriculture/tenali-double-horse-group-becomes-the-first-south-indian-pulses-and-dals-brand-to-get-sattvik-certification/",
    country: "India"
  },


  {
    id: "15",
    date: "December 26, 2023",
    Image: SattvikImage,
    title: "Veg, vegan food auditing firm Sattvik.....",
    content: "Sattvik Certifications, which endorses vegetarian and vegan foods, in India and abroad will soon begin its certification process in Australia.......",
    link: "https://www.thehindubusinessline.com/economy/agri-business/veg-vegan-food-auditing-firm-sattvik-certifications-set-to-expand-its-footprint-abroad-further/article67674305.ece",
    country: "India"
  },


  {
    id: "16",
    date: "January 20, 2023",
    Image: One,
    title: "The world’s first vegetarian food safety council is here",
    content: "Sattvik Verification to assure that food products do not contain any hidden non-vegan, non-vegetarian and harmful substances......",
    link: "https://www.hotelierindia.com/operations/the-worlds-first-vegetarian-food-safety-council-is-here",
    country: "India"

  },
  {
    id: "17",
    date: "January 19, 2023",
    Image: two,
    title: "Sattvik Certification certifies Royal....",
    content: "Sattvik Council of India has exclusively “Sattvik Certified” ITC Royal Vega Restaurants in ITC Grand Chola, Chennai, ITC Narmada,.....",
    link: "https://bwhotelier.businessworld.in/article/Sattvik-Certification-certifies-Royal-Vega-by-ITC-Luxury-Hotel-Group/19-01-2023-462473",
    country: "India"

  },


  {
    id: "18",
    date: "November 16, 2023",
    Image: together,
    title: "Abhishek Biswas , Sattvik Certifications....",
    content: " Sattvik Certifications is pleased to announce that its founder, Mr. Abhishek Biswas, was invited to participate as a distinguished.....",
    link: "https://hospibuz.com/abhishek-biswas-sattvik-certifications-founder-share-insights-on-food-awareness-panel/",
    country: "India"

  },


  {
    id: "19",
    date: "November 19, 2023",
    Image: People,
    title: "Abhishek Biswas , Sattvik Certifications....",
    content: "Sattvik Certifications is pleased to announce that its founder, Mr. Abhishek Biswas, was invited to participate as a distinguished.....",
    link: "https://www.passionateinmarketing.com/abhishek-biswas-sattvik-certifications-founder-share-insights-on-food-awareness-panel/",
    country: "India"

  },



  {
    id: "20",
    date: "November 20, 2023",
    Image: Satvikpeople,
    title: "Abhishek Biswas , Sattvik Certifications....",
    content: "Sattvik Certifications is pleased to announce that its founder, Mr. Abhishek Biswas, was invited to participate as a distinguished.....",
    link: "https://bizrapidx.com/business/abhishek-biswas-sattvik-certifications-founder-share-insights-on-food-awareness-panel/",
    country: "India"

  },


  {
    id: "21",
    date: "November 20, 2023",
    Image: together,
    title: "Abhishek Biswas , Sattvik Certifications....",
    content: "Sattvik Certifications is pleased to announce that its founder, Mr. Abhishek Biswas, was invited to participate as a distinguished.....",
    link: "https://onlinenews9.in/business/abhishek-biswas-sattvik-certifications-founder-share-insights-on-food-awareness-panel/",
    country: "India"

  },



  {
    id: "22",
    date: "November 20, 2023",
    Image: together,
    title: "Abhishek Biswas , Sattvik Certifications....",
    content: "Sattvik Certifications is pleased to announce that its founder, Mr. Abhishek Biswas, was invited to participate as a distinguished.....",
    link: "https://businessmicro.in/business/abhishek-biswas-sattvik-certifications-founder-share-insights-on-food-awareness-panel/",
    country: "India"

  },


  {
    id: "23",
    date: "November 20, 2023",
    Image: Gifting,
    title: "Abhishek Biswas , Sattvik Certifications....",
    content: "Sattvik Certifications is pleased to announce that its founder, Mr. Abhishek Biswas, was invited to participate as a distinguished.....",
    link: "https://www.chandigarhcitynews.com/abhishek-biswas-sattvik-certifications-founder-share-insights-on-food-awareness-panel/",
    country: "India"

  },

  {
    id: "24",
    date: "November 29, 2023",
    Image: Virat,
    title: "Sattvik certification Grants Esteemed Vegan....",
    content: "Sattvik Certifications, a leading certification authority dedicated to promoting ethical and sustain Practices proudly announces the conferral......",
    link: "https://www.foodinfotech.com/sattvik-certifications-grants-esteemed-vegan-certification-to-blue-tribe-manufacturing-facility/#:~:text=In%20recognition%20of%20Blue%20Tribe%27s,conscious%20and%20socially%20responsible%20practices",
    country: "India"

  },




  {
    id: "25",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik certification Grants Esteemed Vegan....",
    content: "Sattvik Certifications, a leading certification authority dedicated to promoting ethical and sustain Practices proudly announces the conferral......",
    link: "https://www.foodinfotech.com/sattvik-certifications-grants-esteemed-vegan-certification-to-blue-tribe-manufacturing-facility/#:~:text=In%20recognition%20of%20Blue%20Tribe%27s,conscious%20and%20socially%20responsible%20practices",
    country: "India"

  },


  {
    id: "26",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://businessmicro.in/business/sattvik-certifications-recognizes-farmley-with-prestigious-vegetarian-and-vegan-certification/",
    country: "India"

  },

  {
    id: "27",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://onlinenews9.in/business/sattvik-certifications-recognizes-farmley-with-prestigious-vegetarian-and-vegan-certification/",
    country: "India"

  },

  {
    id: "28",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://bizrapidx.com/business/sattvik-certifications-recognizes-farmley-with-prestigious-vegetarian-and-vegan-certification/",
    country: "India"

  },

  {
    id: "29",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://bizrapidx.com/business/sattvik-certifications-recognizes-farmley-with-prestigious-vegetarian-and-vegan-certification/",
    country: "India"

  },


  {
    id: "30",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://konexionetwork.com/NewsDetails.aspx?newsid=c7f3b61b-856c-4560-8342-a7d071789831",
    country: "India"

  },



  // {
  //   id: "92",
  //   date: "December 04, 2023",
  //   Image: Rahultogether,
  //   title: "Sattvik Certifications Recognizes Farmley.......",
  //   content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
  //   link: "https://www.passionateinmarketing.com/sattvik-certifications-recognizes-farmley-with-prestigious-vegetarian-and-vegan-certification/",
  //   country: "India"

  // },



  {
    id: "31",
    date: "December 04, 2023",
    Image: Rahultogether,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://www.passionateinmarketing.com/sattvik-certifications-recognizes-farmley-with-prestigious-vegetarian-and-vegan-certification/",
    country: "India"

  },


  {
    id: "32",
    date: "December 04, 2023",
    Image: Farmely,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://mediabrief.com/farmley-earns-prestigious-vegetarian-and-vegan-certifications-from-sattvik/",
    country: "India"

  },


  {
    id: "33",
    date: "December 04, 2023",
    Image: Farmely,
    title: "Sattvik Certifications Recognizes Farmley.......",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for Vegetarian,has been awarded the prestigious......",
    link: "https://mediabrief.com/farmley-earns-prestigious-vegetarian-and-vegan-certifications-from-sattvik/",
    country: "India"

  },






  {
    id: "34",
    date: "December 01, 2023",
    Image: Rahul,
    title: "Sattvik Certifications awards Farmley with....",
    content: "Sattvik Certifications, a leading standard-developing organisation specifically for vegetarian and vegan standard certifications.....",
    link: "https://indiantelevision.com/mam/marketing/brands/sattvik-certifications-awards-farmley-with-vegetarian-and-vegan-certification-231201",
    country: "India"

  },

  {
    id: "35",
    date: "December 02, 2023",
    Image: Hotel,
    title: "Sarvatt Restaurant at Hyatt Regency....",
    content: "Sarvatt, the restaurant at Hyatt Regency Ahmedabad, has been officially certifie by esteemed sattvik council of india , marking a.........",
    link: "https://hospitality.economictimes.indiatimes.com/news/hotels/sarvatt-restaurant-at-hyatt-regency-ahmedabad-achieves-sattvik-certifications/105678699",
    country: "India"

  },

  {
    id: "36",
    date: "December 04, 2023",
    Image: Fastweb,
    title: "Sattvik Certifications grants vegan certification.......",
    content: "Sattvik Certifications, a leading certification authority dedicated to promoting ethical and sustainable practices, proudly announces......",
    link: "https://www.foodtechbiz.com/business-updates/sattvik-certifications-grants-vegan-certification-to-blue-tribe-manufacturing-facility",
    country: "India"

  },


  {
    id: "37",
    date: "December 04, 2023",
    Image: Hayatt,
    title: "Sarvatt Restaurant at Hyatt Regency.......",
    content: "Sarvatt, the renowned restaurant at Hyatt Regency Ahmedabad, has been officially certified by the esteemed Sattvik Council of India......",
    link: "https://hospibuz.com/sarvatt-restaurant-at-hyatt-regency-ahmedabad-achieves-sattvik-certifications-for-exemplary-vegetarian-jain-practices/",
    country: "India"

  },

  {
    id: "38",
    date: "December 04, 2023",
    Image: Hotels,
    title: "Sattvik Certifications Bestows Prestigious Jain.....",
    content: "Sattvik Certifications, the leading certifying authority for vegetarian and Jain dietary compliance is thrilled to announce the awarding of esteemed jain....",
    link: "https://www.foodinfotech.com/sattvik-certifications-bestows-prestigious-jain-certification-upon-taj-gandhinagar-resort-spa/",
    country: "India"

  },


  {
    id: "39",
    date: "December 02, 2023",
    Image: Hotels,
    title: "Sarvatt Restaurant at Hyatt Regency Ahmedabad Achieves Sattvik.....",
    content: "Sarvatt, the renowned restaurant at Hyatt Regency Ahmedabad, has been officially certified by the esteemed....",
    link: "https://www.passionateinmarketing.com/sarvatt-restaurant-at-hyatt-regency-ahmedabad-achieves-sattvik-certifications-for-exemplary-vegetarian-jain-practices/",
    country: "India"

  },

  {
    id: "40",
    date: "December 02, 2023",
    Image: Taj,
    title: "Sattvik Certifications Bestows Prestigious.....",
    content: "Sattvik Certifications, the leading certifying authority for vegetarian and Jain dietary compliance, is pleased to announce....",
    link: "https://hospibuz.com/sattvik-certifications-bestows-prestigious-jain-certification-upon-taj-gandhinagar-resort-spa/",
    country: "India"

  },

  {
    id: "41",
    date: "December 05, 2023",
    Image: Allmember,
    title: "Taj Gandhinagar Resort & Spa awarded.....",
    content: "Sattvik Certifications, the foremost authority in certifying vegetarian and Jain dietary compliance, has announced the conferment of Jain Certification upon Taj Gandhinagar Resort and Spa.....",
    link: "https://bwhotelier.businessworld.in/article/Taj-Gandhinagar-Resort-Spa-awarded-Sattvik-Jain-Certification-/05-12-2023-501115/",
    country: "India"

  },


  {
    id: "42",
    date: "December 11, 2023",
    Image: Givecertificate,
    title: "Sattvik Certifications Grants Vegan Certification.....",
    content: "Sattvik Certifications is delighted to announce that it has awarded vegan certification to “Tirupati  ...",
    link: "https://www.foodinfotech.com/sattvik-certifications-grants-vegan-certification-to-tirupati-food-industries-pvt-ltd/",
    country: "India"

  },

  {
    id: "43",
    date: "December 12, 2023",
    Image: Givecertificate,
    title: "Sattvik Certifications Grants Vegan Certification.....",
    content: "Sattvik Certifications is delighted to announce that it has awarded vegan certification to “Tirupati  ...",
    link: "https://www.foodtechbiz.com/business-updates/sattvik-certifications-grants-vegan-certification-to-tirupati-food-industries",
    country: "India"

  },


  {
    id: "44",
    date: "December 13, 2023",
    Image: Givecertificate,
    title: "Sattvik Certifications Grants Vegan Certification......",
    content: "Sattvik Certifications, is pleased to announce the certification to.....",
    link: "https://hospibuz.com/sattvik-certifications-grants-vegan-certification-to-tirupati-food-industries-pvt-ltd/",
    country: "India"

  },

  {
    id: "45",
    date: "December 13, 2023",
    Image: Foundere,
    title: "Making food Sattvik compliant..............",
    content: "Sattvik Certifications is world’s first vegetarian food safety and certification body.....",
    link: "https://thepatriot.in/profile/making-food-sattvik-compliant-43526",
    country: "India"

  },








  {

    id: "46",
    date: "November 26, 2022",
    Image: three,
    title: "Sattvik Council Of  India collaborates with DY....",
    content: "Sattvik Council Of  India has collaborated with DY Patil, deemed to be University – School   of Hospitality and Tourism Studies, as a part of which “Sattvik Market ......",
    link: "https://www.financialexpress.com/education-2/sattvik-council-of-india-dy-patil-deemed-to-be-university/2892289/",
    country: "India"
  },


  {

    id: "47",
    date: " December 16, 2022",
    Image: Four,
    title: "Sattvik Council of India Verified Lifestyle.....",
    content: "Sattvik Council of India, the world’s first and pioneering vegetarian food and lifestyle Standard Development Organization .....",
    link: "https://hospitalitybizindia.com/news-track/sattvik-council-of-india-verified-lifestyle-foods-pvt-ltd/",
    country: "India"
  },

  {

    id: "48",
    date: "November 26, 2022",
    Image: six,
    title: "Sattvik Council Of India signs MOU with.....",
    content: "Through this collaborative agreement, “Sattvik Market Friendly Incubators” will be now open in the Institute. Under this, students can pursue various.....",
    link: "https://www.hotelierindia.com/business/sattvik-council-of-india-signs-mou-with-dy-patil-deemed-to-be-university-school-of-hospitality-and-tourism-studies",
    country: "India"
  },


  {

    id: "49",
    date: "November 15, 2021",
    Image: five,
    title: "IRCTC to get 'sattvik certificate'.....",
    content: "According to a press release issued by the Sattvik Council of India, it has tied up with the IRCTC to introduce services that fits the requirements of vegetarian.....",
    link: "https://www.thehindu.com/news/national/irctc-to-get-sattvik-certificate-for-some-trains-to-religious-sites-says-sattvik-council-of-india/article37484196.ece",
    country: "India"
  },

  {

    id: "50",
    date: "April 2, 2023",
    Image: seven,
    title: "Sri Sri Tattva Panchakarma Becomes Sattvik Verified.....",
    content: "On 25th March,23 Sattvik Certification Mark is one the most prominent upcoming marks used widely to assure 100% Pure vegetarian/vegan/jain Products......",
    link: "https://www.khabarindia.in/sri-sri-tattva-panchakarma-becomes-sattvik-verified/",
    country: "India"

  },


  {

    id: "51",
    date: "September 7, 2022",
    Image: eight,
    title: "India's first vegetarian/vegan certification body.....",
    content: "India's first vegetarian/vegan certification body stepping into Africa region with DNV as certification and audit partners.Satvik Certification South.....",
    link: "https://www.aninews.in/news/business/business/indias-first-vegetarianvegan-certification-body-stepping-into-africa-region-with-dnv-as-certification-and-audit-partners20220907173551",
    country: "India"
  },

  {

    id: "52",
    date: "September 18, 2021",
    Image: ten,
    title: "Like 'Halal' Is For Meat, World's 1st.....",
    content: "The world’s first vegetarian food safety and regulatory compliance for vegetarian and allied adherents --  The 'Sattvik' Council Certification scheme was ......",
    link: "https://www.indiatimes.com/news/india/sattvik-worlds-first-certification-for-vegetarian-food-launched-549700.html",
    country: "India"
  },

  {

    id: "53",
    date: "January 10, 2022 ",
    Image: meet,
    title: "Meet Entrepreneur Abhishek Biswas, Who .....",
    content: "Sattvik Council of India is the world's first and pioneering vegetarian food and lifestyle standard development organisation established by Abhishek ......",
    link: "https://thelogicalindian.com/uplifting/abhishek-biswas-vegetarian-environment-sattvik-council-of-india-33184",
    country: "India"
  },


  {

    id: "54",
    date: "November 14, 2021",
    Image: eleven,
    title: "Vande Bharat to Katra to be ‘pure veg’ as IRCTC.....",
    content: "Passengers on board Vande Bharat Express between Delhi and Katra will be encapsulated in a completely vegetarian environment,......",
    link: "https://indianexpress.com/article/india/vande-bharat-to-katra-to-be-pure-veg-irctc-7621690/",
    country: "India"
  },

  {

    id: "55",
    date: "August 2, 2023",
    Image: twelve,
    title: "Sattvik-Certifications-Singapore.....",
    content: "Sattvik Certifications Singapore has exclusively Sattvik Certified home based bakery The Cream Connection Singapore......",
    link: "https://thefinancialcapital.com/news/sattvik-certifications-singapore-has-exclusively-sattvik-certified-home-based-bakery-the-cream-connection-singapore/0445173",
    country: "Singapore"

  },

  {

    id: "56",
    date: "July 10, 2023",
    Image: thirteen,
    title: "Sattvik-Certifications-Singapore .....",
    content: "Sattvik Certifications Singapore (www.scsg.sg), World’s first leading Vegetarian/Vegan food and lifestyle certification, officially launched.......",
    link: "https://www.heraldquest.com/sattvik-certifications-singapore-certifies-the-hawker-centre-my-happy-belly-under-the-category-sattvik-vegetarian/",
    country: "Singapore"
  },


  {

    id: "57",
    date: "May 5, 2023",
    Image: fourteen,
    title: "Sattvik-Council-of-India Launches  .....",
    content: "Sattvik Certifications Singapore is proud to be associated with the “SIFAS Festival of Arts 2023”. Sattvik Council of India & Sattvik Certifications.......",
    link: "https://chroniclesnews.live/sattvik-council-of-indialaunches-sattvikconsciousness-program-designed-for-artiste-at-sifasfestival-of-arts-2023/",
    country: "Singapore"
  },

  {

    id: "58",
    date: "May 5, 2023",
    Image: fourteen,
    title: "Sattvik-Council-of-India Launches  .....",
    content: "Sattvik Certifications Singapore is proud to be associated with the “SIFAS Festival of Arts 2023”. Sattvik Council of India & Sattvik Certifications .......",
    link: "https://firstnews.co.in/sattvik-council-of-indialaunches-sattvikconsciousness-program-designed-for-artiste-at-sifasfestival-of-arts-2023/",
    country: "Singapore"
  },

  {

    id: "59",
    date: "May 5, 2023",
    Image: fourteen,
    title: "Sattvik-Council-of-India Launches  .....",
    content: "Sattvik Certifications Singapore is proud to be associated with the “SIFAS Festival of Arts 2023”. Sattvik Council of India & Sattvik Certifications .......",
    link: "https://newscrazy.in/sattvik-council-of-indialaunches-sattvikconsciousness-program-designed-for-artiste-at-sifasfestival-of-arts-2023/",
    country: "Singapore"
  },

  {

    id: "60",
    date: "May 5, 2023",
    Image: fourteen,
    title: "Sattvik-Council-of-India Launches  .....",
    content: "Sattvik Certifications Singapore is proud to be associated with the “SIFAS Festival of Arts 2023”. Sattvik Council of India & Sattvik Certifications .......",
    link: "https://newsdogs.in/sattvik-council-of-indialaunches-sattvikconsciousness-program-designed-for-artiste-at-sifasfestival-of-arts-2023/",
    country: "Singapore"
  },


  {

    id: "61",
    date: "May 5, 2023",
    Image: fourteen,
    title: "Sattvik-Council-of-India Launches  .....",
    content: "Sattvik Certifications Singapore is proud to be associated with the “SIFAS Festival of Arts 2023”. Sattvik Council of India & Sattvik Certifications .......",
    link: "https://newsdrinker.me/sattvik-council-of-indialaunches-sattvikconsciousness-program-designed-for-artiste-at-sifasfestival-of-arts-2023/",
    country: "Singapore"
  },

  {

    id: "62",
    date: "May 5, 2023",
    Image: fourteen,
    title: "सात्विक काउंसिल ऑफ इंडिया ने .....",
    content: "सात्विक सर्टिफिकेशन सिंगापुर को “सिफास फेस्टिवल ऑफ आर्ट्स 2023” से जुड़कर गर्व हो रहा है। सात्विक काउंसिल ऑफ इंडिया और सात्विक सर्टिफिकेशन सिंगापुर .......",
    link: "https://beyondindia.in/%e0%a4%b8%e0%a4%be%e0%a4%a4%e0%a5%8d%e0%a4%b5%e0%a4%bf%e0%a4%95-%e0%a4%95%e0%a4%be%e0%a4%89%e0%a4%82%e0%a4%b8%e0%a4%bf%e0%a4%b2-%e0%a4%91%e0%a4%ab-%e0%a4%87%e0%a4%82%e0%a4%a1%e0%a4%bf%e0%a4%af/",
    country: "India"
  },

  {

    id: "63",
    date: "May 8, 2023",
    Image: fourteen,
    title: "सात्विक काउंसिल ऑफ इंडिया ने .....",
    content: "सात्विक सर्टिफिकेशन सिंगापुर को “सिफास फेस्टिवल ऑफ आर्ट्स 2023” से जुड़कर गर्व हो रहा है। सात्विक काउंसिल ऑफ इंडिया और सात्विक सर्टिफिकेशन सिंगापुर .......",
    link: "https://prathamswar.com/%E0%A4%B8%E0%A4%BE%E0%A4%A4%E0%A5%8D%E0%A4%B5%E0%A4%BF%E0%A4%95-%E0%A4%95%E0%A4%BE%E0%A4%89%E0%A4%82%E0%A4%B8%E0%A4%BF%E0%A4%B2-%E0%A4%91%E0%A4%AB-%E0%A4%87%E0%A4%82%E0%A4%A1%E0%A4%BF%E0%A4%AF/",
    country: "India"
  },

  {

    id: "64",
    date: "May 8, 2023",
    Image: fourteen,
    title: "सात्विक काउंसिल ऑफ इंडिया ने .....",
    content: "सात्विक सर्टिफिकेशन सिंगापुर को सिफास फेस्टिवल ऑफ आर्ट्स 2023 से जुड़कर गर्व हो रहा है। सात्विक काउंसिल ऑफ इंडिया और सात्विक सर्टिफिकेशन सिंगापुर .......",
    link: "https://premierindia09.com/full-story/2623/satvika-kaunsila-opha-indiya-ne-kalakara-ke-lie-taiyara-kiya-gaya-satvika-cetana-karyakrama-suru-kiya-sifas-kala-mahotsava-2023-mem",
    country: "India"
  },

  {

    id: "65",
    date: "April 17, 2023",
    Image: sixteen,
    title: "GoodDot becomes India’s First Vegan Brand.....",
    content: "Sattvik Certification Mark is one the most prominent marks used widely to assure 100% Pure vegetarian/vegan/jain Products.......",
    link: "https://chroniclesnews.live/gooddot-becomes-indias-first-vegan-brand-to-get-its-sattvik-vegan-certi%EF%AC%81cate/",
    country: "India"
  },

  {

    id: "66",
    date: "April 17, 2023",
    Image: sixteen,
    title: "GoodDot becomes India’s First Vegan Brand.....",
    content: "Sattvik Certification Mark is one the most prominent marks used widely to assure 100% Pure vegetarian/vegan/jain Products.......",
    link: "https://chroniclesnews.live/gooddot-becomes-indias-first-vegan-brand-to-get-its-sattvik-vegan-certi%EF%AC%81cate/",
    country: "India"
  },


  {

    id: "67",
    date: "April 17, 2023",
    Image: sixteen,
    title: "GoodDot becomes India’s First Vegan Brand.....",
    content: "Sattvik Certification Mark is one the most prominent marks used widely to assure 100% Pure vegetarian/vegan/jain Products.......",
    link: "https://newscrazy.in/gooddot-becomes-indias-first-vegan-brand-to-get-its-sattvik-vegan-certi%EF%AC%81cate/",
    country: "India"
  },



  {

    id: "68",
    date: "April 17, 2023",
    Image: sixteen,
    title: "GoodDot becomes India’s First Vegan Brand.....",
    content: "Sattvik Certification Mark is one the most prominent marks used widely to assure 100% Pure vegetarian/vegan/jain Products.......",
    link: "https://newsdogs.in/gooddot-becomes-indias-first-vegan-brand-to-get-its-sattvik-vegan-certi%EF%AC%81cate/",
    country: "India"
  },


  {

    id: "69",
    date: "April 17, 2023",
    Image: sixteen,
    title: "GoodDot becomes India’s First Vegan Brand.....",
    content: "Sattvik Certification Mark is one the most prominent marks used widely to assure 100% Pure vegetarian/vegan/jain Products.......",
    link: "https://newsdrinker.me/gooddot-becomes-indias-first-vegan-brand-to-get-its-sattvik-vegan-certi%ef%ac%81cate/",
    country: "India"
  },

  {

    id: "70",
    date: "April 17, 2023",
    Image: sixteen,
    title: "GoodDot becomes India’s First Vegan Brand.....",
    content: "Sattvik Certification Mark is one the most prominent marks used widely to assure 100% Pure vegetarian/vegan/jain Products.......",
    link: "https://newsfiles.in/gooddot-becomes-indias-first-vegan-brand-to-get-its-sattvik-vegan-certi%EF%AC%81cate/",
    country: "India"
  },

  {

    id: "71",
    date: "March 18, 2023",
    Image: founder,
    title: "Flagging off World's first Vegetarian.....",
    content: "Sattvik Certifications Singapore, World’s first leading Vegetarian/Vegan food and lifestyle certification officially launched in.......",
    link: "https://www.issuewire.com/flagging-off-worlds-first-vegetarian-and-vegan-certifications-in-singapore-1760692690306476",
    country: "Singapore"
  },

  {

    id: "72",
    date: "July 3, 2023",
    Image: seventeen,
    title: "Sattvik-Certifications Singapore Certifies.....",
    content: "Sattvik Certifications Singapore has exclusively “Sattvik Certified” a hawker center called “All About Vege” based at 7 Maxwell Road, #01-53,.......",
    link: "https://www.issuewire.com/flagging-off-worlds-first-vegetarian-and-vegan-certifications-in-singapore-1760692690306476",
    country: "Singapore"
  },


  {
    id: "73",
    date: "July 9, 2023",
    Image: Eightteen,
    title: "Sattvik-Certifications Singapore Certifies.....",
    content: "Sattvik Certifications Singapore https://www.scsg.sg has exclusively “Sattvik Certified” another hawker center called.......",
    link: "https://www.issuewire.com/sattvik-certifications-singapore-certifies-the-hawker-centre-my-happy-belly-under-the-category-sattvik-vegetarian-1770969187742283",
    country: "Singapore"
  },

  {
    id: "74",
    date: "September 9, 2023",
    Image: Ninteen,
    title: "Markets: BIKANERVALA opens Sattvik-certified.....",
    content: "Sattvik-certified “BIKANERVALA”, a well-known family Indian restaurant chain, has opened its outlet in Terminal 3 of Singapore’s Changi Airport.......",
    link: "https://www.fiinews.com/2023/09/09/markets-bikanervala-opens-sattvik-certified-outlet-at-changi-airport-t3/",
    country: "Singapore"
  },




];


export default news