import React, { useEffect, useState } from 'react';
import Compsubheader from "../components/training/trainingsubheader";
import Whowearenav from '../components/whoweare/whowearenav';
import Weare from "../components/assests/images/indo/building.jpg";
// import Whowearecomponent from '../components/whoweare/whoweare';
import Whowearesubheader from '../components/whoweare/whowearesubheader';
import Msgfounder from "../components/whoweare/msgfounder"
import { useLocation } from 'react-router-dom';
import Card from "../components/team/card"
import Services from '../components/services/services';
// import Whyus from "../components/whyus/whyus"
// import NewImage from "../components/assests/images/indo/whyusimage-min.jpg"
// import NewImageSecon from "../components/assests/images/indo/LAKE-min.jpg"
import Representative from '../components/representative/representative';

const Whoweare = () => {
  const newLocation = useLocation();
  const exactLocation = newLocation.pathname;
  const [location, setlocation] = useState(false);
  const [locationourteam, setlocationourteam] = useState(false);
  const [services, setservices] = useState(false)
  const [representative, setRepresntative] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);

    if (exactLocation === "/Whoweare") {
      setlocation(true);
      setlocationourteam(false);
      setservices(false)
      setRepresntative(false)
      // Set this to false to ensure only one component is rendered
    } else if (exactLocation === "/ourteam") {
      setlocation(false); // Set this to false to ensure only one component is rendered
      setlocationourteam(true);
      setservices(false)
      setRepresntative(false)
    } else if (exactLocation === "/services") {
      setservices(true)
      setlocationourteam(false);
      setRepresntative(false)

    } else if (exactLocation === "/representative") {
      setRepresntative(true)
      setservices(false)
      setlocation(false);
      setlocationourteam(false);

    } else {
      setlocation(false);
      setlocationourteam(false);
    }
  }, [exactLocation]);

  const handleLocationClick = (newLocationvalue) => {
    setlocation(newLocationvalue)
    setlocationourteam(false)
    setservices(false)
    setRepresntative(false)
  }

  const handleLocationClickteam = (newteamvalue) => {
    setlocationourteam(newteamvalue)
    setlocation(false)
    setRepresntative(false)
    setservices(false)
  }

  const handleLocationClickservice = (newsevicevalue) => {

    setservices(newsevicevalue);
    setlocationourteam(false);
    setlocation(false);
    setRepresntative(false)
  }


  const representaivevalue = (valuerepresent) => {
    setRepresntative(valuerepresent);
    setlocationourteam(false);
    setlocation(false);
    setservices(false)
  }

  const Props = {
    titleone: "About us",
    titlesecond: "",
    titlethird: " ",
    Imgurl: Weare,
  };

  // const Whyusdata = {
  //   header: "WHYUS?",
  //   describe: "Sattvik Certifications Indonesia is dedicated to providing comprehensive food certifications that cater specifically to the unique requirements and regulations of the Indonesian market. ",
  //   bgimages: NewImage
  // }

  // const NewWhyusdata = {
  //   header: "WHAT WE DO?",
  //   bgimages: NewImageSecon,

  // }

  const subheadernav = {
    one: "Who we are",
    two: "Our Team",
    three: "Services",
    four: "Representative"
  }







  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <Whowearenav subheadernav={subheadernav} handleLocationClick={handleLocationClick} handleLocationClickteam={handleLocationClickteam} handleLocationClickservice={handleLocationClickservice} representaivevalue={representaivevalue} />
      </section>
      <section>
        {location && <Whowearesubheader />}
      </section>
      <section>
        {location && <Msgfounder />}
      </section>
      {/* <section>
        {location && <Whyus data={Whyusdata} />}
      </section>

      <section>
        {location && <Whyus data={NewWhyusdata} />}
      </section> */}

      <section>
        {locationourteam && <Card />}
      </section>


      <section>
        {services && <Services />}
      </section>

      <section>
        {representative && <Representative />}
      </section>

    </>
  );
};

export default Whoweare;
