import React  from 'react'
import Landing from '../components/landing/landing'



const Landingpage = () => {

  
  return (

   <Landing/>

  )
}

export default Landingpage