import React from 'react'
import "../assests/css/footer/footer.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import logicon from "../assests/images/logo/sattvik-council-of-india-min.png"
import Footertwo from './footertwo'
import Footerterm from './footerterm'
// import indo from "../assests/images/indo/indonesia.png"
// import uk from "../assests/images/indo/uk.png"
import { NavLink } from 'react-router-dom'
import mainlogo from "../assests/images/icons/scsg.png"
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';



const Footer = () => {
  // useEffect(() => {
  //   let currentlang = localStorage.getItem('lang')
  //   i18next.changeLanguage(currentlang)
  // }, [])

  // const changelang = (l) => {
  //   i18next.changeLanguage(l)
  //   localStorage.setItem('lang', l)
  // };

  return (
    <>

      <footer className="text-center text-lg-start text-muted background-footer-color">

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3 responsive-style">
              <div className="col-md-3 col-lg-4 col-xl-3  mb-4  logo-style">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3 text-secondary"></i>
                  <img src={mainlogo} loading="auto" className="footer-logo" alt="logo" />
                </h6>
                <div className="all-address-content">
                  {/* <h3 className="text-center same-font-style title-logo">
                    Jika sattvic Maka Itu Murni
                  </h3> */}
                  <div className=" d-none d-lg-block get-connected-text">
                    <p>Follow Us:</p>
                  </div>

                  <div className="social-media-icons">
                    <NavLink to="https://www.facebook.com/profile.php?id=100091746805925">
                      <span className="me-4 link-secondary">
                        <i className="ri-facebook-fill icons " style={{ color: "white",borderRadius: "3px", fontSize: "23px" }} ></i>
                      </span>
                    </NavLink>

                    <NavLink to="https://x.com/Sattvikid?t=mDeN0UCH_yX-dsZwYmCtpg&s=09">
                      <span className="me-4 link-secondary" >
                        <FontAwesomeIcon icon={faXTwitter} className='x-ixon-footer' style={{ color: "white",  borderRadius: "3px", fontSize: "23px" }} />
                      </span>
                    </NavLink>

                    <NavLink to="https://www.instagram.com/sattvikcertificationssg/?igsh=YzVkODRmOTdmMw%3D%3D">
                      <span className="me-4 link-secondary">
                        <FontAwesomeIcon icon={faInstagram} className='x-ixon-footer' style={{
                             fontSize: "23px"
                        }} />
                      </span>
                    </NavLink>

                    <NavLink to="https://www.linkedin.com/company/scsg-sg/">
                    <span className="me-4 link-secondary">
                      <i className="ri-linkedin-fill icons" style={{ color: "white", borderRadius: "3px", fontSize: "23px" }}></i>
                    </span>
                    </NavLink>
                  </div>

                  <div className="address-name">
                    <address className='address-point'>
                      SATTVIK CERTIFICATIONS SINGAPORE PTE. LTD. <a href="mailto:info@scsg.sg">SATTVIK</a>.<br />
                      Visit us 35 Selegie Road<br />
                      # 09-02, Parklane Shopping Mall<br />
                      Singapore 188 307
                    </address>
                  </div>
                  {/* <p className="text-center same-font-indonesta">Sertifikasi Sattvik Indonesia</p>
                  <p className="text-center same-font-style">The Boulevard,Apartemen,Jl.H.</p>
                  <p className="text-center same-font-style">Fachrudin</p>
                  <p className="text-center same-font-style">Jakarta 10250 ,Indonesia</p> */}
                </div>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4 same-font-style">
                  Category
                </h6>
                <p>
                  <span><i className="ri-arrow-right-s-line icon-color"></i><span className="text-reset same-font-style">Event</span></span>
                </p>
                <NavLink to="/news">
                  <p>
                    <span><i className="ri-arrow-right-s-line icon-color"></i><span className="text-reset same-font-style">News</span></span>
                  </p>
                </NavLink>
                {/* <p>
                  <span><i className="ri-arrow-right-s-line icon-color"></i> <a href="google.com" className="text-reset same-font-style1">{t('Carrierf')}</a></span>
                </p> */}
                <NavLink to="/contacts">
                  <p>
                    <span><i className="ri-arrow-right-s-line icon-color"></i> <span className="text-reset same-font-style2">Contact</span></span>
                  </p>
                </NavLink>

                <div className="customer-care my-3 same-font-style-customer">
                  <h3>CUSTOMER CARE (Toll Free)</h3>
                  <h2>+65 93861039</h2>
                </div>
              </div>


              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4 same-font-style">
                  Useful links
                </h6>
                <NavLink to="/gallery">
                  <p>
                    <span><i className="ri-arrow-right-s-line icon-color"></i><a href="google.com" className="text-reset same-font-style" >Gallery</a></span>
                  </p>
                </NavLink>

                <NavLink to="/ourvision">
                  <p>
                    <span><i className="ri-arrow-right-s-line icon-color"></i> <a href="google.com" className="text-reset same-font-styleo">Vision</a></span>
                  </p>
                </NavLink>
                <NavLink to="/satcon">
                  <p>
                    <span><i className="ri-arrow-right-s-line icon-color"></i><a href="google.com" className="text-reset same-font-style">Satcon</a></span>
                  </p>
                </NavLink>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 codex-div">
                <div className='language'>
                  {/* <p >CHOOSE LANGUAGE</p>
                  <img className='indo' src={indo} onClick={() => changelang('id')} alt="indo" />
                  <img src={uk} className="eng" onClick={() => changelang('en')} alt="Eng" /> */}
                </div>


                <NavLink to="/codex">
                  <button type="button" className="btn btn-light btn-lg  custom-btn-codex">CODEX</button>
                </NavLink>
                <NavLink to="https://e-skvm.org/">
                  <button type="button" className="btn btn-light btn-lg  custom-btn-e-skvm">E-SKVM</button>
                </NavLink>
                <NavLink to="/training">
                  <button type="button" className="btn btn-light btn-lg  custom-btn-training">TRAINING</button>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <Footertwo />
        <Footerterm />

      </footer>


    </>
  )
}

export default Footer