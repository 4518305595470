import React , {useEffect} from 'react'
import Compsubheader from "../components/training/trainingsubheader"
import Weare from "../components/assests/images/indo/Singapore-min.jpg";
import Imptourpoint from '../components/tourism/imptourpoint';
import Artmin from "../components/assests/images/indo/sunsetsinga-min.jpg"
// import Whyus from "../components/whyus/whyus"
import AOS from "aos"
import 'aos/dist/aos.css'


const Whyuspage = () => {

    useEffect (() => {
    window.scrollTo(0,0)
    AOS.init({duration:3000})
    },[])



    const Props = {
        titleone: "About Us",
        titlesecond: "",
        titlethird: " ",
        Imgurl: Weare,
      };

 
      const Impacttourism = [
        {
          title: 'Market Expansion:',
          heading: 'Sattvik Certification allows food industries to tap into the growing market of health-conscious consumers, including vegetarians, vegans, Jains, Sattvam, and Buddhists. This broadens their customer base and market reach.',
    
        },
        {
          title: 'Enhanced Consumer Trust:',
          heading: 'Sattvik Certification instills trust in consumers. When they see the Sattvik Certified logo, they have confidence that the product or service meets rigorous quality and authenticity standards, improving their perception of the brand.',
        },
    
        {
          title: 'Competitive Advantage:',
          heading: 'Certified food industries gain a competitive edge by offering products and services that cater to a specific and often underserved market segment. This can help differentiate them from competitors.',
        },
    
        {
          title: 'Access to Niche Markets:',
          heading: 'Certification allows food industries to access niche markets with specific dietary preferences. This can lead to increased sales and brand loyalty among these targeted consumer groups.',
        },
    
        {
          title: 'Improved Brand Image:',
          heading: 'Being Sattvik Certified demonstrates a commitment to food safety, quality, and ethical practices. This can enhance the overall brand image and reputation, attracting a wider customer base.',
        },
    
        {
          title: 'Compliance with Standards:',
          heading: 'Certification ensures that food industries comply with established standards, fostering a culture of quality and safety within the organization. This can lead to more consistent product quality.',
        },
    
        // {
        //   title: 'International Recognition:',
        //   heading: 'T15',
        // },
    
        // {
        //   title: 'Community Engagement:',
        //   heading: 'T17',
        // },
    
    
        {
          title: 'Global Expansion:',
          heading: 'Sattvik Certification can facilitate entry into international markets, especially in regions with a significant vegetarian and vegan population or in countries where plant-based diets are gaining popularity.',
        },
    
        {
          title: 'Cultural and Ethical Responsibility:',
          heading: 'Sattvik Certification aligns with cultural and ethical values, demonstrating that the food industry is sensitive to the cultural and ethical preferences of consumers.',
        },
    
        {
          title: 'Consumer Education:',
          heading: 'Certification encourages consumer education and awareness about the benefits of vegetarian and vegan diets, contributing to a more informed and health-conscious society.',
        },
    
        {
          title: 'Sustainability:',
          heading: 'Plant-based diets are often associated with lower environmental impact. Sattvik Certification can align with sustainability efforts, which is an increasingly important factor for consumers and regulatory bodies.'
        },
    
        
        {
            title: 'Access to Resources:',
            heading: 'Food industries that are Sattvik Certified may have access to a network of like-minded businesses, sharing resources and knowledge for mutual benefit.'
          },

          {
            title: '',
            heading: 'Sattvik Certification offers food and lifestyle industries a range of benefits, including market expansion, improved brand image, compliance with standards, and access to niche markets. These advantages can lead to increased consumer trust and, ultimately, greater success in a competitive and evolving food industry landscape.'
          },
      ];

      const Impacttourismback = {
        BuddhismImg: Artmin,
      }


      


  return (
    <>
    <section>
    <Compsubheader Props={Props} />
  </section>
 
  <div className="  text-dark my-5 text-center ">
                <h1 className="whoweare-css-heading" data-aos="fade-up" data-aos-anchor-placement="top-bottom " >Why Us ?</h1>
                {/* <p className="whoweare-css-heading">An Introduction to Sattvik Certifications Singapore</p> */}
            </div>
  <section>
   <Imptourpoint Impacttourism={Impacttourism} Impacttourismback={Impacttourismback} />
  </section>
      </>
  )
}

export default Whyuspage