import React from 'react'
import "../assests/css/training/kitchen.css"


const Kitchen = () => {

  return (
    <>
            <h2 className="code-heading">Kitchen Management</h2>
            <p className='code-content'>Kitchen Management training program helps an individual to build a career in audit field for the Sattvik kitchen certification. This program introduce the students to the principles of vegetarian kitchen standards the Panch tava Gun’s world’s first classification of kitchen as per the standards the requirements of Vastu compliance in the premises as the standards the current need for the safe vegetarian supply chain system the classification of Sattvik Foods. It will help the individual acquire the necessary skills to conduct an audit program internally or externally and also help to plan conduct and report the audit as per the Sattvik vegetarian standards.</p>
            <p className='quality-code '>Fee : Rs. 9999/-</p>
            <p className='code-quality'>Inclusive of 18%GST</p>
            <p className='code-footer-one'>This program is available with Art of Living by paying Rs 1000/- extra.</p>
        </>
  )
}

export default Kitchen 
