import React, { useEffect } from 'react'
import Legalnotice from "../components/privacy/privacy"
import Lega from "../components/assests/images/privacy/legal.jpg"
import Compsubheader from "../components/training/trainingsubheader";
const Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Props = {
    titleone: " ",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Lega,
  };
  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <Legalnotice />
      </section>

    </>
  )
}

export default Privacy