import React, {useState} from 'react'
import "../assests/css/SATCON/satcon.css"
import Pop  from '../pop/pop'

const Satcon = () => {
 const [fromData ,setFormData] = useState({})
 const [pop , setnewpop] = useState(false)
 const [displaycontent , setdisplay] = useState(true)

 
const submitBasicForm = async (e) => {
  try {
    e.preventDefault();
    const BasicDetailResponse = await fetch("https://api.scsg.sg/ap1/v1/formdata", {
      method: "POST",
      body: JSON.stringify(fromData),
      headers: {
        "Content-Type": "application/json"
      }
    });

    // Handle the response as needed
    if (BasicDetailResponse.ok) {
      console.log("Form submitted successfully");
      setnewpop(true)
      setdisplay(false)
    } else {
      console.log("Error submitting form");
      setnewpop(false)
      setdisplay(true)
    }

  } catch (error) {
    console.log(`Error: ${error}`);
  }
};



  const handleInputChange = ((e) =>{
    setFormData({
      ...fromData,
      [e.target.name] : e.target.value
    })

  })


  return (
    <>
    {displaycontent &&
    <>
      <div className="container-fluid p-5   text-center custom-div-satcon">
        <h1 className="containerdata">SATCON</h1>
        <p className="containerdata">Course Code: SCTN2079002</p>
      </div>

      <div className="container mt-8">
        <div className="row custom-satcon-row">
          <div className="col-sm-5 new-col">
            <div>
              <h1 className='satcon-css'>Description:</h1>
              <p className="satcon-para">Embark on a transformative one-hour course delving into the principles of food safety culture as per Indian Vedas and Upanishads, defined by Sattvik Certifications (Course Code: SCTN2079002). Understand the profound impact of Sattvik principles on the mind and soul, gaining insights into the classification of foods based on Gunas, Ayurvedic perspectives, and the significance of Pancha Tattva (the five elements).</p>
            </div>
            <div>
              <h1 className='satcon-css'>Learning Objectives:</h1>
              <p className="satcon-para">1. Explore the essence of Sattvik principles.</p>
              <p className="satcon-para">2. Understand the origin and definition of Sattvik.</p>
              <p className="satcon-para">3. Classify foods based on Gunas for mindful consumption.</p>
              <p className="satcon-para">4. Gain insights into Sattvik practices in Ayurveda.</p>
              <p className="satcon-para">5. Comprehend the role of the Pancha Tattva in food choices.</p>
              <p className="satcon-para">6. Apply Sattvik certification principles for a balanced and purposeful lifestyle.</p>
            </div>

            <div>
              <h1 className='satcon-css'>Objective:</h1>
              <p className="satcon-para">1. Upon completion, attendees will:</p>
              <p className="satcon-para">2. Grasp the combination of Vedic concepts supporting the reduction of foodborne illnesses.</p>
              <p className="satcon-para">3. Recognize the impact of healthy food choices on organisational behaviour.</p>
              <p className="satcon-para">4. Learn to use recognition to influence and strengthen behaviour change initiatives.</p>
              <p className="satcon-para">5. Enhance knowledge about tools accelerating behaviour change in food safety programs.</p>
            </div>

            <div>
              <h1 className='satcon-css'>Types of Sattvik Consciousness Programs:</h1>
              <p className="satcon-para">1. Sattvik Consciousness for Corporates</p>
              <p className="satcon-para">2. Sattvik Consciousness for Schools</p>
              <p className="satcon-para">3. Sattvik Consciousness for Artists</p>
            </div>

            <div>
              <h1 className='satcon-css'>Target Group:</h1>
              <p className="satcon-para">1. Schools</p>
              <p className="satcon-para">2. Corporate</p>
              <p className="satcon-para">3. Artists</p>
              <p className="satcon-para">4. Kids </p>
              <p className="satcon-para">5. Individuals involved in the food safety management system within their organisation.</p>
            </div>

            <div>
              <h5 className='satcon-para-data'>Join us on this enlightening journey to harmonise food safety with timeless Vedic wisdom, fostering a Sattvik Consciousness for a healthier and purposeful life.</h5>
            </div>

          </div>
          <div className="col-sm-6 new-col-one">
            <div className="data-input">
            <h1 className='satcon-css'>Fill the Form to Contact Us Regarding SATCON / LAP PROGRAM</h1>
            <form onSubmit = {submitBasicForm}>
                <input type="text" placeholder="First Name" name ="firstName" required onChange={handleInputChange}/>
                <input type="text" placeholder="Middle Name" name = "secondName" required onChange={handleInputChange}/>
                <input type="text" placeholder="Last Name"  name = "thirdName" required onChange={handleInputChange} />
                <input type="email" placeholder="Email" name="fourthName" required onChange={handleInputChange}/>
                <input type="number" placeholder="Mobile Number" name="fivthName" required onChange={handleInputChange} />
                <input type="submit" placeholder='SUBMIT' required/>
              </form>
            </div>
          </div>
        </div>
      </div>
      </>
    }
      { pop && <Pop/>}
    </>
  )
}

export default Satcon