import React from 'react';
// import Loader from "../src/components/loader/loader"
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// import Floatingpage from "../src/components/floating/floating"
import Home from "./pages/Home";
import Navbar from "./components/navbar/navbar"
import Footer from "./components/footer/footer"
//import ErrorPage from "./pages/error"
// import Services from "./pages/services";
// import Respresentative from "./pages/respresentative";
// import Certification from "./certification";
import Whoweare from "./pages/whoweare";
// import Ourteam from "./pages/ourteam";
// import Ourhistory from "./pages/ourhistory";
// import Ourfuture from "./pages/ourfuture";
// import Association from "./pages/association";
// import Operatingcountry from "./pages/operatingcountry";
import News from "./pages/news"
import Codex from "./pages/codex";
import Gallery from "./pages/gallery"
// import Bussiness from "./pages/bussiness"
// import Integrity from "./pages/integrity";
import Tourism from "./pages/tourism";
import Certificate from "./pages/certificate";
import Training from "./pages/training";
import Company from "./pages/ourcompany"
import Brands from "./pages/brand";
// import Indiaindonesia from "./pages/indiaindonesia";
import Privacy from "./pages/legalnotice"
import Termofuse from "./pages/termofuse"
import Cookies from "./pages/cookies"
import Privacypolicy from "./pages/privacy";
import Term from "./pages/termsandcodition"
import Contact from "./pages/contacts"
import Vision from "./pages/vision"
// import Asita from "./pages/asita"
import Landing from "./pages/landing"
import Whyus from "./pages/whyus"
import Sattvikscheme from "./pages/sattvikscheme"
import Sattviksingapore from "./pages/sattviksingapore";
import Contactus from "./pages/contactnav"
import SATCON from "./pages/satcon"
import MyComponent from './components/bot/chatboat';
function App() {



  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  // const [loading, setLoading] = useState(true);
  const location = useLocation();
  const currentURL = location.pathname;

  // useEffect(() => {
  //   // Simulate an API call or any async operation
  //   const fetchData = async () => {
  //     // Add your async logic here
  //     await new Promise(resolve => setTimeout(resolve, 6000)); // Simulating a delay

  //     // Set loading to false once data is fetched
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, []);
  return (
    <>
      {currentURL === '/' ? "" : <Navbar />}
      <MyComponent />
      {/* <Floatingpage /> */}

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Whoweare />} />
        <Route path="/representative" element={<Whoweare />} />
        <Route path="/Whoweare" element={<Whoweare />} />
        <Route path="/ourteam" element={<Whoweare />} />
        <Route path="/ourhistory" element={<Company />} />
        <Route path="/ourfuture" element={<Company />} />
        {/* <Route path = "/associations" element = {<Association/>}/> */}
        <Route path="/operatingcountry" element={<Company />} />
        <Route path="/news" element={<News />} />
        <Route path="/codex" element={<Codex />} />
        <Route path="/bussiness" element={<Company />} />
        {/* <Route path="/integrity" element={<Integrity />} /> */}
        <Route path="/tourism" element={<Tourism />} />
        <Route path="/certificate" element={<Certificate />} />
        <Route path="/training" element={<Training />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/gallery" element={<Gallery />} />
        {/* <Route path="/india&indonesia" element={<Indiaindonesia />} /> */}
        <Route path="/legalnotice" element={<Privacy />} />
        <Route path="/termofuse" element={<Termofuse />} />
        <Route path="/cookiespolicy" element={<Cookies />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/termandcondtion" element={<Term />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/ourvision" element={<Vision />} />
        {/* <Route path="/asita" element={<Asita />} /> */}
        <Route path="/whyus" element={<Whyus />} />
        <Route path='/Sattvikscheme' element={<Sattvikscheme />} />
        <Route path='/Sattviksingapore' element={<Sattviksingapore />} />
        <Route path='/contactus' element={<Contactus />} />
        <Route path='/satcon' element={<SATCON />} />
        {/*** <Route path = "*" element = {<ErrorPage/>}/> */}
      </Routes>

      {currentURL === '/' ? "" : <Footer />}

    </>
  );
}

export default App;
