import React from 'react'
import '../assests/css/footer/footertwo.css'

const Footertwo = () => {
    const NewcurrentYear = new Date().getFullYear();
  return (
    <div className="text-center p-3 footertwo-style ">
    © {NewcurrentYear} Copyright : 
    <span className="text-white">Sattvik Certifications Singapore</span>
  </div>
  )
}

export default Footertwo