import React, { useEffect } from 'react'
import Covertourist from "../components/training/trainingsubheader"
import Newsimage from "../components/assests/images/indo/pexel.jpg"
import Tourismone from "../components/tourism/tourone"
import Touronedetail from '../components/tourism/touronedetail'
import Tourismdetail from "../components/tourism/impacttourism"
import Tourismimapct from "../components/tourism/imptourpoint"
import Buddhism from '../components/tourism/buddhism'
import Scene from '../components/assests/images/indo/scene.jpg'
import SittingBuddha from "../components/assests/images/indo/siiting-buddha.jpg"
import ImapctBuddha from "../components/tourism/buddhaimpact"
import LastBuddhism from '../components/tourism/lastsatvikbuddha'
import BuddhismImg from "../components/assests/images/indo/buddhism-1822518-min.jpg"
import BuddhismImgblack from "../components/assests/images/indo/buddhaimage.jpg"
import BuddhismImgsecond from "../components/assests/images/indo/gautam.jpeg"
import Artmin from "../components/assests/images/indo/arti-min.jpg"
const Tourism = () => {

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const Props = {
    titleone: "Tourism in Indonesia",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Newsimage,
  };

  const Propsdata = {
    headingdata: "Impact of Sattvik Certification on Indonesian Tourism",
    content: "Sattvik Certification, by providing assurance to the consumers regarding the authenticity of the food they are consuming is purely vegetarian, will prove to be a major boost for the economy of Indonesia.",
    secondcontent: "It will have the following positive impacts on the economy of Indonesia:",
    bgimage: Scene
  };

  const Propsdatabuddhist = {
    headingdata: "Impact of Sattvik Certification on Buddhist Community",
    content: "T33",
    secondcontent: "T34",
    bgimage: SittingBuddha,
  };


  const NewdataBuddhism = {
    titleonebuddha: "Buddhism in Indonesia",
    contentonebuddha: "T30",
    contentwobuddha: "T31",
    contenthreebuddha: "T32",
    Imagedatabuddha: BuddhismImg,
    bgbuddha: BuddhismImgblack,
    newcolor: 'white'
  }

  const secondparadata = {
    contentsecondpara: "T35",
    ImageOne: BuddhismImgsecond,
  }


  const Buddha = [

    {
      title: "Religious Compliance:",
      content: "T23"
    },

    {
      title: "Cultural Identity:",
      content: "T24"
    },

    {
      title: "Social and Community Cohesion:",
      content: "T25"
    },

    {
      title: "Consumer Confidence:",
      content: "T26"
    },

    {
      title: "Economic Impact:",
      content: "T27"
    },

    {
      title: "Interfaith Relations:",
      content: "T28"
    },

    {
      title: "Environmental Considerations:",
      content: "T29"
    }


  ]

  const Newdata = [
    {
      title: "Indonesia's Vibrant Population:",
      content: "T4",
    },

    {
      title: "Rise of Vegetarian and Vegan Preferences:",
      content: "T5",
    },

    {
      title: "Indonesia's Unique Vegetarian Landscape:",
      content: "Indonesia's approach to vegetarianism is multifaceted, shaped by cultural,religious, and environmental factors. While vegetarianism isn't a widespread dietary choice, specific communities and regions do adopt it. Notably, Bali, with its Balinese Hindu majority, champions a vegetarian-friendly culture rooted in the balance between nature, humans, and the divine.",
    },

    {
      title: "Harmony with Nature:",
      content: "T6",
    },

    {
      title: "Ahimsa: A Universal Principle:",
      content: "T7",
    },

    {
      title: "Culinary Riches of Indonesia:",
      content: "T8",
    },

    {
      title: "Migrating Populations and Vegetarianism:",
      content: "T9",
    },

    {
      title: "Balancing Tradition and Evolution:",
      content: "T10",
    },

    {
      title: "Culinary Riches of Indonesia:",
      content: "T8",
    },
    // You can add more items to the Newdata array if needed.
  ];

  const Impacttourismback = {
    BuddhismImg: Artmin,
  }

  const Impacttourism = [
    {
      title: 'Quality Assurance:',
      heading: 'T11',

    },
    {
      title: 'Culinary Diversity and Authenticity:',
      heading: 'T12',
    },

    {
      title: 'Market Credibility:',
      heading: 'T13',
    },

    {
      title: 'Economic Growth:',
      heading: 'T14',
    },

    {
      title: 'International Recognition:',
      heading: 'T15',
    },

    {
      title: 'Improved Tourism Management:',
      heading: 'T16',
    },

    // {
    //   title: 'International Recognition:',
    //   heading: 'T15',
    // },

    // {
    //   title: 'Community Engagement:',
    //   heading: 'T17',
    // },


    {
      title: 'Health and Safety:',
      heading: 'T18',
    },

    {
      title: 'Culinary Tourism:',
      heading: 'T19',
    },

    {
      title: 'Sustainable Practices:',
      heading: 'T20',
    },

    {
      title: 'Enhanced Reputation:',
      heading: 'T21'
    },

  ];
  return (
    <>
      <section>
        <Covertourist Props={Props} />
      </section>

      <section>
        <Tourismone />
      </section>

      <section>
        <Touronedetail Newdata={Newdata} />
      </section>

      <section>
        <Tourismdetail Propsdata={Propsdata} />
      </section>
      
      <section>
        <Tourismimapct Impacttourism={Impacttourism} Impacttourismback={Impacttourismback} />
      </section>

      <section>
        <Buddhism NewdataBuddhism={NewdataBuddhism} />
      </section>

      <section>
        <Tourismdetail Propsdata={Propsdatabuddhist} />
      </section>

      <section>
        <ImapctBuddha Buddha={Buddha} />
      </section>

      <section>
        <LastBuddhism secondparadata={secondparadata} />
      </section>
    </>
  )
}

export default Tourism