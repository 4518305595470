import React from 'react'
import "../assests/css/codex/codex.css"

const Hospitalitykitechen = () => {
 
  return (
    <>
    <h1  className='color-code-code'>Kitchen in hotel/establishment</h1>
    {/* <h3 className='color-code'>{selectedCertificationId}.Country{industry}{selectedvikram2079id}{selectProfiledetailsid}{subclasssvalue}{microindexvalue}{comissioncosde}</h3> */}
    <p className="color-code-new">A kitchen in hotel in which the food is prepared with 100 % vegetarian ingredients, and ensuring free from contamination by non-Vegetarian food (Ex: Eggs, Meat, Fish, etc.) or any ingredients derived from animal slaughter.</p>
    </>
  )
}

export default Hospitalitykitechen