import React, { useEffect } from 'react'
import Privacypolicy from '../components/privacy/privacypolicy'
import Paperworktwo from "../components/assests/images/privacy/paperwork2.jpg"
import Compsubheader from "../components/training/trainingsubheader";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Props = {
    titleone: " ",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Paperworktwo,
  };
  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <Privacypolicy />
      </section>
    </>

  )
}

export default Privacy