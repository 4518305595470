import React , {useEffect} from 'react'
import "../assests/css/popup/popup.css"

const Pop = () => {
  useEffect(()=>{
   window.scrollTo(10,10)
  },[])
  return (
    <>
    <div className="form-submitted-successfully">
    <h1 className="subitted-sucees">Form Submitted Successfully</h1>
    <p className="text-thanku">Thankyou For Contacting Us : Someone will Contact you soon!</p>
    </div>
   </>
  )
}

export default Pop