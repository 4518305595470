import React from 'react'
import "../assests/css/training/hospitality.css"


const Hospitality = () => {
 
  return (
    <>
            <h2 className="code-heading">Hospitality Management</h2>
            <p className='code-content'>With the increasing numbers of millennial adopting vegetarianism and veganism people are becoming more conscious about their environment or surroundings the food/ hotels/ clothes/ cosmetics etc. everything requires as per the vegetarian and veganism standards. For years it has been difficult for people with vegetarian or vegan lifestyles to find hotels/ bed & breakfast or guest houses that naturally cater to their needs and desires. Sattvik Vegetarian hospitality training will help hotels bed & breakfast and guest houses to convert it into Vegetarian/ vegan friendly hotels as per Vegetarian/ vegan standards. This training will provide a wider range of knowledge for the requirements for basic Vegetarian/ Vegan properties. It help the organization to understand the basic principles of Sattvik Hotel Management & maintenance.</p>
            <p className='quality-code '>Fee : Rs. 9999/-</p>
            <p className='code-quality'>Inclusive of 18%GST</p>
            <p className='code-footer-one'>This program is available with Art of Living by paying Rs 1000/- extra.</p>
        </>
  )
}

export default Hospitality