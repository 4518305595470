import React, { useEffect } from 'react'
import Subheadertwo from '../components/subheadertwo/subheadertwo'
import Subheader from '../components/subheader/subheader'
import Marqueecreate from '../components/marquee/marquee'
import Ourservices from "../components/ourservices/ourservices"
import Slider from "../components/textslider/slider"
import Books from "../components/booksslider/book"


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <section>
        <Subheader />
      </section>
      <section>
        <Marqueecreate />
      </section>
      <section>
        <Books />
      </section>
      <section>
        <Ourservices />
      </section>
      <section>
        <Subheadertwo />
      </section>
      <section>
        <Slider />
      </section>

    </>
  )
}

export default Home