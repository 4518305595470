import React, { useEffect } from 'react'
import Contactfile from "../components/contact/contact"


const Contacts = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <section>
        <Contactfile />
      </section>


    </>

  )
}
export default Contacts
