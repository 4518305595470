import React from 'react'
import "../assests/css/tourism/buddhism.css"


const Buddhism = ({NewdataBuddhism}) => {

    const divContainerStyle = {
        backgroundImage: `url(${NewdataBuddhism.bgbuddha})`, // Corrected backgroundImage property and template literal
        backgroundAttachment: 'fixed', // Fixed backgroundAttachment property
        backgroundRepeat: 'no-repeat', // Fixed backgroundRepeat property
        backgroundSize: 'cover', 
   
    };

    const newstyle = {
        color: NewdataBuddhism.newcolor, 
     
      };

    return (

        <div className="container-fluid mt-5 data-container-imahe-value overlay-new-element-dat" style={divContainerStyle}>
        <div className="new-view"></div>
       
            <div className="row custom-row">
                <div className="col-sm-4 new-width">
                    <h3 className='heading-buddhism' style={newstyle}>{NewdataBuddhism.titleonebuddha}</h3>
                    <p className='heading-buddhism-content' style={newstyle}>{NewdataBuddhism.contentonebuddha}<span className='color-data'>{NewdataBuddhism.contentwobuddha}</span>{NewdataBuddhism.contenthreebuddha}</p>
                </div>

                <div className="col-sm-4 new-css-image">
                    <div className="container newone-image-css-value">
                        <img src={NewdataBuddhism.Imagedatabuddha} loading="auto" alt="error" className='new-image-one both-image' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Buddhism