import React, { useState } from 'react'
import "../assests/css/contactusNav/contactusnav.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Contactnavcomp = () => {

    const [input, setInputValue] = useState({})

    const handleChanged = ((e) => {
        setInputValue({
            ...input,
            [e.target.name]: e.target.value,
        })
    })

   


    const handleSubmit = ((e) => {
        e.preventDefault()
        try {
            const response = fetch("https://api.scsg.sg/ap1/v12/contactdata", {
                method: "POST",
                body: JSON.stringify(input),
                headers: {
                    "Content-Type": "application/json"
                },

            })
            console.log(response.status)
            toast.success("Form Submitted Successfully",{
                theme: "dark",
            });
           

        } catch (error) {
            console.log("Plese Check this erro " + error)
        }
    })

    return (
        <>
        <ToastContainer/>
            <div className='container custom-contact-us-css '>
   

                <div className='heading-contact'>
                    <h1 className='heading-contact-one'>Join Our Team</h1>
                    <p className="para-graph-one">At SCSG, we believe that our people are our most valuable asset. We're always on the lookout for talented, motivated individuals who share our passion for vegetarian/vegan/buddhist/Jain certification for food & lifestyles.</p>
                </div>
                <div className='heading-contact'>
                    <h1 className='heading-contact-one'>Why Work With Us?</h1>
                    <p className="para-graph-one">Innovative Environment: Join a team that thrives on innovation and is constantly seeking new and better ways to solve challenges in our industry.</p>
                    <p className="para-graph-one">Career Growth: We are committed to the professional growth and development of our employees. Your success is our success.</p>
                    <p className="para-graph-one">Work-Life Balance: We understand the importance of work-life balance and offer flexible schedules to help you excel at work and enjoy your personal life.</p>
                    <p className="para-graph-one">Diverse and Inclusive: We value diversity and inclusivity. We're proud of our diverse team and the perspectives and ideas it brings to the table.</p>
                    <p className="para-graph-one">Impactful Work: Make a difference in the world by contributing to projects and initiatives that matter. Your work will have a meaningful impact.</p>
                </div>

                <div className='heading-contact' >
                    <h1 className='heading-contact-one' >Current Openings</h1>
                    <h1 className='heading-contact-one text-primary' > No vacancy</h1>
                </div>

                <div className='heading-contact'>
                    <h1 className='heading-contact-one'>How to Apply</h1>
                    <address className=''>
                        <p className="para-graph-one">To apply for a position at SCSG, please send your resume and a cover letter to   <a href="mailto:info@scsg.sg">info@scsg.sg</a>. In your cover letter, tell us why you're interested in working with us and what you can bring to our team.</p>
                    </address>
                </div>

                <div className='heading-contact'>
                    <h1 className='heading-contact-one'>Internships and Career Development</h1>
                    <p className="para-graph-one">
                        Are you a student or recent graduate looking for an internship opportunity? We offer a range of internships in various departments, allowing you to gain practical experience and learn from our experts. We also offer career development programs to help you grow in your role and reach your full potential. Your success is important to us.</p>
                </div>
                <div className='heading-contact'>
                    <h1 className='heading-contact-one'>Join Our Talent Pool</h1>
                    <p className="para-graph-one">If you don't see a current opening that matches your skills and interests, you can still connect with us by joining our talent pool. We'll keep your information on file and reach out if a suitable position becomes available. We're excited to learn more about you and how you can contribute to our team. Come grow with us.
                    </p>

                </div>
            </div>
            
           
          
            <div className="container width-container">
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7903252919723!2d103.84446578477595!3d1.3006663583633835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19bc8a338d27%3A0xc1bfd283b2585ce4!2s35%20Selegie%20Rd%2C%20%2309%2002%2C%20Singapore%20188307!5e0!3m2!1sen!2sin!4v1703156446299!5m2!1sen!2sin" title="Example Website" width="100%" height="550" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="data-input-width">
                    <h1 className='satcon-css'>Fill the Form to Contact us</h1>
                    <form onSubmit={handleSubmit} >
                    <div className='new-value-form'>
                        <input type="text" className='input-value' placeholder="First Name" name="firstName" required onChange={handleChanged} />
                        <input type="text"  className='input-value new-middle-name' placeholder="Middle Name" name="secondName" required onChange={handleChanged} />
                        <input type="text"   className='input-value' placeholder="Last Name" name="thirdName" required onChange={handleChanged} />
                        <input type="email"  className='input-value new-email' placeholder="Email" name="fourthName" required onChange={handleChanged} />
                        <input type="number"  className='input-value' placeholder="Mobile Number" name="fivthName" required onChange={handleChanged} />
                        <div>
                        <input type="submit"   placeholder='SUBMIT' required />
                        </div>
                        </div>
                    </form>
                </div>
               
            </div>

        </>

    )
}

export default Contactnavcomp