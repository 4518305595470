import React from 'react'
import "../assests/css/whoweare/msgfounder.css"
import Whoweareone from "../assests/images/founder/abhishek-biswas.jpg"
// import Whowearesecond from "../assests/images/indo/whoweare-minified (2).jpg"
const Msgfounder = () => {

  return (
    <>



      <div className="container-fluid my-5  bg-image mt-5">
        <div className="row give-gap">

          <div className="col-sm-4 founder-image-photo">
            <div className="container newone-image-css">
              <img  src={Whoweareone} loading="auto" alt="error" className='new-image-one' />
            </div>
          </div>

          
          <div className="col-sm-6 set-width">
          <h1 className='message-text'>MESSAGE FROM FOUNDER</h1>
            <p className='content-details'>
            I would like to express my gratitude to the many people who saw me through this project; to all those who provided support, talked things over, read, wrote, offered comments, allowed me to quote their remarks and assisted in the formation of Sattvik India Council and designing the world’s first single window vegetarian food certification program from India, by India Sattvik certification is designed keeping the 5000 years of Indian traditions, heritage and Vedas and implementing it up with modern sciences and industry, in the process of writing the manual and accumulating every information I specially thank all my research team who have dedicated contributions for the Manual and to all the researchers on board Sattvik India for their inputs that I could convert an idea into reality. At Sattvik India council, we describe food safety as large and transformative, all the global forces that define the future of food safety, Sattvik Certification will have a far-reaching impact on businesses, economies, industries, societies and individuals related to Food. We live in a world in constant motion. Goods, capital and labor are traveling globally at a faster pace than ever and moving in novel patterns. Technological innovation, including digital, is rewriting every industry including the food industry In this world, the ever-increasing acceleration of change is one of the few constants, and Sattvik India Certification would be that constant.
            </p>
          </div>
        </div>
      </div>
    </>

  )
}

export default Msgfounder