import React from 'react'
import "../assests/css/training/railwaycomponent.css"

const Railwaycomonent = () => {

  return (
    <>
    <h2 className='code-heading'>Vegetarian Friendly Railway Services</h2>
    <p className='code-content'>There is an increasing demand for capable railroad transportation services and products to provide comprehensive vegetarian friendly services (VFS). An array of civil and mechanical specialists / services and products such as train / safety enhancer / value added services etc. make the railroad transport and logistics chain supply management very critical for the vegetarian certified services or products. While continuing the journey of faith VFRS ensures prohibition on non-vegetarian food use of organic chemicals and maintaining the vegetarian environment. Vegetarian friendly railway services generally refers to railways offering products and services that accommodate vegetarian travelers need to the varying extent. The vegetarian friendly is used mainly to describe services and products encompasses the full spectrum of vegetarian compliance.</p>
    <p className='code-pointone'>The Training Program for Vegetarian Friendly Services SCITN2079041 is sub divided in to 6 components:</p>
    <p className="code-quality ">1.Vegetarian Budget hotel management (SCTN2079141)</p>
    <p className="code-quality ">2.Vegetarian Base kitchen management(SCTN2079241)</p>
    <p className="code-quality ">3.Vegetarian Catering & service management (SCTN2079341)</p>
    <p className="code-quality ">4.Passenger locomotive Continuous audit module (SCTN2079441)</p>
    <p className="css-bullet-point">Vegetarian friendly railway services is clearly a growing market segment / with vegetarian tourists seeking destinations which meet their needs / in terms of food / faith or beliefs. With a burgeoning middle class and increasing disposable income many countries would adapt to attract these travelers.</p>
    <p className='code-gst'>Vegetarian Friendly Railway Services (VFRS) works on three basic principles:</p>
    <p className='css-bullet-point'>1.Understand the needs and preferences of Vegetarian travelers</p>
    <p className='css-bullet-point'>2.Provide amenities / alternatives or flexibilities that cater to those needs</p>
    <p className='css-bullet-point'>3.Communicate availability of Vegetarian -friendly features as unique selling point</p>
    <p className="code-railwayprice">Fee : Rs. 25000/-</p>
    <p className=''>Inclusive of 18% GST</p>
    <p className='code-footer-one'>This program is available with Art of Living by paying Rs 2500/- extra.</p>
</>
  )
}

export default Railwaycomonent