import React from 'react'
import Termofuse from '../components/privacy/termofuse'
import { useEffect } from 'react'
import paperwork from "../components/assests/images/privacy/paperwork.jpg"
import Compsubheader from "../components/training/trainingsubheader";
const Termofusedata = () => {


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Props = {
    titleone: " ",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: paperwork,
  };

  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <Termofuse />
      </section>
    </>
  )
}

export default Termofusedata