import React from 'react'
import "../assests/css/privacy/privacy.css"


const    Cookies = () => {

    return (
        <>
         <div className="container main-container-privacy">
            <div className="container-fluid p-5  text-dark text-center custom-legal-container">
                <h1 className="privacy-h1">Cookies Policy</h1>
                <p className="subheading-p3">In this policy, we use the term “cookies” to refer to cookies and other similar technologies covered by the EU Directive on privacy in electronic communications.
                </p>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3  className="privacy-h1">What is a cookie?</h3>
                        <p className="subheading-p2">A cookie is a small piece of data that a website asks your browser to store on your computer or mobile device. The cookie allows the website to remember your actions or preferences over time.Most Internet browsers support cookies; however, users can set their browsers to decline certain types of cookies or specific cookies. Further, users can delete cookies at any time.</p>
                    </div>
                </div>
            </div>


            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3  className="privacy-h1">Why do we use cookies?</h3>
                        <p className="subheading-p2">We use cookies to learn how you interact with our content and to improve your experience when visiting our website(s). For example, some cookies remember your language or preferences so that you do not have to repeatedly make these choices when you visit one of our websites. We also use cookies to help us with geolocation tracking in order to present you with the closest Sattvik station and office locations. Additionally, cookies allow us to serve you specific content, such as videos on our website(s). We may employ the learnings of your behaviour on our website(s) to serve you with targeted advertisements on third-party website(s) in an effort to “re-market” our products and services to you.What types of cookies do we use?</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="privacy-h1">First-Party and Third-Party Cookies</h3>
                        <p className="subheading-p2">We use both first-party and third-party cookies on our website. First-party cookies are cookies issued from the www.sattvikcertifications.id domain that are generally used to identify language and location preferences or render basic site functionality.Third-party cookies belong to and are managed by other parties, such as Sattvik business partners or service providers. These cookies may be required to render certain forms, such as the submission of a job application, or to allow for some advertising outside of the Sattvik website</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="privacy-h1">Session Cookies</h3>
                        <p className="subheading-p2">Session cookies are temporary cookies that are used to remember you during the course of your visit to the website, and they expire when you close the web browser.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="privacy-h1">Persistent Cookies</h3>
                        <p className="subheading-p2">Persistent cookies are used to remember your preferences within the website and remain on your desktop or mobile device even after you close your browser or restart your computer. We use these cookies to analyse user behaviour to establish visit patterns so that we can improve our website functionality for you and others who visit our website(s). These cookies also allow us to serve you with targeted advertising and measure the effectiveness of our site functionality and advertising.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="privacy-h1">How are cookies used for advertising purposes?</h3>
                        <p className="subheading-p2">Cookies and ad technology such as web beacons, pixels, and anonymous ad network tags help us serve relevant ads to you more effectively. They also help us collect aggregated audit data, research, and performance reporting for advertisers. Pixels enable us to understand and improve the delivery of ads to you and know when certain ads have been shown to you. Since your web browser may request advertisements and web beacons directly from ad network servers, these networks can view, edit, or set their own cookies, just as if you had requested a web page from their site.Although we do not use cookies to create a profile of your browsing behaviour on third-party sites, we do use aggregate data from third parties to show you relevant, interest-based advertising. We do not provide any personal information that we collect to advertisers. You can opt-out of offsite and third-party-informed advertising by adjusting your cookie settings. Opting out will not remove advertising from the pages you visit, but, instead, opting out will result in the ads you see not being matched to your interests. This implies that the ad(s) you see will not be matched to your interests by those specific cookies.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="privacy-h1">How are third-party cookies used?</h3>
                        <p className="subheading-p2">For some of the functions within our websites, we use third-party suppliers, for example, when you visit a page with videos embedded from or links to YouTube. These videos or links (and any other content from third-party suppliers) may contain third-party cookies, and we encourage you to consult the privacy policies of these third-party vendors on their websites for information regarding their use of cookies.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="privacy-h1">How do I reject and delete cookies?</h3>
                        <p className="subheading-p2">You can choose to reject or block all or specific types of cookies set by virtue of your visit to a Sattvik Council of Singapore website by clicking on the cookie preferences on our website(s). You can change your preferences for Sattvik websites and/or the websites of any third-party suppliers by changing your browser settings. Please note that most browsers automatically accept cookies. Therefore, if you do not wish cookies to be used, you may need to actively delete or block the cookies. If you reject the use of cookies, you will still be able to visit our websites but some of the functions may not work correctly. You may also visit www.allaboutcookies.org for details on how to delete or reject cookies and for further information on cookies generally. By using our website without deleting or rejecting some or all cookies, you agree that we can place those cookies that you have not deleted or rejected on your device.</p>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row row-privacy">
                    <div className="col-sm-12">
                        <h3 className="subheading-p-color">Sattvik Certifications Singapore</h3>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default Cookies