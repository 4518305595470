import React from 'react'
import "../assests/css/tourism/impacttourpoint.css"



const Imptourpoint = ({Impacttourism , Impacttourismback}) => {

  const backimage = {
    backgroundImage: `url(${Impacttourismback.BuddhismImg})`, 
    // backgroundAttachment: 'fixed', 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: 'cover', 
  }

  return (
    <div className="container-fluid mt-5 back-image-one " style ={backimage}>
       <div className="overlay-ind"></div>
      <div className="row gap-between-data">
        {Impacttourism.map((item, index) => (
          <div className="col-sm-4 margin-status" key={index}>
            <h3 className='heading-title'>{item.title}</h3>
            <p className='heading-content'>{item.heading}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Imptourpoint;
