// OptionButton.js
import React from 'react';

const OptionButton = ({ actions }) => {
  // console.log(actions.children.props.children.props.state)
  const buttonStyle = {
    display: 'block', // You can change 'block' to 'none' to hide the button initially
  };

  const handleclickYes =((e)=>{
    actions.handleYesNo(e.target.value)
  
  })

  const handleclickNo =((e)=>{
    actions.handleYesNo(e.target.value)
   })

  return (
    <>
    <div className="Yes-no">
    <button className='yes-button' value="yes" style={buttonStyle} onClick={handleclickYes}>
      Yes
    </button>
    <button className='No-button' value="no" style={buttonStyle} onClick={handleclickNo}>
      No
    </button>
    </div>
    </>
  );
};

export default OptionButton;