import React from 'react'
import "../assests/css/codex/codex.css"

const Jains = () => {

  return (
    <>
    <h1 className='color-code-code'>Train : Hygiene </h1>
    <p className="color-code-new">The train in which hygiene & sanitization is maintained as per the requirements of Sattvik certification scheme.</p>
    </>
  )
}

export default Jains