import React from 'react'
import "../assests/css/codex/codex.css"

const Laundry = () => {
  
  return (
    <>
    <h1 className='color-code-code'>Laundry in hotel/establishment</h1>
   {/* <h3 className='color-code'>{selectedCertificationId}.Country{industry}{selectedvikram2079id}{selectProfiledetailsid}{subclasssvalue}{microindexvalue}{comissioncosde}</h3> */}
   <p className="color-code-new"> A laundry in which material used are made with natural ingredients that still do the hard work of removing tough stains. Generally, this means that the ingredients are minimally processed and are derived without adding non-agricultural ingredients.</p>
   </>
  )
}

export default Laundry