import React ,{useEffect} from 'react';
import "../assests/css/services/services.css";

// import Serviceone from "../assests/images/sinaporedata/food-min.jpg";
// import Servicetwo from "../assests/images/sinaporedata/Singapore-min.jpg";
// import Servicethree from "../assests/images/sinaporedata/cosmetic-min.jpg";
// import Servicefour from "../assests/images/sinaporedata/agro-min.jpg";
// import Servicefive from "../assests/images/sinaporedata/textile-min.jpg";
// import Servicesix from "../assests/images/sinaporedata/marine-min.jpg";
// import Serviceseven from "../assests/images/sinaporedata/cruise-min.jpg";
// import ServiceEight from "../assests/images/sinaporedata/training-program--min.jpg";
// import Servicenine from "../assests/images/sinaporedata/employee_training_program-min.jpg";
import hospitality from "../assests/images/icons/hospitality.png"
import marines from "../assests/images/icons/resturants.png"
import agriculture from "../assests/images/icons/Agro.png"
// import dairy from "../assests/images/icons/hospitality.png"
import railway from "../assests/images/icons/railways.png"
import cosmetics from "../assests/images/icons/cosmetics.png"
import textile from "../assests/images/icons/industries.png"
import capsul from "../assests/images/icons/training.png"
import food from "../assests/images/icons/fast food.png"
import ServcesLap from "../serviceLAP/serviceLAP"
import AOS from "aos"
import 'aos/dist/aos.css'

const serviceData = [
  {
    image:food, 
    title: 'FOOD',
    description: 'We offer a comprehensive range of assurance and advisory services designed to assist vegetarian and vegan food businesses in delivering high-quality, safe, and compliant products to global markets. Our global network of food experts, comprised of highly qualified auditors and food safety specialists, leverages state-of-the-art laboratories and software applications to provide independent solutions that cater to your knowledge, risk management, and compliance needs.',
  },
  {
    image: hospitality,
    title: 'HOSPITALITY',
    description: 'The global tourism and hospitality industry faced a significant downturn during the pandemic. In the face of ongoing challenges related to climate change and environmental degradation, the hospitality sector now plays a pivotal role in advancing sustainability. We advocate for hotels to adopt certified practices for toiletries, laundry, and kitchen operations, thereby contributing to environmental preservation and responsible business practices.',
  },
  {
    image: cosmetics,
    title: 'COSMETICS',
    description: 'At Sattvik, we are dedicated to ensuring the safety and efficacy of your vegetarian and vegan cosmetics. Our local experts, in collaboration with a network of advanced laboratories and clinical facilities, assist you in verifying product safety and performance. We support your product claims through clinical trials, performance evaluations, and in-home use studies. With the aid of cutting-edge digital technologies, our specialists are well-equipped to guide you through evolving regulations and standards.',
  },

  {
    image: agriculture,
    title: 'AGRICULTURE',
    description: 'The vegetarian/vegan society contributes to one of the largest sectors worldwide. People are often in a doubtful situation regarding whether the products they are consuming are truly vegetarian or not. Even the Green logo comes with a benefit of doubt. Sattvik Certifications aims to bring about a vegetarian revolution where consumers from the vegetarian society can consume any product with confidence in terms of food safety and quality.',
  },

  {
    image: textile,
    title: 'TEXTILE',
    description: ' Post-pandemic, evolving societal values have prompted consumers to expect fashion companies to be driven by purpose, support social initiatives, and reduce their environmental impact in response to the climate change crisis. Key priorities now include diversity, inclusivity, equality, and minimising environmental footprints. Consumers are increasingly concerned about climate change, global waste issues, and are embracing plant-based and recycled alternatives in various aspects of their lives, including fashion choices.',
  },

  {
    image: marines,
    title: 'MARINES',
    description: 'Sattvik offers specialised food cargo inspection services designed for clients seeking reassurance not only during container loading and unloading but also confirmation that safety and quality requirements are met. These services ensure that correct goods handling procedures are established and adhered to, enhancing the overall integrity of marine cargo shipments.',
  },

  {
    image: railway,
    title: 'CRUIS-SHIP',
    description: 'Sattvik ensures safety no matter where you are. Cruise-ship establishments, including kitchens, restaurants, and rooms that meet Sattvik standards, are eligible for certification. Sattvik certified cruise ships provide 100% pure vegetarian and vegan food, along with various vegetarian-friendly amenities, offering passengers a unique and delightful culinary experience.',
  },


  {
    image: capsul,
    title: 'SATTVIK LAP',
    description: 'The "Sattvik Lead Auditor Program" is a specialised training and certification program designed to equip participants with the knowledge and skills required to become lead auditors in the context of Sattvik Management Standards and HACCP (Hazard Analysis and Critical Control Points) principles.',
  },


  {
    image: capsul,
    title: 'SATTVIK PROGG',
    description: 'The mind’s psychological qualities are highly unstable and can quickly fluctuate between the different gunas.The predominant guna of the mind acts as a lens that affects our perceptions and perspective of the world around us. Thus, if the mind is in rajas it will experience world events as chaotic, confusing and demanding and it will then have a strong tendency to continue to react to events in a rajasic way.',
  },

  


];

const Services = () => {
 useEffect(()=>{
  AOS.init({duration:2000})
 },[])

  return (
    <>
 
      <div className="container-fluid p-5  text-dark text-center text-custom ">
        <h1 className="whoweare-css-heading our-services-custom" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Our Services</h1>
        <p className="whoweare-css-heading"></p>
      </div>
      <div className='row new-contain-row'>
        {serviceData.map((service, index) => (
          <div className="card-one-two" key={index}>
            <div className="imgbox">
              <img src={service.image} loading="auto" alt="error" />
            </div>
            <div>
              <h2 className="heading-standered">{service.title}</h2>
            </div>
            <div className="content">
              <p className='content-services'>
                {service.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <ServcesLap/>
    </>
  );
};

export default Services;
