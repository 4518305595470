import React, { useState, useEffect, useMemo } from 'react';
import '../assests/css/subheader/subheader.css'; // Make sure this path is correct
import newimage1 from '../assests/images/indo/pexels-pixabay-52495.jpg'
import newimage2 from '../assests/images/indo/singapore1.jpg'
import newimage3 from '../assests/images/indo/tata-min.jpg'
import { NavLink } from 'react-router-dom';




const Subheader = () => {
  
  const [selectedImage, setSelectedImage] = useState(0);
  const backgroundImages = useMemo(() => [newimage1, newimage2,newimage3], []);
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloadImages = () => {
      backgroundImages.forEach((image) => {
        new Image().src = image;
      },[]);
    };
    preloadImages();

    // Automatically advance to the next image every 6 seconds
    const interval = setInterval(() => {
      setSelectedImage((selectedImage) =>
        selectedImage < backgroundImages.length - 1
          ? selectedImage + 1
          : 0
      );
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [backgroundImages]); 

  const Nextbutton = () => {
    setSelectedImage((selectedImage) =>
      selectedImage < backgroundImages.length - 1
        ? selectedImage + 1
        : 0
    );
  };

  const previousbutton = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage > 0
        ? prevSelectedImage - 1
        : backgroundImages.length - 1
    );
  };

  const newstyle = {
    height: '100vh',
    backgroundImage: `url(${backgroundImages[selectedImage]})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '93px',
    position: 'relative',
   transition: 'background 1s ease-in',
   backgroundAttachment: 'fixed'
   
  };

  const textAbove = {
    color: 'white', // Text color
    fontSize: '30px', // Text size
    position: 'absolute',
    top: '20%', // Adjust the top position
    left: '50%', // Center horizontally
    transform: 'translateX(-50%)', // Center horizontally
    zIndex: 2, // Place text above the overlay
    marginLeft: 0
    
  };




  return (
    <section>
      <div
        className="container-fluid p-5 d-flex flex-column justify-content-center align-items-center heightcss new-animation new-animation-one"
        style={newstyle}>
        <div className="row row-subheader-width " style = {textAbove}>
          <div className="col-sm-12  text-box">
            <h1>SATTVIK CERTIFICATIONS SINGAPORE</h1>
            {/* <p className="cetificate">{t('Indonesia')}</p> */}
            <h3 className="firstletter">
              Generating Vegetarian / Buddhist / Vegan - Food and Lifestyle
            </h3>
            <p className="change-color">
              
                Food / Hospitality / Marines / Railway / Agro Foods / Dairy / Cosmetics / Textile / Nutraceuticals
              
            </p>
            <div className="all-button">
            <NavLink to="/ourhistory">
              <button type="button" className="btn btn hovercolor mx-2  button-color-singa">
                KNOW MORE
              </button>
              </NavLink>
              <NavLink to="/contacts" >
              <button type="button" className="btn btn-danger hovercolor1">
                APPLY NOW
              </button>
              </NavLink>
              <div className="container-fluid width-crausel text-center">
                <i className="ri-arrow-left-s-line right-arrow" onClick={Nextbutton}></i>
                <i className="ri-arrow-right-s-line left-arrow" onClick={previousbutton} ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subheader;
