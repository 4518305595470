import React from 'react'
import "../assests/css/training/trainingcode.css"
// import Compsubheader from "../training/trainingsubheader"


const Trainingheader = () => {

  return (
    <>

   
      {/* heading section start */}
      <section>
      <div className="container-fluid p-5 text-white text-center my-5 custom-css-div">
        <h1>For detail of courses kindly select given options.</h1>
      </div>
      </section>
      {/* heading section End */}



    </>
  )

}

export default Trainingheader