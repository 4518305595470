import React from 'react';
import '../assests/css/tourism/touronedetails.css';


const Touronedetail = ({Newdata}) => {

  

  return (
    <>
      <div className="container mt-5">
        <div className="row custom-gap">
          {Newdata.map((item, index) => (
            <div className="col-sm-4 border-new" key={index}>
              <h3 className='title-color' >{item.title}</h3>
              <p className='content-color'>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Touronedetail;
