import React , {useEffect} from 'react'
import "../assests/css/marquee/marquee.css"
import Marquee from "react-fast-marquee";
// import Dypatil from '../assests/images/logo/dypatil.png'
import Anna from '../assests/images/logo/anna-min.png'
import Ashoka from '../assests/images/logo/ashoka-min.png'
import Dnv from '../assests/images/logo/dnv-min.png'
import Jaganath from '../assests/images/logo/jaganath-min.png'
import Patil from '../assests/images/logo/patil-min.png'
import Artofliving from '../assests/images/logo/artofliving-min.png'
import Bikaner from '../assests/images/logo/bikaner-min.png'
import Bureau from '../assests/images/logo/bureau_veritas-min.png'
import Davik from '../assests/images/logo/davik-min.png'
import Fedhasa from '../assests/images/logo/fedhasa-min.png'
import Fhrai from '../assests/images/logo/fhrai-min.png'
import Foxnut from '../assests/images/logo/foxy-min.png'
import Govinda from '../assests/images/logo/govind--min.png'
import Bhuwansher from '../assests/images/logo/ihm-min.png'
import Irctc from '../assests/images/logo/irctc-min.png'
import Ahmedabad from '../assests/images/logo/itc-hotel-ahmedabad-min.png'
import Chennai from '../assests/images/logo/itc-hotel-chnnai-min.png'
import Ihotel from '../assests/images/logo/itc-kolkata-min.png'
import Bengal from '../assests/images/logo/itc-bengal-min.png'
// import Ministry from '../assests/images/logo/ministryofskill.png'
// import Niti from '../assests/images/logo/nitiayog.png'
// import Nsdc from '../assests/images/logo/nsdc.png'
import Svsu from '../assests/images/logo/svsu-min.png'
import Varahi from '../assests/images/logo/varahi-min.png'
import Chukde from '../assests/images/logo/chukde-min.png'
import nchmct from '../assests/images/logo/nchmct-min.png'
import Bird from "../assests/images/logo/birdgroup-min.png"
import BlueTribe from "../assests/images/logo/bluetribe-min.png"
import Ekyam from "../assests/images/logo/ekyam-min.png"
import Enark from "../assests/images/logo/enark-min.png"
import Farmley from "../assests/images/logo/farmley-min.png"
import Gharkul from "../assests/images/logo/gharkul-min.png"
import Gooddot from "../assests/images/logo/gooddot-min.png"
import Hotel from "../assests/images/logo/hotelmanagement-min.png"
import Ikea from "../assests/images/logo/ikea-min.png"
import Isha from "../assests/images/logo/isha-min.png"
import Iskon from "../assests/images/logo/iskon-min.png"
import lotus from "../assests/images/logo/lotus-min.png"
import Manak from "../assests/images/logo/manak-min.png"
import Madhuram from "../assests/images/logo/mdhuram-min.png"
import Mirtillo from "../assests/images/logo/mirtillo-min.png"
import Panafrica from "../assests/images/logo/panafrica-min.png"
import Pcti from "../assests/images/logo/pcti-min.png"
import Radisson from "../assests/images/logo/radisson-min.png"
import roseat from "../assests/images/logo/roseat-min.png"
import Sri from "../assests/images/logo/srisri-min.png"
import Trident from "../assests/images/logo/trident-min.png"
import Ulsolution from "../assests/images/logo/ulsolution-min.png"
import Welex from "../assests/images/logo/welex-min.png"
import { NavLink } from 'react-router-dom';
import AOS from "aos"
import 'aos/dist/aos.css'




const Marqueecreate = () => {
  
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])


  const newstyle = {
    width: '200px',
    margin: '37px 50px 0px 0px',


  }
  const divide = {
    display: "flex",
    // overflow:"hidden",
  }

  return (
    <>
      <section>


        {/* text ourpartner start */}
        <div className="container my-5">
          <h1 className="text-center our-global"  data-aos="fade-up" data-aos-anchor-placement="top-bottom">Our Global Sattvik Certified Brands</h1>
        </div>
        {/* text ourpartner end */}


        {/* marquee images start */}

        <div className=" container-fluid" style={divide}>
          <Marquee gradient  direction='right' pauseOnHover="true">
            {/* <div>
              <img className="marquee-img1" style={newstyle} src={Dypatil} alt="img1" />
            </div> */}

            <div>
              <img className="marquee-img2" loading="auto" style={newstyle} src={Anna} alt="img2" />
            </div>

            <div>
              <img className="marquee-img3" loading="auto"  style={newstyle} src={Ashoka} alt="img3" />
            </div>

            <div>
              <img className="marquee-img4"  loading="auto" style={newstyle} src={Dnv} alt="img4" />
            </div>

            <div>
              <img className="marquee-img5" loading="auto" style={newstyle} src={Jaganath} alt="img5" />
            </div>

            <div>
              <img className="marquee-img6" loading="auto" style={newstyle} src={Patil} alt="img6" />
            </div>

            <div>
              <img className="marquee-img7" loading="auto" style={newstyle} src={Artofliving} alt="img2" />
            </div>

            <div>
              <img className="marquee-img8" loading="auto" style={newstyle} src={Bikaner} alt="img2" />
            </div>

            <div>
              <img className="marquee-img9" style={newstyle} src={Bureau} alt="img2" />
            </div>

            <div>
              <img className="marquee-img10" loading="auto" style={newstyle} src={Davik} alt="img2" />
            </div>

            <div>
              <img className="marquee-img11" loading="auto" style={newstyle} src={Fedhasa} alt="img2" />
            </div>

            <div>
              <img className="marquee-img12" loading="auto" style={newstyle} src={Fhrai} alt="img2" />
            </div>

            <div>
              <img className="marquee-img13" loading="auto" style={newstyle} src={Foxnut} alt="img2" />
            </div>

            <div>
              <img className="marquee-img14" loading="auto" style={newstyle} src={Govinda} alt="img2" />
            </div>

            <div>
              <img className="marquee-img15" loading="auto" style={newstyle} src={Bhuwansher} alt="img2" />
            </div>

            <div>
              <img className="marquee-img16" loading="auto" style={newstyle} src={Irctc} alt="img2" />
            </div>

            <div>
              <img className="marquee-img17"  loading="auto" style={newstyle} src={Ahmedabad} alt="img2" />
            </div>

            <div>
              <img className="marquee-img18" loading="auto" style={newstyle} src={Chennai} alt="img2" />
            </div>

            <div>
              <img className="marquee-img19" loading="auto" style={newstyle} src={Ihotel} alt="img2" />
            </div>

            <div>
              <img className="marquee-img20" loading="auto" style={newstyle} src={Bird} alt="img2" />
            </div>

            <div>
              <img className="marquee-img21" loading="auto" style={newstyle} src={BlueTribe} alt="img2" />
            </div>

            <div>
              <img className="marquee-img22" loading="auto" style={newstyle} src={Ekyam} alt="img2" />
            </div>

            <div>
              <img className="marquee-img23" loading="auto" style={newstyle} src={Svsu} alt="img2" />
            </div>

            <div>
              <img className="marquee-img24" loading="auto" style={newstyle} src={Varahi} alt="img2" />
            </div>

            <div>
              <img className="marquee-img25" loading="auto"  style={newstyle} src={Chukde} alt="img2" />
            </div>

            <div>
              <img className="marquee-img26" loading="auto" style={newstyle} src={nchmct} alt="img2" />
            </div>

            <div>
              <img className="marquee-img27" loading="auto" style={newstyle} src={Bengal} alt="img2" />
            </div>

            <div>
              <img className="marquee-img28" loading="auto" style={newstyle} src={Enark} alt="img2" />
            </div>


            <div>
              <img className="marquee-img29" loading="auto" style={newstyle} src={Farmley} alt="img2" />
            </div>


            <div>
              <img className="marquee-img30" loading="auto" style={newstyle} src={Gharkul} alt="img2" />
            </div>



            <div>
              <img className="marquee-img31" loading="auto" style={newstyle} src={Gooddot} alt="img2" />
            </div>


            <div>
              <img className="marquee-img32" loading="auto" style={newstyle} src={Hotel} alt="img2" />
            </div>



            <div>
              <img className="marquee-img33" loading="auto" style={newstyle} src={Ikea} alt="img2" />
            </div>



            <div>
              <img className="marquee-img34" loading="auto" style={newstyle} src={Isha} alt="img2" />
            </div>



            <div>
              <img className="marquee-img35" loading="auto" style={newstyle} src={Iskon} alt="img2" />
            </div>


            <div>
              <img className="marquee-img36" loading="auto" style={newstyle} src={lotus} alt="img2" />
            </div>



            <div>
              <img className="marquee-img37" loading="auto" style={newstyle} src={Manak} alt="img2" />
            </div>



            <div>
              <img className="marquee-img38" loading="auto" style={newstyle} src={Madhuram} alt="img2" />
            </div>


            <div>
              <img className="marquee-img39" loading="auto" style={newstyle} src={Mirtillo} alt="img2" />
            </div>



            <div>
              <img className="marquee-img40" loading="auto" style={newstyle} src={Panafrica} alt="img2" />
            </div>



            <div>
              <img className="marquee-img41" loading="auto"  style={newstyle} src={Pcti} alt="img2" />
            </div>



            <div>
              <img className="marquee-img42"  loading="auto" style={newstyle} src={Radisson} alt="img2" />
            </div>



            <div>
              <img className="marquee-img43" loading="auto" style={newstyle} src={roseat} alt="img2" />
            </div>



            <div>
              <img className="marquee-img44" loading="auto" style={newstyle} src={Sri} alt="img2" />
            </div>



            <div> 
              <img className="marquee-img45" loading="auto" style={newstyle} src={Trident} alt="img2" />
            </div>


            <div>
              <img className="marquee-img46" loading="auto" style={newstyle} src={Ulsolution} alt="img2" />
            </div>


            <div>
              <img className="marquee-img47" loading="auto" style={newstyle} src={Welex} alt="img2" />
            </div>


          </Marquee>
        </div>

        <div className='container-fluid d-flex justify-content-center my-3'>
          <NavLink to="/brands">
            <button className='btn btn load-more-button'>Load More..</button>
          </NavLink>
        </div>

        {/* /* marquee image end */}

      </section>
    </>
  )
}

export default Marqueecreate