import React from 'react'
import Sattviksinga from '../components/sattviksingapore/sattviksingapore'

const Sattviksingapore = () => {
  return (
    <>
       <Sattviksinga/> 
    </>
  )
}

export default Sattviksingapore