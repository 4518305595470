import React from 'react'
import "../assests/css/codex/codex.css"

const Daksha = () => {

  return (
  
    <>
    <div>
    <h1 className ='color-code'>Daksha :</h1>
    <p className ='color-code-new'>Daksha Kitchen are found in online food orders, pick-ups, drive-ins and app based products. It has an area of 150-300 Sq. ft., capacity of 250 above meals per day, working hours of 24*7 and less than 50 employees.</p>
    </div>
 </>
 )
}

export default Daksha