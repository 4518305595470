import React from 'react'
// import Visionmission from "../assests/images/asita/visionmission.png"
import "../assests/css/resemble/firstcomponent.css"
import "../assests/css/serviceLAP/serviceLap.css"
import { NavLink } from 'react-router-dom'

const Asitathird = () => {

    return (
        <>


            {/* <div className="col-sm-12 new-column-7">
                        <img src="https://visme.co/blog/wp-content/uploads/2020/02/header.gif" alt="error" className='we-are-compnent visionss' />
                    </div> */}

            <div className="container">
                <div className="row row-gap-india new-row-gap">
                <div className=" text-column-5 tomorrow">
                        <h1>Sattvik Consciousness Program</h1>
                        <p className='india-indo-css'>
                            The mind’s psychological qualities are highly unstable and can quickly fluctuate between
                            the different gunas. The predominant guna of the mind acts as a lens that affects our perceptions and perspective of the world around us. Thus, if the mind is in rajas it will experience world events as chaotic, confusing and demanding and it will then have a strong tendency to continue to react to events in a rajasic way. Therefore, for yogis to make progress along the path we must practise self observation and discernment to witness and not react to the activities of the gunas. We must also have the inner strength and willpower to consciously
                            shift our thoughts and actions away from tamas and rajas towards sattvic balance and purpose.

                        </p>

                        <p className='india-indo-css'>
                            "The types of food we eat have a direct effect on our mind and soul and choice of food reflects our personality".
                        </p>

                        <p className='india-indo-css'>
                            If you are interested to join our SATCON program, <NavLink to="/satcon">Click here.</NavLink>
                        </p>
                    </div>


                    <div className=" text-column-5 tomorrow">
                        <h1>Sattvik Lead Auditor Training</h1>
                        <p className='india-indo-css'>
                            The Sattvik Lead Auditor Program is a specialised training and certification program designed to equip participants with the knowledge and skills required to become lead auditors in the context of Sattvik Management Standards and HACCP (Hazard Analysis and Critical Control Points) principles.
                        </p>
                        <p className='india-indo-css'>
                            Here an overview of what this program aims to provide:

                        </p>
                        <p className='india-indo-css'>

                            1. Occupational Knowledge and Skills: The program aims to provide participants with the necessary knowledge and skills related to Sattvik Management Standards and HACCP principles. This knowledge is essential for developing, implementing, maintaining, and updating a HACCP-based Sattvik Management System.

                        </p>
                        <p className='india-indo-css'>
                            2. HACCP-Based Sattvik Management System (SMS): Participants will learn how to create and manage a Sattvik Management System that is based on HACCP principles. This system is likely designed to ensure the safety and quality of food products in accordance with Sattvik standards.
                        </p>
                        <p className='india-indo-css'>
                            3. Auditing Skills: The program focuses on developing auditing skills. Lead auditors are responsible for conducting audits to ensure that organisations adhere to specific standards and regulations. Participants will gain the knowledge and expertise needed to perform effective audits.

                        </p>

                        <p className='india-indo-css'>
                            4. Non-Compliance Management: Participants will also learn how to identify and address non-compliance issues. Managing non-compliance is a critical aspect of ensuring that organisations meet the required standards and make necessary improvements.
                        </p>

                        <p className='india-indo-css'>
                            5. Overall, the "Sattvik Lead Auditor Program" seems to be a valuable opportunity for individuals looking to enhance their career prospects in the food industry, particularly in the context of Sattvik standards and HACCP principles.
                        </p>
                        <p className='india-indo-css'>
                            If you are interested to join our LAP program,Please Fill the form <NavLink to="/satcon">Click here.</NavLink>
                        </p>
                    </div>

                    

                </div>
            </div>
        </>
    )
}

export default Asitathird