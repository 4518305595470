import React , {useEffect} from 'react'
import "../assests/css/country/country.css"
import India from "../assests/images/flag/Flag-India.jpg"
import SA from "../assests/images/flag/Flag_of_South_Africa.svg.png"
import INDO from "../assests/images/flag/Flag-Indonesia.jpg"
import CANADA from "../assests/images/flag/Flag-Canada.jpg"
import SINGA  from "../assests/images/flag/singa.jpg"
import AOS from "aos"
import 'aos/dist/aos.css'


const Country = () => {
 
    
        useEffect (()=>{
            window.scrollTo(0,0)
            AOS.init({duration:2000})
        },[])
    

    const flags = [{
        id: "1",
        flagcountry: India,
        heading: "SATTVIK CERTIFICATIONS OF INDIA",
        addone: "B 20 Third Floor Sector 63 Noida 201301",
        phone: "+91-11-40503001",
        email: 'info@sattvikcouncilofindia.org',
        maplocation: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3502.378514313514!2d77.37298808767922!3d28.61841562200794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM3JzA2LjMiTiA3N8KwMjInNDAuMyJF!5e0!3m2!1sen!2sin!4v1695029865624!5m2!1sen!2sin'
    },
    {
        id: "2",
        flagcountry: SA,
        heading: "SATTVIK CERTIFICATION SA (PTY)Itd",
        addone: "13 Keevan Garden 5 Scone Palace Atholl Heights, KwaZulu Natal 3629 ",
        phone: "+91-11-40503001, +91-9310730802 ",
        email: 'info@sattvik.org.za',
        maplocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13848.46074834045!2d30.904719721318333!3d-29.80321643102158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1ef701ea68e01017%3A0x3d03fe2f4b28d243!2sAtholl%20Heights%2C%20Westville%2C%203629%2C%20South%20Africa!5e0!3m2!1sen!2sin!4v1695101493277!5m2!1sen!2sin',

    },

    {
        id: "3",
        flagcountry: INDO,
        heading: "PT SATTVIK CERTIFICATIONS INDONESIA",
        addone: "Jalan Pangeran Antasari No. 45, Kebayorar Baru, RT.7/RW.7, Cipete Sel., Jakarta Selatan, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12150, Indonesia ",
        phone: "+91-11-40503001, +91-9310730802 ",
        email: 'info@sattvikcertifications.id',
        maplocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.970545542423!2d106.80376028522606!3d-6.267604145151886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f18574a1099b%3A0xc31ee0bf20650dc0!2sJl.%20Pangeran%20Antasari%20No.45%2C%20RT.7%2FRW.7%2C%20Cipete%20Sel.%2C%20Kec.%20Cilandak%2C%20Kota%20Jakarta%20Selatan%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2012410%2C%20Indonesia!5e0!3m2!1sen!2sin!4v1695101624296!5m2!1sen!2sin",

    },

    {
        id: "4",
        flagcountry: CANADA,
        heading: "SATTVIK CERTIFICATIONS CANADA ",
        addone: "1150 King St W, #334, Toronto, ON, M5H3T9, CA ",
        phone: "(021) 3160988 ",
        email: 'info@sattvikcertificationscanada.com',
        maplocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.413934288152!2d-79.430854408808!3d43.639555578812896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b35aa07434767%3A0x19530303c670103f!2s1150%20King%20St%20W%20%23334%2C%20Toronto%2C%20ON%20M6K%203C5%2C%20Canada!5e0!3m2!1sen!2sin!4v1695101716040!5m2!1sen!2sin",

    },

    
    {
        id: "5",
        flagcountry: SINGA,
        heading: "SATTVIK CERTIFICATIONS SINGAPORE PTE. LTD.",
        addone: "35 Selegie Road, # 09-02, Parklane Shopping Mall, Singapore 188 307 ",
        phone: "+65 93861039",
        email: 'info@scsg.sg',
        maplocation: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7903252919727!2d103.844465785032!3d1.3006663583631037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19bc8a338d27%3A0xc1bfd283b2585ce4!2s35%20Selegie%20Rd%2C%20%2309%2002%2C%20Singapore%20188307!5e0!3m2!1sen!2sin!4v1695101837169!5m2!1sen!2sin",

    }
    ]

    return (
        <>
            <div className="container-fluid   text-white text-center custom-country-container">
                <h1 className='new-country-heading-css' data-aos="fade-up" data-aos-anchor-placement="top-bottom">Operating Country</h1>
                {/* <p>Resize this responsive page to see the effect!</p>  */}
            </div>

            <div className="container new-country-padding-zero ">
                {flags.map((flag) => (
                    <div key={flag.id} className="row country-custom-css custom-flag-container">
                        <div className="col-sm-4 custom-css-flag">
                            <div className="container">
                                <img src={flag.flagcountry} loading="auto" alt="error" className='country-flag' />
                            </div>
                        </div>
                        <div className="col-sm-4 custom-address-country">
                            <h3 className='flag-heading'>{flag.heading}</h3>
                            <p className='content-flag'><span>Address:</span> {flag.addone}</p>
                            <p className='content-flag'><span>Phone:</span>{flag.phone}</p>
                            <p className='content-flag'><span>Email:</span> {flag.email}</p>
                        </div>
                        <div className="col-sm-4 new-padding-map">
                            <iframe
                                src={flag.maplocation}
                                width="400"
                                height="300"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Google Maps Embed"
                            ></iframe>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Country