import React from 'react'


const Quality = () => {

  return (
    <>
            <h2 className="code-heading">Quality Management</h2>
            <p className='code-content'>The quality management training program helps to gain the knowledge about the concept of “Sattvik”. This training program primarily focus on education the individual about the Sattvik Environment and the principals of Sattvik Certification During the program an individual will learn the right combination of food what are the principals of Sattvik certification the decoding of Charka Sahita the importance of Ayurveda in our daily life the requirements of sapta dhatus importance of sanitation and hygiene during the preparation of food the importance of Vegetarian certification post covid etc. after completion of this program an individual will able to understand the principles of the Sattvik certification .</p>
            <p className='quality-code '>Fee : Rs. 9999/-</p>
            <p className='code-quality'>Inclusive of 18%GST</p>
            <p className='code-footer-one'>This program is available with Art of Living by paying Rs 1000/- extra.</p>
        </>
  )
}

export default Quality