import React, { useEffect } from 'react'
import Cookies from "../components/privacy/cookies"
import Hammer from "../components/assests/images/privacy/hammer.jpg"
import Compsubheader from "../components/training/trainingsubheader";

const Cookiespage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const Props = {
    titleone: " ",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Hammer,
  };
  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <Cookies />
      </section>
    </>
  )
}

export default Cookiespage