import React from 'react'
import "../assests/css/subheadertwo/subheadertwo.css"
import { NavLink } from 'react-router-dom';

const Subheadertwo = () => {

  return (
    <>
      <div className="container-fluid p-5 my-5  text-white text-center backgroundimage image-float">
        <div className="content-detail">
          <h1>Introduction to Sattvik Certifications Singapore</h1>
          <p className='subheader-content-word'>WORLD’S FIRST VEGETARIAN / VEGAN FOOD SAFETY REGULATORY COMPLIANCE FOR VEGETARIAN AND ALLIED ADHERENTS</p>
          <NavLink to="/ourvision">
            <button type="button" className="btn btn hovercolor">DISCOVER MORE</button>
          </NavLink>
        </div>
      </div>

    </>
  )
}

export default Subheadertwo