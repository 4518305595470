import React, { useState, useEffect } from 'react';
import '../assests/css/textslider/slider.css';
import Image1 from '../assests/images/founder/volunteer.jpg'
import Image2 from "../assests/images/founder/venkata.jpg"
// import Image3 from  '../assests/images/founder/founder.png'
const quotes = [
    {
        img:  Image1 ,
        text: "A vision, an eye opener to the way of life which is going to enable our culture to reach to our life and give direction to us. The goal of Sattvik Council is to create worlds best purity standards that help all the vegetarian community worldwide to live, eat and use products with pride of being a pure vegetarian.",
        author: "Abhishek Biswas",
        position:"Secretary General Sattvik Council of India & Founder Sattvik Certifications",
        
    },
    {
        img:  Image2 ,
        text: "Developing, expanding and optimizing existing Food & Lifestyle industry services and 100% Quality Assured product offerings for Vegetarian, Vegan, Jain & Buddhist consumers. Adopting industry-leading best practices and implementing Safe Food Safety Regulation and Quality Management System models",
        author: "Mr. Venkataraman Kumar",
        position:"Director - “Sattvik Certifications Singapore Pte Ltd.",
       
    },

    // {
    //     img:  Image3 ,
    //     text: "text3",
    //     author: "Huizhong Yang",
    //     position:"position3",
       
    // },
];

const Slider = () => {
    
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        }, 5000); // Change slide every 8 seconds
        return () => clearInterval(interval);
    }, []);

    
    return (
        <section>
        <div className="quote-slider">
            {quotes.map((quote, idx) => (
                <div
                    key={idx}
                    className={`quote-slide ${index === idx ? 'active' : ''}`}>
                    <div className="quote-content">
                    <div className='div1'>
                        <img className="image-style text-center" loading="auto" src={quote.img} alt="img1" />
                        
                      </div>

                      <div className='div2'>  
                        <p className="quote-author my-2">{quote.author}</p>
                    </div>

                    <div className='div3'>  
                        <p className="quote-author my-2">{quote.position}</p>
                    </div>

                    <div className='div4'>  
                        <p className="quote-text"><q className='quotation'>{quote.text}</q></p>
                    </div>  
                    </div>
                </div>
            ))}
        </div>
        </section>
    );
};

export default Slider;
