import React from 'react';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {

    if (message.includes('hello') || message.includes('Hello') || message.includes('HELLO')) {
      actions.Name();
    } else if (message.includes('Hi') || message.includes('HI') || message.includes('hi')) {
      actions.Name();
    } else if (message.includes('@') && (typeof message === "number" || typeof message === "string")) {
      actions.handleHello();
    } else if (message.includes('Sattvik') || message.includes('SATTVIK') || message.includes('sattvik')) {
      actions.handleDog();
      actions.handleAreYou();
    }
    else {
      actions.ThankuforyourResponseNo()
      // actions.handleDog();
      // actions.handleAreYou();
      // actions.handleYesNo();
    }
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
