import React from 'react'
import "../assests/css/bot/bot.css"
const Sendmail = ({ actions }) => {

    const allResponseData = actions.children.props.children.props.state

    const buttonStyle = {
        display: 'block',
    };

    const handleSendEmail = async () => {
        try {
            // e.preventDefault()
            const response = await fetch('https://api.scsg.sg/chatbot/v1', {
                method: "POST",
                body: JSON.stringify(allResponseData),
                headers: {
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                console.log("success");
              
            } else {
                console.log("Error while sending response");
            }

            // console.log(response)
            actions.ThankuforyourResponse()
        }
        catch (error) {
        }
    }
    const handleSendEmailno = () => {
        actions.ThankuforyourResponseNo()
    }



    return (
        <div className="Yes-no">
            <button className='buttonemail' value="no" style={buttonStyle} onClick={handleSendEmail} >
                Send Your Response
            </button>
            <button className='buttonemail' value="no" style={buttonStyle} onClick={handleSendEmailno} >
                No
            </button>
        </div>
    )
}

export default Sendmail