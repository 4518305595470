import React from 'react'
// import BuddhismImg from "../assests/images/indo/gautam.jpeg"
import "../assests/css/tourism/lastsatvikbuddha.css"


const LastBuddhism = ({secondparadata}) => {
   
    return (
<>
        <div className="container-fluid  text-dark  text-center our-future-container">
        <h1 className='headingfuture-content-one-heading-dynamic'>{secondparadata.headingfuturetitle}</h1>
    </div>

        <div className="container-fluid mt-5 backgroung-buddha-image">
        <div className="covver-image"></div>
        
            <div className="row custom-row">
             
                <div className="col-sm-4 new-custom-col-data">
                    <div className="container newone-image-css-img">
                        <img src={secondparadata.ImageOne} loading="auto" alt="error" className='new-image-one-daata'/>
                    </div>
                </div>



                <div className="col-sm-7 content-width-data">
                    <h3 className='heading-buddhism'>{secondparadata.headingone}</h3>
                    <p className='heading-buddhism-content-data'>{secondparadata.contentsecondpara}</p>
                    <h3 className='heading-buddhism'>{secondparadata.headingtwo}</h3>
                    <p className='heading-buddhism-content-data'>{secondparadata.contentnewpara}</p>
                </div>

            </div>
        </div>
        </>
    )
}

export default LastBuddhism