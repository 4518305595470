import React , {useEffect} from 'react'
import Buddhaimpact from '../components/tourism/buddhaimpact'
import Visionmission from "../components/assests/images/indo/one.gif"
import "../components/assests/css/sattvikscheme/sattvikscheme.css"
import Compsubheader from "../components/training/trainingsubheader"
const Sattvikscheme = () => {
 useEffect(()=>{
    window.scrollTo(0,0)
 },[])

    const Props = {
        titleone: "Sattvik Scheme",
        titlesecond: "",
        titlethird: " ",
        Imgurl: Visionmission,
      };

    const Buddha = [

        {
            title: "Sattvik - Vegan:",
            content: "The Vegan Food category is a significant component to establish the highest Sattvik standards for compassionate, plant-based, and sustainable cuisine. Our Codex Standard is dedicated to setting rigorous guidelines for vegan food products, emphasizing not only the absence of animal-derived ingredients but also the principles of purity, ethics, and environmental responsibility. We believe that vegan food should not only be delicious and nutritious but also align with Sattvik principles, promoting compassion, sustainability, and responsible sourcing. "
        },

        {
            title: "Sattvik - Vegetarian:",
            content: "The Vegetarian Food category is a foundational element to establish the highest Sattvik standards for wholesome, plant-based, and ethical cuisine. Our Codex Standard is dedicated to setting rigorous guidelines for vegetarian food products, emphasizing the principles of purity, healthfulness, and ethical responsibility. We believe that vegetarian food should not only be nutritious and delicious but also align with Sattvik principles, promoting well-being, ethical sourcing, and respect for the environment. "
        },

        {
            title: "Sattvik - Jain: ",
            content: "The Jain Food category is an integral facet to establish the highest Sattvik standards for pure, compassionate, and spiritually mindful cuisine. Our Codex Standard is dedicated to setting rigorous guidelines for Jain food products, emphasizing not only the purity of ingredients but also the principles of non-violence, balance, and ethical responsibility. We believe that Jain food should not only nourish the body but also align with Sattvik principles including ensuring non-use of all forbidden ingredients as per Jain conduct and rules, promoting harmony, spiritual well-being, and respect for all living beings."
        },

        {
            title: "Sattvik - Sattvam:",
            content: "The Sattvam Food category aims to establish the highest Sattvik standards for pure, holistic, and mindful cuisine. Sattvam food complies with use of all the permitted ingredients and prepared in a kitchen or a processing facility with prescribed Vastu layout i.e. food prepared following all the principles of Sattvik philosophy. Our Codex Standard is dedicated to setting rigorous guidelines for Sattvam food products, emphasizing not only the purity and quality of ingredients but also the principles of balance, well-being, and ethical responsibility. We believe that Sattvam food should not only nourish the body but also align with Sattvik principles, promoting harmony, spiritual well-being, and respect for the environment. This helps in creating a world where Sattvam food embodies the essence of Sattvik values, offering consumers a truly pure and balanced choice that respects their health, the planet, and ethical standards."
        },

        {
            title: "Sattvik Buddhist:",
            content: "The Buddhist Food category aims to establish the highest Sattvik standards for ethical, sustainable, and spiritually inspired food products. Our Codex Standard is dedicated to setting rigorous guidelines for Buddhist food, emphasising not only the quality and purity of ingredients but also the principles of sustainability, well-being, and ethical responsibility. We believe that Buddhist food should not only nourish the body but also align with Sattvik principles, promoting ethical sourcing, eco-conscious practices, and respect for spiritual and cultural values. "
        },



    ]
    return (

        <>
              <section>
    <Compsubheader Props={Props} />
  </section>
            <section>
                <Buddhaimpact Buddha={Buddha} />
            </section>
        </>
    )
}

export default Sattvikscheme