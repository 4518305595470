import React from 'react'
import "../assests/css/codex/codex.css"

const Brahma = () => {

  return (
    <>
    <div>
    <h1 className = "color-code">Brahma :</h1>
    <p className = "color-code-new">Brahma kitchens are found in cafeterias, hotels, hospitals, educational and workplace facilities, army, barracks, holy places and similar establishments. It has an area of above 5000 sq. ft., capacity of over 5000 meals per day,working hours of 24*7 and number of employees above 900.</p>
    </div>
 </>
 )
}

export default Brahma