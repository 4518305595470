import { createChatBotMessage } from 'react-chatbot-kit';
import Button from './yesno';
import NewButton from "./newyesno"
import Avatar from './avatar';
import SendEmailButton from "./sendmail"
const botName = 'Sattvik';

const config = {
  initialMessages: [createChatBotMessage(`Hello! Welcome to Sattvik Certifications Singapore ! How can we assist you today?`, {

  })],

  customComponents: {
    botAvatar: (props) => <Avatar {...props} />
  },
  widgets: [
    {
      widgetName: 'Button',
      widgetFunc: (props) => <Button {...props} />,
    },
    {
      widgetName: 'ButtonNew',
      widgetFunc: (props) => <NewButton {...props} />,
    },
    {
      widgetName: 'ButtonSendEmail',
      widgetFunc: (props) => <SendEmailButton {...props} />,
    },
  ],

  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor: '#376B7E',
    },
    chatButton: {
      backgroundColor: '#5ccc9d',
    },
  },
};

export default config;