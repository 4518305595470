import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import { fa} from '@fortawesome/free-brands-svg-icons';
import { NavLink } from 'react-router-dom';
import "../assests/css/sharebox/sharebox.css"


const Sharebox = ({ handleshareone }) => {

    const [closeshare, setcloseshare] = useState(true)

    const handleclose = (() => {
        setcloseshare(false)
        handleshareone(true)
    })

    const openWindow = (url) => {
        window.open(url, '_blank');
    };

    const Url = 'https://scsg.sg/'
    const Heading = 'Sattvik Certification Singapore Website'

    const handlefacebookShare = (() => {
        openWindow(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(Url)}`, '_blank');
    })

    const handleTwitterShare = () => {
        openWindow(`https://twitter.com/intent/tweet?url=${encodeURIComponent(Url)}&text=${encodeURIComponent(Heading)}`, '_blank');
    };

    const handleInstagramShare = () => {
        openWindow(`https://www.instagram.com/create/story`);

    };

    const handleLinkedInShare = () => {
        openWindow(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(Url)}&title=${encodeURIComponent(Heading)}&summary=${encodeURIComponent(Heading)}`);

    };

    const handleSMSSharebutton = () => {
        openWindow(`sms:?body=${encodeURIComponent(Url)}`);

    };

    const handleWhatsAppSharebutton = () => {
        const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(`${Heading} ${Url}`)}`;
        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.href = whatsappUrl;
        } else if (navigator.userAgent.match(/Android/i)) {
            window.location.href = whatsappUrl;
        } else {
            window.open('https://web.whatsapp.com/');
        }
    };
    return (
        <>
            {closeshare && <div className="container-fluid text-white text-center main-div-container">
                <div className="container  bg-white text-white text-center second-main-div-container">

                    {/* <div className="container-fluid p-5 bg-white text-dark text-center second-main-div-container-second">
                        <div className="row new-landing-row">
                            <div className="col-5 logo-first-landing">
                                <img src={SattvikLogo} alt="error" className='logo-first-landing' />
                            </div>
                            <div className="col-5 new-sattvik-dat-sattvik">
                                <h3 className='main-sattvik-heading'>Sattvik Certifications</h3>
                                <p className='main-sattvik-country'>INDONESIA</p>

                            </div>

                        </div>
                    </div> */}

                    <div className="container-fluid p-5 bg-white  text-white text-center second-main-div-container-connect">
                        <div className='icon-close-share'>
                            <i className="ri-close-fill new-fill-icon" onClick={handleclose} ></i>
                        </div>
                        <p className='para-landing text-center new-share-page'> Now Share On Social Media</p>
                        <p className='para-landing-one'></p>

                    </div>

                    <div className="container-fluid   bg-white text-white text-center channel-container-link-one ">



                        <div className="row dummy-row " onClick={handlefacebookShare}>
                            <div className='container-fluid row-icon-container '>
                                <div className="col-sm-2">
                                    <span className='chrome-icon-facebook' ><FontAwesomeIcon icon={faFacebook} /></span>
                                </div>
                                <div className="col-sm-4 col-sm-4-custom">
                                    <p className='para-landing-visit'>Share On Facebook</p>
                                    <NavLink to="/home">
                                        <p className="landing-page-icon"></p>
                                    </NavLink>
                                </div>
                            </div>
                        </div>


                        <div className="row  dummy-row" onClick={handleInstagramShare}>
                            <div className='container-fluid row-icon-container '>
                                <div className="col-sm-2">
                                    <span className='chrome-icon-insta'><FontAwesomeIcon icon={faInstagram} /></span>
                                </div>
                                <div className="col-sm-4 col-sm-4-custom">
                                    <p className='para-landing-visit'>Share On Instagram</p>
                                    <p className="landing-page-icon insta"></p>
                                </div>
                            </div>
                        </div>


                        <div className="row  dummy-row " onClick={handleTwitterShare}>
                            <div className='container-fluid row-icon-container '>
                                <div className="col-sm-2">
                                    <span className='X-icon'><FontAwesomeIcon icon={faXTwitter} /></span>
                                </div>
                                <div className="col-sm-4 col-sm-4-custom">
                                    <p className='para-landing-visit'>Share On X</p>
                                    <p className="landing-page-icon insta"></p>
                                </div>
                            </div>
                        </div>


                        <div className="row  dummy-row" onClick={handleLinkedInShare}>
                            <div className='container-fluid row-icon-container '>
                                <div className="col-sm-2">
                                    <span className='chrome-icon'><FontAwesomeIcon icon={faLinkedin} style={{ color: "#105ada", }} /></span>
                                </div>
                                <div className="col-sm-4 col-sm-4-custom">
                                    <p className='para-landing-visit'>Share On LinkedIn</p>
                                    <p className="landing-page-icon insta"></p>
                                </div>
                            </div>
                        </div>


                        <div className="row  dummy-row" onClick={handleWhatsAppSharebutton}>
                            <div className='container-fluid row-icon-container '>
                                <div className="col-sm-2 " style={{ marginTop: "-9px" }}>
                                    <span className='chrome-icon'><FontAwesomeIcon icon={faWhatsapp} style={{ color: "rgb(122, 176, 48)", paddingTop: "1px !important" }} /></span>
                                </div>
                                <div className="col-sm-4 col-sm-4-custom">
                                    <p className='para-landing-visit'>Share On Whatsaap</p>
                                    <p className="landing-page-icon insta"></p>
                                </div>
                            </div>
                        </div>

                        <div className="row  dummy-row" onClick={handleSMSSharebutton}>
                            <div className='container-fluid row-icon-container '>
                                <div className="col-sm-2 " style={{ marginTop: "-9px" }}>
                                    <span className='chrome-icon'><i class="ri-smartphone-fill"></i></span>
                                </div>
                                <div className="col-sm-4 col-sm-4-custom">
                                    <p className='para-landing-visit'>Share On Mobile</p>
                                    <p className="landing-page-icon insta"></p>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </div>

            }
        </>

    )

}



export default Sharebox