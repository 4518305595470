import React , {useEffect ,Suspense} from 'react'
import "../components/assests/css/vision/vision.css"
import Visionpdf from "../components/assests/images/indo/vision.jpg"
import Compsubheader from '../components/training/trainingsubheader'


 const Visioncontent = React.lazy(()=> import("../components/vision/vision.js"))

const Vision = () => {
    useEffect (()=>{
        window.scrollTo(0,0)
    },[])

    const Props = {
      titleone: "Our Vision",
      titlesecond: "",
      titlethird: "",
      Imgurl: Visionpdf
  }
    
  return (
   <>

    <section>
      <Compsubheader Props={Props} />
   </section>


    <section className='suspension-down'>
    <Suspense fallback={<p>Its Loading...................</p>}>
      <Visioncontent/>
      </Suspense>
    </section>
    </>
  )
}

export default Vision