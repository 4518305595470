import React , {useEffect} from 'react'
import "../assests/css/bussiness/bussiness.css"


const Bussiness = () => {
    useEffect (()=>{
        window.scrollTo(0,0)
    },[])

    return (
        <>
            <div className="container-fluid text-white text-center bussiness-container">
                <h1 className='business-color-heading-one'>Business Principle</h1>
            </div>

            <div className="container-fluid  bussiness-container-one ">
                <div className="row bussiness-row">
                    <div className="col-sm-12 bussiness-css">
                        <div className='content-bussiness-together'>
                            <h3 className='text-center business-color-heading-two '>Commitment to Holistic Food Safety and Quality</h3>
                            <p className="business-color-content-one">BussinessB5</p>
                            <p className="business-color-content">BussinessB6</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Bussiness