import React from 'react'
import "../assests/css/codex/codex.css"

const Vegan = () => {

  return (
    <>
    <h1  className='color-code-code'>Vegan Processed Food</h1>
   <p className="color-code-new">A food which is processed with no use of any animal origin products, milk and its derivatives such as cheese, butter, ghee etc.</p>
   </>
  )
}

export default Vegan