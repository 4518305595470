import React from 'react'
import "../assests/css/training/lapcomponent.css"


const Lapcomponent = () => {
  
    return (

        <>
            <h2 className='code-heading'>Lead Auditor Program (LAP)</h2>
            <p className='code-content'>Sattvik Lead Auditor Program is comprehensive training program for the students and professionals who are looking to create a career in the booming industry for the vegetarian certification. This program will cover the process approach to the Sattvik management systems and auditing the leading industries under the Sattvik umbrella. This program is intended to acquire the competence to the audit and enable a person to develop necessary expertise to perform the Sattvik management systems by applying widely principles and procedure and techniques during the training program the student will acquire the knowledge and skills to plan and carry out the internal and external audits with the Sattvik management systems in different industries.</p>
            <p className="quality-code">The four components of LAP (SCTN2079001) are-</p>
            <p className='code-quality '>1. Quality Management – SCTN2079011</p>
            <p className='code-quality '>2. Kitchen Management – SCTN2079021</p>
            <p className='code-quality '>3. Hospitality Management – SCTN2079031</p>
            <p className='code-quality '>4. Vegetarian Friendly Railway Services – SCTN2079041</p>
            <p className ="code-pointone">Fee : Rs. 25000/-</p>
            <p className='css-pre'>Inclusive of 18%GST</p>
            <p className='code-heading-one'>This program is available with Art of Living by paying Rs 2500/- extra.</p>
        </>

    )
}



export default Lapcomponent