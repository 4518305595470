// OptionButton.js
import React from 'react';

const OptionButton = ({ actions }) => {
  // const [isActive, setIsActive] = useState(false);

  const buttonStyle = {
    display: 'block', // You can change 'block' to 'none' to hide the button initially
  };

  const handleclickYes = ((e) => {
    actions.handleYesNoServices(e.target.value)
    actions.handleValue()
    actions.handleValuelast()

  })

  const handleclickNo = ((e) => {
    actions.handleYesNoServices(e.target.value)
    actions.handleValue()
    actions.handleValuelast()
  })

  return (
    <>
      <div className="Yes-no">
        <button className='yes-button' value="yes" style={buttonStyle} onClick={handleclickYes}>
          Yes
        </button>
        <button className='No-button' value="no" style={buttonStyle} onClick={handleclickNo}>
          No
        </button>
      </div>
    </>
  );
};

export default OptionButton;