import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "../assests/css/news/news.css";
import "../assests/css/gallery/gallery.css"
import News from "./newscontent"
import AOS from "aos"
import 'aos/dist/aos.css'

const Newsdata = () => {



  useEffect(() => {
    AOS.init({ duration: 2000 })

  }, [])


  const newdate = [
    {
      year: "2021"
    },

    {
      year: "2022"
    },

    {
      year: "2023"
    },
    {
      year: "2024"
    },

  ]

  const Countrydate = [

    {
      id: "1",
      countryName: "India"
    },

    {
      id: "2",
      countryName: "Singapore"
    },

  ]

  const [newselectedyear, setnewselectedyear] = useState("")
  const [newselectedcountry, setnewselectedcountry] = useState("")

  const handleCategoryyear = ((e) => {
    const YearValue = e.target.value
    setnewselectedyear(YearValue)
  })

  const handleCategoryCountry = ((e) => {
    const CountryYear = e.target.value
    setnewselectedcountry(CountryYear)

  })


  const filternews = News.filter((newsarticle) => {
    const NewsYear = newsarticle.date.split(" ")
    const ExactYear = NewsYear[2]

    const SelectedYear = newselectedyear === "" || newselectedyear === ExactYear;
    const SelectedCountry = newselectedcountry === "" || newselectedcountry === newsarticle.country
    return SelectedYear && SelectedCountry
  })




  // const [newdatanews , setnewdatanews] = useState(news)
  // const handleCategoryChangeyear = (e) =>{
  //  const year = e.target.value
  //  if(year === ""){
  //   setnewdatanews(news)
  //  }else{
  //   const filter = news.filter((curr)=>{
  //     const dateParts = curr.date.split(' ');
  //     const yearPart = dateParts[dateParts.length - 1];
  //    return (
  //     yearPart === year
  //    ) 
  //    })
  //    setnewdatanews(filter)
  //   }
  //  }







  return (
    <>
      <div className="container-fluid  text-dark text-center  new-data">
        <h1 className="whoweare-css-heading-news" data-aos="fade-up" data-aos-anchor-placement="top-bottom">News and Resources</h1>
      </div>

      <div className="container-fluid p-5 text-white text-end">
        <div className="container mt-3 gallery-custom-css-gallery">
          <select
            className="ui dropdown custom-dropdown"
            onChange={handleCategoryyear}>
            <option value="">---Select Year---</option>
            {newdate.map((curr) => (
              <option key={curr.year} value={curr.year}>{curr.year}</option>
            ))}
          </select>
        </div>

        <div className="container mt-3 gallery-custom-css-gallery">
          <select
            className="ui dropdown custom-dropdown"
            onChange={handleCategoryCountry}>
            <option value="">---Select Country---</option>
            {Countrydate.map((curr) => (
              <option key={curr.id} value={curr.countryName}>{curr.countryName}</option>

            ))}
          </select>
        </div>
      </div>



      <div className='container'>
        <div className='row gap-between'>
          {filternews.length > 0 ? (filternews.map((article) => (
            <div className="card new-news-card" key={article.id}>
              <img src={article.Image} loading="auto" className="card-img-top image-desgin" alt="error" />
              <div className="card-body">
                <p className='paragrap-content'>{article.date}</p>
                <p className='paragrap-content'>{article.country}</p>
                <h5 className="card-title new-margin-heading">{article.title}</h5>
                <p className="card-text new-margin">{article.content}</p>
                <NavLink to={article.link}>
                  <button className="btn btn-warning new-button">More Details</button>
                </NavLink>
              </div>
            </div>
          ))) :
            <div className="no-images-found">No News found.</div>
          }
        </div>
      </div>
    </>
  );
}

export default Newsdata;
