import React, { useState ,useEffect } from 'react'
import "../assests/css/landing/landing.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import SattvikLogo from "../assests/images/icons/scsg.png"
import { NavLink } from 'react-router-dom';
import Sharebutton from "../sharebox/sharebox"
import AOS from "aos"
import 'aos/dist/aos.css'

// import { faChrome } from '@fortawesome/free-brands-svg-icons'; // Use the specific icon you want

const Landing = () => {
    const [share, setshare] = useState(true)
    const [sharenew, setsharenew] = useState(false)


    useEffect(()=>{
        AOS.init({duration:2000})
      },[])


    const handleshare = (() => {
        setshare(false)
        setsharenew(true)

    })

    const newhandleshare = ((data) => {
        setshare(data)
        setsharenew(false)
    })


    return (

        <>
            <div className="container-fluid text-white text-center main-div-container">

                {sharenew &&
                    <Sharebutton handleshareone={newhandleshare} />
                }

                {share && <div className="container  bg-white text-white text-center second-main-div-container"  data-aos="zoom-in">


                    <div className="container-fluid p-5 bg-white text-dark text-center second-main-div-container-second">
                        <div className="row new-landing-row">
                            <div className="col-5 logo-first-landing">
                                <img src={SattvikLogo} loading="auto" alt="error" className='logo-first-landing' />
                            </div>
                            <div className="col-5 new-sattvik-dat-sattvik">
                                <h3 className='main-sattvik-heading'>Sattvik Certifications</h3>
                                <p className='main-sattvik-country'>Singapore</p>

                            </div>

                        </div>
                    </div>

                    <div className="container-fluid p-5 bg-white  text-white text-center second-main-div-container-connect">
                        <p className='para-landing'>Connect With Us on Social Media</p>
                        <p className='para-landing-one'>Follow us and get updates delivered to your favorite social media channel.</p>

                    </div>

                    <div className="container-fluid   bg-white text-white text-center channel-container-link ">


                        <NavLink to="/home">
                            <div className="row dummy-row">
                                <div className='container-fluid row-icon-container '>
                                    <div className="col-sm-2">
                                        <span className='chrome-icon'><FontAwesomeIcon icon={faChrome} /></span>
                                    </div>
                                    <div className="col-sm-4 col-sm-4-custom">
                                        <p className='para-landing-visit'>Visit us online</p>

                                        <p className="landing-page-icon">https://www.scsg.sg/</p>

                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink to="https://www.instagram.com/sattvikcertificationssg/?igsh=YzVkODRmOTdmMw%3D%3D">
                            <div className="row  dummy-row">
                                <div className='container-fluid row-icon-container '>
                                    <div className="col-sm-2">
                                        <span className='chrome-icon-insta'><FontAwesomeIcon icon={faInstagram} /></span>
                                    </div>
                                    <div className="col-sm-4 col-sm-4-custom">
                                        <p className='para-landing-visit'>Instagram</p>
                                        <p className="landing-page-icon insta">sattvikcertificationssg</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink to="https://x.com/Sattvikid?t=mDeN0UCH_yX-dsZwYmCtpg&s=09">
                            <div className="row  dummy-row">
                                <div className='container-fluid row-icon-container '>
                                    <div className="col-sm-2">
                                        <span className='chrome-icon'><FontAwesomeIcon icon={faXTwitter} /></span>
                                    </div>
                                    <div className="col-sm-4 col-sm-4-custom">
                                        <p className='para-landing-visit'>X</p>
                                        <p className="landing-page-icon insta">sattvikcertificationsID</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <NavLink to="https://www.linkedin.com/company/scsg-sg/">
                            <div className="row  dummy-row">
                                <div className='container-fluid row-icon-container '>
                                    <div className="col-sm-2">
                                        <span className='chrome-icon'><FontAwesomeIcon icon={faLinkedin} style={{ color: "#105ada", }} /></span>
                                    </div>
                                    <div className="col-sm-4 col-sm-4-custom">
                                        <p className='para-landing-visit'>LinkedIn</p>
                                        <p className="landing-page-icon insta">Sattvik Certifications Singapore</p>
                                    </div>
                                </div>
                            </div>
                        </NavLink>

                        <div className='new-share' onClick={handleshare}>
                            <div className='container  mt-5 number-share-one'>

                                <span className='fashop'><i className="ri-share-fill new-fill-share"></i></span>
                                <p className='number-share'>SHARE THIS PAGE</p>
                            </div>
                        </div>
                    </div>

                </div>

                }


            </div>



        </>


    )
}

export default Landing