import React , {useEffect} from 'react'
import Visionmission from "../assests/images/indo/sattviksinga.png"
import "../assests/css/sattviksingapore/sattviksingapore.css"
import Compsubheader from '../training/trainingsubheader'
import Weare from "../assests/images/indo/landscape-singapore-min.jpg"
import AOS from "aos"
import 'aos/dist/aos.css'

const Sattviksinga = () => {
   
    useEffect (() => {
        window.scrollTo(0,0)
        AOS.init({duration : 2000})
        },[])
    
        const Props = {
            titleone: "Resources",
            titlesecond: "",
            titlethird: " ",
            Imgurl: Weare,
          };
    return (
        <>
           <section>
    <Compsubheader Props={Props} />
  </section>
            <div className="container text-center">
                <h1 className='new-graph-text new-text' data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    Sattvik in Singapore

                </h1>
            </div>

            <div className="container">
                <div className="row row-gap-india">
                    <div className="col-sm-5 text-column-5">
                        <h1>In Singapore</h1>
                        <p className='india-indo-css'>
                        Catering to Diverse Dietary Preferences: Singapore is a popular tourist destination with visitors from around the world, many of whom have diverse dietary preferences, including vegetarian, vegan, Jain, Sattvam, and Buddhist diets. Sattvik Certifications ensure that tourists with these dietary requirements have access to safe and high-quality food options, enhancing their overall experience and encouraging more visitors to choose Singapore as their travel destination.

                        </p>
                        <p className='india-indo-css'>
                        Enhancing Culinary Tourism: Singapore is renowned for its diverse and vibrant food scene. By certifying vegetarian and vegan establishments and dishes, Sattvik Certifications contribute to the growth of culinary tourism. Travelers interested in exploring the local food culture can do so with confidence, knowing they can find authentic and high-quality plant-based options.

                        </p>
                        <p className='india-indo-css'>
                        Food Safety and Quality: Sattvik Certifications emphasize food safety and quality. Certified restaurants and eateries are recognized for their commitment to meeting rigorous standards, giving tourists peace of mind regarding the safety and hygiene of the food they consume during their visit.

                        </p>
                        <p className='india-indo-css'>
                        Cultural and Ethical Experiences: Many travelers seek to experience the culture and ethics of the places they visit. Sattvik Certifications can help promote cultural and ethical aspects of vegetarian and vegan cuisines, offering tourists an opportunity to engage with local traditions and values through their culinary experiences.
                        </p>

                        <p className='india-indo-css'>
                        Supporting Sustainable Tourism: As the world increasingly focuses on sustainable tourism, Sattvik Certifications can align with this trend by promoting plant-based diets, which have a lower environmental impact. This can attract eco-conscious travellers and contribute to the image of Singapore as a sustainable and responsible tourist destination.
                        </p>

                        
                        <p className='india-indo-css'>
                        By supporting diverse dietary preferences and promoting food safety and quality, Sattvik Certifications enhances the overall tourism experience in Singapore, making it a more attractive and welcoming destination for travellers from various cultural backgrounds and dietary choices.

                        </p>


                    </div>
                    <div className="col-sm-7 new-column-7">
                        <img src={Visionmission} loading="auto" alt="error" className='we-are-compnent' />
                    </div>

                </div>
            </div>
        </>
    )
}

export default Sattviksinga