import React from 'react'
import "../assests/css/training/preaudit.css"


const Preaudit = () => {


  return (
    <>
      <h2  className = "code-heading">Pre Audit Program</h2>
      <p className = "code-content">Pre Audit Training Program is designed keeping in focus the current requirement for the trained personnel to implement the Sattvik Standard in one’s industry. Pre audit training program helps the organization to train its management for the implementation for the Sattvik standards and identify for any nonconformities. It helps to contribute the optimal preparation for the certification audit for an organization. This help to enrich management proficiency and expertise to conduct Sattvik Management Systems audit for an organization adhering to the Sattvik Standards this training program support the trainee acquire requisite skills to plan conduct and report an audit to ensure improvement in organizations performance as the Sattvik Management Standards.</p>
      <p className ="code-pointone">Fee : Rs. 4999/- for 4 person include additional @ 2000/- per person.</p>
      <p className = "css-pre">Inclusive of 18%GST</p>
      <p className='code-heading-one'>This program is available with Art of Living by paying Rs 1000/- extra.</p>
    </>
  )
}

export default Preaudit