import React , {useEffect} from 'react';
import "../assests/css/representaive/representative.css";
import Singapur from "../assests/images/founder/venkata.jpg"
// import indobaugus from "../assests/images/founder/babus (4).png"
// import Kaminimam from "../assests/images/founder/kaminimam (2).png"
// import Indoone from "../assests/images/founder/indo-onecopy.png"
// import Indotwo from "../assests/images/founder/foundercopy.png"
import Founder from "../assests/images/founder/abhishek-biswas.jpg"
import AOS from "aos"
import 'aos/dist/aos.css'


const representatives = [
  {
    id: "1",
    Name: "Mr. Abhishek Biswas",
    Location: "",
    Image: Founder,
    Content: " Sattvik certifications in Singapore embody a commitment to purity and wholesome living, ensuring products meet the highest standards of quality and authenticity. In a world seeking balance, these certifications resonate, offering consumers a trusted path to embrace a holistic and Sattvik lifestyle. Elevate your well-being with Sattvik-certified choices in the vibrant heart of Singapore.",
    director: "Founder Sattvik Certifications & Secretary General Sattvik Council of India",
    link: "https://www.linkedin.com/in/abiswasonline/"
  },

  // {
  //   id: "4",
  //   Name: "Huizhong Yang",
  //   Location: "",
  //   Image: Indotwo,
  //   Content: "founderfive",
  //   director: "Director - Sattvik Certifications Indonesia",
  //   link: "#"
  // },
  


  // {
  //   id: "3",
  //   Name: "Dr. N Rusmiati M.Si",
  //   Location: "",
  //   Image: Indoone,
  //   Content: "founderthree",
  //   director: "President of ASITA & Director - Sattvik Certifications Indonesia",
  //   link: "#"
  // },



  {
    id: "2",
    Name: "Mr. Venkataraman Kumar",
    Location: "",
    Image: Singapur,
    Content: "In the dynamic landscape of Singapore, Sattvik certifications bloom as a symbol of purity and authenticity, harmonizing with the city-state's diverse cultural tapestry. Guiding consumers through the bustling markets and urban wellness hubs, Sattvik certifications illuminate the path to a balanced and nourished life, seamlessly blending tradition with the modern Singaporean lifestyle.",
    director: "Director - “Sattvik Certifications Singapore Pte Ltd.",
    link: "https://www.linkedin.com/in/sindadvisor/"
  },
  // {
  //   id: "5",
  //   Name: "Mr. Bagus Kestiadi",
  //   Location: "",
  //   Image: indobaugus,
  //   Content: "bagus",
  //   director: "Director - Sattvik Certifications Indonesia",
  //   link: "https://www.linkedin.com/in/sindadvisor/"
  // },
];

const Representative = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[])

  return (
    <>
      <div className="container-fluid p-5 text-dark text-center">
        <h1 className="whoweare-css-heading-data" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Sattvik Certifications Singapore Representatives</h1>
      </div>

      <div className="container mt-5">
        {/* <div className="row row-representative new-gap"> */}
          {representatives.map((representative) => (

            <>
            <div className="row row-representative new-gap">
            
            <div className="col-sm-6 mt-4 new-content-id" key={representative.id}>
            <div className=" container both-content ">
                      <h4 className='founder-one-heading'>{representative.Name}</h4>
                      <p className='founder-one-director'>{representative.director}</p>
                      <h5 className='founder-one-location'>{representative.Location}</h5>
                      </div>
                  <p className='founder-one'>{representative.Content}</p>
                
                  {/* <NavLink to={representative.link}>
                      
                  </NavLink> */}
              </div>
              <div className="mt-5 col-sm-4 new-custom-founder-repra" key={representative.id}>

                      <img className="img-fluid representative-image" loading="auto" src={representative.Image} alt={representative.Name} width="230" />
                     
                      {/* <NavLink to={representative.link}> */}
                      {/* <i className="ri-linkedin-fill datalink"></i> */}
                     {/* </NavLink> */}
                  </div>
                  
                  </div>
                  </>
          ))}
        </div>
      {/* </div> */}
    </>
  );
}

export default Representative;
