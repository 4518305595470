import React , {useEffect} from 'react'
import SubBrands from '../components/brand/subbrand'
import Compsubheader from "../components/training/trainingsubheader";
import Brand from "../components/assests/images/indo/companyjakarta-min.jpg"
import Anna from '../components/assests/images/logo/anna-min.png'
import Ashoka from '../components/assests/images/logo/ashoka-min.png'
import Dnv from '../components/assests/images/logo/dnv-min.png'
import Jaganath from '../components/assests/images/logo/jaganath-min.png'
import Patil from '../components/assests/images/logo/patil-min.png'
import Artofliving from '../components/assests/images/logo/artofliving-min.png'
import Bikaner from '../components/assests/images/logo/bikaner-min.png'
import Bureau from '../components/assests/images/logo/bureau_veritas-min.png'
import Davik from '../components/assests/images/logo/davik-min.png'
import Fedhasa from '../components/assests/images/logo/fedhasa-min.png'
import Fhrai from '../components/assests/images/logo/fhrai-min.png'
import Foxnut from '../components/assests/images/logo/foxy-min.png'
import Govinda from '../components/assests/images/logo/govind--min.png'
import Bhuwansher from '../components/assests/images/logo/ihm-min.png'
import Irctc from '../components/assests/images/logo/irctc-min.png'
import Ahmedabad from '../components/assests/images/logo/itc-hotel-ahmedabad-min.png'
import Chennai from '../components/assests/images/logo/itc-hotel-chnnai-min.png'
import Ihotel from '../components/assests/images/logo/itc-kolkata-min.png'
import Svsu from '../components/assests/images/logo/svsu-min.png'
import Varahi from '../components/assests/images/logo/varahi-min.png'
import Chukde from '../components/assests/images/logo/chukde-min.png'
import nchmct from '../components/assests/images/logo/nchmct-min.png'
import Bird from "../components/assests/images/logo/birdgroup-min.png"
import BlueTribe from "../components/assests/images/logo/bluetribe-min.png"
import Ekyam from "../components/assests/images/logo/ekyam-min.png"
import Enark from "../components/assests/images/logo/enark-min.png"
import Farmley from "../components/assests/images/logo/farmley-min.png"
import Gharkul from "../components/assests/images/logo/gharkul-min.png"
import Gooddot from "../components/assests/images/logo/gooddot-min.png"
import Hotel from "../components/assests/images/logo/hotelmanagement-min.png"
import Ikea from "../components/assests/images/logo/ikea-min.png"
import Isha from "../components/assests/images/logo/isha-min.png"
import Iskon from "../components/assests/images/logo/iskon-min.png"
import lotus from "../components/assests/images/logo/lotus-min.png"
import Manak from "../components/assests/images/logo/manak-min.png"
import Madhuram from "../components/assests/images/logo/mdhuram-min.png"
import Mirtillo from "../components/assests/images/logo/mirtillo-min.png"
import Panafrica from "../components/assests/images/logo/panafrica-min.png"
import Pcti from "../components/assests/images/logo/pcti-min.png"
import Radisson from "../components/assests/images/logo/radisson-min.png"
import roseat from "../components/assests/images/logo/roseat-min.png"
import Sri from "../components/assests/images/logo/srisri-min.png"
import Trident from "../components/assests/images/logo/trident-min.png"
import Ulsolution from "../components/assests/images/logo/ulsolution-min.png"
import Welex from "../components/assests/images/logo/welex-min.png"
import Triputi from "../components/assests/images/logo/triputi.png"
import Fort from "../components/assests/images/logo/fort.jpg"
import Ninteen from "../components/assests/images/logo/nineteen78.png"
import Lotus from "../components/assests/images/logo/lotus.png"

const Brands = () => {
  useEffect (()=>{
    window.scrollTo(0,0)
},[])
  const Props = {
    titleone: "Our Global Sattvik Certified Brands",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Brand,
  };

  const AuditHeading = {
    titleoneheading: "Audit & Certification Partner",
  }

  const Audit = [{
    id: "1",
    imageone: Bureau,
    link:"https://www.bureauveritas.co.in/sattvik-food-certification"
  },
  {
    id: "2",
    imageone: Dnv,
    link:"https://www.dnv.com/"
  },
  {
    id: "3",
    imageone: Ulsolution,
    link:"https://www.ul.com/"
  }
  ]


  const Key = {
    titleoneheading: "Our Key Association"
  }

  const Keylogo = [{
    id: "3",
    imageone: Irctc,
    link:"https://www.irctc.co.in/nget/train-search"

  },
  {
    id: "4",
    imageone: Panafrica,
    link:"https://panaccti.org/"
  },
  {
    id: "5",
    imageone: Fedhasa,
    link:"https://fedhasa.co.za/"
  },

  {
    id: "6",
    imageone: nchmct,
    link:"https://www.nchm.gov.in/"
  },

  {
    id: "7",
    imageone: Fhrai,
    link:"https://fhrai.com/"
  },

  {
    id: "8",
    imageone: Bhuwansher,
    link:"https://www.ihmbbs.org/pages/index.php"
  },

  {
    id: "9",
    imageone: Manak,
   
  },

  {
    id: "10",
    imageone: Svsu,
    link:"https://svsu.ac.in/"
  },

  {
    id: "11",
    imageone: Pcti,
    link:"https://www.pctiltd.com/"
  },

  {
    id: "12",
    imageone: Artofliving,
    link:'https://www.artofliving.org/in-en'
  },

  {
    id: "13",
    imageone: Hotel,
    link:"https://ihmbhopal.ac.in/"
  },

  {
    id: "14",
    imageone: Patil,
    link:"https://dpu.edu.in/"
  },


  ]

  const Keyreference = {
    titleoneheading: "Sattvik Certification Key Reference",
  }

  const KeyreferenceSattvik = [{
    id: "16",
    imageone: Irctc,
    link:"https://www.irctc.co.in/nget/train-search"

  },
  {
    id: "17",
    imageone: Bikaner,
    link:"https://bikanervala.com/"

  },
  {
    id: "18",
    imageone: Gooddot,
    link:"https://gooddot.in/"
  },

  {
    id: "19",
    imageone: Jaganath,
    link:"https://www.shreejagannatha.in/"
  },

  {
    id: "20",
    imageone: Iskon,
    link:"https://www.iskcon.org/"
  },

  {
    id: "21",
    imageone: Davik,
    link:"https://www.davik.in/"
  },

  {
    id: "22",
    imageone: Foxnut,
    link:"https://www.foxynut.in/"
  },

  {
    id: "23",
    imageone: lotus,
    link:"https://www.lotswholesale.com/"
  },

  {
    id: "24",
    imageone: Ashoka,
    link:"https://www.theashokhotel.com/"
  },

  {
    id: "25",
    imageone: BlueTribe,
    link:"https://www.bluetribefoods.com/"
  },

  {
    id: "26",
    imageone: Chukde,
    link:"https://www.chukde.com/"
  },

  {
    id: "27",
    imageone: Ikea,
    link:"https://www.ikea.com/in/en/"
  },

  
  {
    id: "28",
    imageone: Varahi,
    link:"https://varahi.in/"
  },

  
  {
    id: "29",
    imageone: Anna,
  },

  
  {
    id: "30",
    imageone: Sri,
    link:"https://www.srisritattva.com"
  },
  {
    id: "31",
    imageone: Govinda,
    link:"https://www.govindas.com/"
  },

  {
    id: "32",
    imageone: roseat,
    link:"https://www.roseatehotels.com/"
  },

  {
    id: "33",
    imageone: Bird,
    link:"https://bird.in/"
  },
  {
    id: "34",
    imageone: Chennai,
    link:"https://www.itchotels.com/"
  },

  {
    id: "35",
    imageone: Ahmedabad,
    link:"https://www.itchotels.com/"
  },

  {
    id: "36",
    imageone: Ihotel,
    link:"https://www.itchotels.com/"
  },

  {
    id: "37",
    imageone: Radisson,
    link:"https://www.radissonhotels.com/"
  },

  {
    id: "38",
    imageone: Trident,
    link:"https://www.tridenthotels.com/"
  },

  {
    id: "39",
    imageone: Welex,
    link:"https://welexcare.com/"
  },

  {
    id: "40",
    imageone: Gharkul,
    link:"https://www.gharkul.org/"
  },

  {
    id: "41",
    imageone: Farmley,
    link:"https://www.farmley.com/"
  },

  {
    id: "42",
    imageone: Mirtillo,
    link:"http://www.mirtillo.in/"
  },

  {
    id: "43",
    imageone: Enark,
    link:"https://www.enark.in/"
  },

  {
    id: "44",
    imageone: Isha,
    link:"https://www.ishaagro.com/"
  },

  {
    id: "45",
    imageone: Madhuram,
  },

  {
    id: "46",
    imageone: Ekyam,
    link:"https://www.ekyamkitchen.com/"
  },

  {
    id: "47",
    imageone: Triputi,
    link:"https://tirupatifood.com/"
  },

  {
    id: "48",
    imageone: Fort,
    link:"https://fortcaps.com/"
  },

  {
    id: "49",
    imageone: Ninteen,
    link:"https://www.instagram.com/nineteen78official/?hl=en"
  },

  
  {
    id: "50",
    imageone: Lotus,
    link:"https://hotelwhitelotus.in/"
  },
  ]

  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <SubBrands Audit={Audit} AuditHeading={AuditHeading} />
      </section>
      <section>
        <SubBrands Audit={Keylogo} AuditHeading={Key} />
      </section>
      <section>
        <SubBrands Audit={KeyreferenceSattvik} AuditHeading={Keyreference} />
      </section>
    </>
  )
}

export default Brands