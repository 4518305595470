import React from 'react'
import "../assests/css/codex/codex.css"

const Organic = () => {

  return (
    <>
     <h1  className='color-code-code'>Organic Processed Food</h1>
    {/* <h3 className='color-code'>{selectedCertificationId}.Country{industry}{selectedvikram2079id}{selectProfiledetailsid}{subclasssvalue}{microindexvalue}{comissioncosde}</h3> */}
    <p className="color-code-new">A Food prepared with 100 % vegetarian ingredients, and ensuring free from contamination by non-Vegetarian food (Ex: Eggs, Meat, Fish, etc.) or any ingredients derived from animal slaughter. A vegetarian mega kitchen area is above 5000 sq. ft. , capacity is over 5,000 meal per day, 24*7 working hrs, no of employees working are above 900.</p>
    </>
  )
}

export default Organic