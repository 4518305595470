import React from 'react'
import "../assests/css/privacy/privacy.css"

const Termandconditon = () => {
  
  return (
    <>
      <div className="container main-container-privacy">
        <div className="container-fluid p-5  text-dark text-center custom-legal-container">
          <h1 className="privacy-h1">Terms and Conditionss of Services</h1>
          <p className='subheading-p-color'>Sattvik Certifications Singapore</p>
        </div>


        <div className="container mt-5">
          <div className="row row-privacy">
            <div className="col-sm-12">
              <h1 className="subheading-p2 new-term">Terms of Use</h1>
            </div>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row row-privacy">
            <div className="col-sm-12">
              <h3 className="privacy-h1">License</h3>
              <p className="subheading-p2">Unless otherwise stated, Sattvik Certifications Singapore and/or its licensors own th intellectual property rights for all material on Sattvik Certifications Singapore. All intellectual property rights are reserved. You may access this from Sattvik Certifications Singapore for your own personal use subjected to restrictions set in these terms and conditions. You must not:</p>
              <p className="subheading-p2">1.Republish material from Sattvik Certifications Singapore.</p>
              <p className="subheading-p2">2.Sell, rent or sub-license material from Sattvik Certifications Singapore.</p>
              <p className="subheading-p2">3.Reproduce, duplicate or copy material from Sattvik Certifications Singapore.</p>
              <p className="subheading-p2">4.Redistribute content from Sattvik Certifications Singapore.</p>
              <p className="subheading-p2">5.This Agreement shall begin on the date hereof. Our Terms and Conditions were created withthe help of the Terms and Conditions Generator. Parts of this website offer an opportunity for users to post and exchange opinions andinformation in certain areas of the website. Sattvik Certifications Indonesia does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Sattvik Certifications Indonesia, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Sattvik Certifications Indonesia shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website. Sattvik Certifications Indonesia reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
            </div>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row row-privacy">
            <div className="col-sm-12">
              <p className="subheading-p2">You warrant and represent that:</p>
              <p className="subheading-p2">1.You are entitled to post the Comments on our website and have all necessary licenses and consents to do so.</p>
              <p className="subheading-p2">2.The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party.</p>
              <p className="subheading-p2">3.The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful material which is an invasion of privacy.</p>
              <p className="subheading-p2">4.The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</p>
              <p className="subheading-p2">5.You hereby grant Sattvik Certifications Indonesia a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms,formats or media.
              </p>
            </div>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row row-privacy">
            <div className="col-sm-12">
              <h3 className="privacy-h1">Hyperlinking to our Content</h3>
              <p className="subheading-p2">The following organizations may link to our Website without prior written approval:</p>
              <p className="subheading-p2">1.Government agencies.</p>
              <p className="subheading-p2">2.Search engines.</p>
              <p className="subheading-p2">3.News organizations.</p>
              <p className="subheading-p2">4.Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses.</p>
              {/* <p className="subheading-p2">5.{t('system')}
              </p> */}

              {/* <p className="subheading-p2">We may consider and approve other link requests from the following types of organizations:</p>
              <p className="subheading-p2">commonly-known consumer and/or business information sources.</p>
              <p className="subheading-p2">dot.com community sites.</p>
              <p className="subheading-p2">associations or other groups representing charities;</p>
              <p className="subheading-p2">online directory distributors.</p>
              <p className="subheading-p2">internet portals.</p>
              <p className="subheading-p2">accounting, law and consulting firms; and</p>
              <p className="subheading-p2">Educational institutions and trade associations.</p> */}

            </div>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row row-privacy">
            <div className="col-sm-12">
              <p className="subheading-p2">We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Sattvik Certifications Indonesia; and (d) the link is in the context of general resource information.These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site. If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Sattvik Certifications Indonesia. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
              <p className="subheading-p2">Approved organizations may hyperlink to our Website as follows:</p>
              <p className="subheading-p2">By use of our corporate name; or'</p>
              <p className="subheading-p2">By use of the uniform resource locator being linked to; or</p>
              <p className="subheading-p2">By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.No use of Sattvik Certifications Singapore’s logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
            </div>
          </div>
        </div>




        <div className="container mt-5">
          <div className="row row-privacy">
            <div className="col-sm-12">
              <h3 className="privacy-h1">Iframes</h3>
              <p className="subheading-p2">Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
            </div>
          </div>
        </div>


        <div className="container mt-5">
          <div className="row row-privacy new-row-privacy">
            <div className="col-sm-12">
              <h3 className="privacy-h1">Content Liability</h3>
              <p className="subheading-p2">We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
            </div>
          </div>
        </div>



      </div>


    </>
  )
}

export default Termandconditon