import React, { useEffect } from 'react'
import Compsubheader from '../components/training/trainingsubheader'
import CodexImg from "../components/assests/images/books/codex.jpg"
import CodexContent from "../components/codex/codex"

const Codex = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const Props = {
    titleone: "codex",
    titlesecond: "codex",
    titlethird: "Here are the steps to sattvik certification codex.",
    Imgurl: CodexImg,
  };

  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      <section>
        <CodexContent Props={Props} />
      </section>
    </>
  )
}

export default Codex