import React from 'react'
import "../assests/css/footer/footerterm.css"
import { NavLink } from 'react-router-dom';

const Footerterm = () => {
 
    return (
        <div className="container-fluid text-center background-custom">
        <ul className="footer-links">

        <NavLink to = "/legalnotice">
          <li className="Legal">
            <p className="legal-one">Legal Notice | </p>
          </li>
          </NavLink>

          <NavLink to = "/termofuse">
          <li className="Term">
            <p className="legal-one">Term of Use | </p>
          </li>
          </NavLink>

          <NavLink to="/cookiespolicy">
          <li className="Cookies">
            <p className="legal-one">Cookies Policy  | </p>
            
          </li>
          </NavLink>

          <NavLink to="/privacypolicy">
          <li className="Privacy">
            <p className="legal-one">Privacy Policy | </p>
          </li>
          </NavLink>

          <NavLink to="/termandcondtion">
          <li className="Condition">
            <p className="legal-one">Terms and Conditions of Services</p>
          </li>
          </NavLink>
        </ul>
      </div>
    )
}

export default Footerterm