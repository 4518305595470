import React, { Suspense, useEffect  , useState} from 'react'
import Compsubheader from "../components/training/trainingsubheader";
import Galleryimg from "../components/assests/images/gallery/gallery.jpg"
import "../components/assests/css/gallery/gallery.css"

const GalleryComponent = React.lazy(() => import("../components/galleryImages/gallery"));


const Gallery = () => {
  const [load , setLoad] = useState(false)

  useEffect(() => {

    window.scrollTo(0, 0)
    setLoad(true)
    setTimeout(()=>{
      setLoad(false)
    },4000)
  }, [])
  const Props = {
    titleone: "Our Gallery",
    titlesecond: " ",
    titlethird: " ",
    Imgurl: Galleryimg,
  };

  return (
    <>
      <section>
        <Compsubheader Props={Props} />
      </section>
      { load ? (
        <div className="loading-indicator">Loading...</div>
      ):(
       <section>
        <Suspense fallback={<p className="loading-indicator">Its Loading...................</p>}>
          <GalleryComponent />
        </Suspense>
      </section>
      )}
    </>
  )
}

export default Gallery