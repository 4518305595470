import React from 'react'


const Postaudit = () => {

  return (
    <>
    <h2  className = "code-heading">Post Audit Training Program</h2>
    <p className = "code-content ">Post Audit Training Program is particularly designed to train the Industry staff as per the the requirements for the Sattvik Management Systems (SMS). It helps an organization to ensure the maintenance of the Sattvik Standard continuously and be always ready for any kind of surprise checks during the certification it contributes to the optimal maintenance & verification of the Sattvik Standards for an organization. This helps the staff to acquire the proficiency and expertise to maintain Sattvik management systems (SMS) and develop skills to implement the corrective actions if required. This training program supports to develop requisite knowledge and skills for the execution of the Sattvik certification.</p>
    <p className="code-pointone">Fee : Rs. 10,000/- for 10 person include additional @ 500/- per person.</p>
    <p className = "css-pre">Inclusive of 18%GST</p>
    <p className="code-heading-one">This program is available with Art of Living by paying Rs 1000/- extra.</p>
    </>
  )
}

export default Postaudit