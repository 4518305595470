import React, { useEffect, useMemo, useState } from 'react'
import firstbook from "../assests/images/books/firstbook-min.png"
import secondbook from "../assests/images/books/secondbook-min.png"
import thirdbook from "../assests/images/books/thirdbook-min.png"
import fourthbook from "../assests/images/books/fourthbook-min.png"
import fivebook from "../assests/images/books/fivebook.png"
import sixbook from "../assests/images/books/resizedimage.png"
import sevenbook from "../assests/images/books/sevenbook-min.png"
import eightbook from "../assests/images/books/eightbook-min.png"
import ninebook from "../assests/images/books/ninebook-min.png"
import tenbook from "../assests/images/books/tenbook.png"
import "../assests/css/book/bookstyle.css"
import { useTranslation } from 'react-i18next'
import AOS from "aos"
import 'aos/dist/aos.css'


const Book = () => {
  const { t } = useTranslation()

  useEffect(()=>{
    AOS.init({duration:2000})
  },[])


  const Arrayvalue = useMemo(() => [
    {
      id: "1",
      manualone: "book-one"

    },

    {
      id: "2",
      manualone: "book-two"

    },

    {
      id: "3",
      manualone: "book-three"

    },

    {
      id: "4",
      manualone: "book-four"

    },

    {
      id: "5",
      manualone: "book-five"

    },
  ], [])



  const Arrayvaluedata = useMemo(() => [
    {
      id: "6",
      manualone: "book-six",

    },

    {
      id: "7",
      manualone: "book-seven"
    },

    {
      id: "8",
      manualone: "book-eight"
    },

    {
      id: "9",
      manualone: "book-nine"

    },

    {
      id: "10",
      manualone: "book-ten"

    },
  ], []);




  const [isHovered, setIsHovered] = useState(false);
  const [parahover, setparahover] = useState(false)
  // console.log(parahover)
  const [selectedImage, setselectedImage] = useState(0)
  const [leftside, setleftsidepara] = useState(0)
  const [newpararightside, setnewpararightsidepara] = useState("")
  const [rightside, setrightsidepara] = useState(0)
  const [newparaleftside, setnewparaleftsidepara] = useState("")
  const backgroundImg = useMemo(() => [firstbook, secondbook, thirdbook, fourthbook, fivebook], []);
  const [selectedImagesecond, setselectedImagesecond] = useState(0)
  const backgroundImgsecond = useMemo(() => [sixbook, sevenbook, eightbook, ninebook, tenbook], []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setselectedImage(selectedImage => selectedImage < 4 ? selectedImage + 1 : 0)

        const arrayLength = Arrayvalue.length;
        if (arrayLength === 0) {
          return;
        }
        const nextIndex = (leftside + 1) % arrayLength;
        setleftsidepara(nextIndex);
        const nextArrayValue = Arrayvalue[nextIndex].manualone;
        setnewparaleftsidepara(nextArrayValue)
      }
    }, 2000)

    const intervalsecond = setInterval(() => {
      if (!isHovered) {
        setselectedImagesecond(selectedImagesecond => selectedImagesecond < 4 ? selectedImagesecond + 1 : 0)
        const arrayLengthdata = Arrayvaluedata.length;
        if (arrayLengthdata === 0) {
          return;
        }
        const nextIndexdata = (rightside + 1) % arrayLengthdata;
        setrightsidepara(nextIndexdata);
        const nextArrayValuedata = Arrayvaluedata[nextIndexdata].manualone;
        setnewpararightsidepara(nextArrayValuedata)
        // console.log(newpararightside)
      }
    }, 2000)

    return () => {
      clearInterval(interval);
      clearInterval(intervalsecond);
    };
  }, [backgroundImg, backgroundImgsecond, isHovered, leftside, Arrayvalue, Arrayvaluedata, newpararightside, rightside]);


  const Nextbutton = (() => {
    const arrayLengthdata = Arrayvaluedata.length;
    if (arrayLengthdata === 0) {
      return;
    }
    const nextIndexdata = (rightside + 1) % arrayLengthdata;
    setrightsidepara(nextIndexdata);
    const nextArrayValuedata = Arrayvaluedata[nextIndexdata].manualone;
    setnewpararightsidepara(nextArrayValuedata)
    
    const arrayLength = Arrayvalue.length;
    if (arrayLength === 0) {
      return;
    }
    const nextIndex = (leftside + 1) % arrayLength;
    setleftsidepara(nextIndex);
    const nextArrayValue = Arrayvalue[nextIndex].manualone;
    setnewparaleftsidepara(nextArrayValue)

    setselectedImage(() =>
      selectedImage < backgroundImg.length - 1
        ? selectedImage + 1
        : 0
    );

    setselectedImagesecond((selectedImagesecond) =>
      selectedImagesecond < backgroundImg.length - 1
        ? selectedImagesecond + 1
        : 0
    );


  })

  // const previousbutton = () => {


  //   const arrayLengthdata = Arrayvaluedata.length;
  //   if (arrayLengthdata === 0) {
  //     return;
  //   }
  //   const nextIndexdata = (rightside + 1) % arrayLengthdata;
  //   setrightsidepara(nextIndexdata);
  //   const nextArrayValuedata = Arrayvaluedata[nextIndexdata].manualone;
  //   setnewpararightsidepara(nextArrayValuedata)
  //   console.log(newpararightside)


  //   const arrayLength = Arrayvalue.length;
  //   if (arrayLength === 0) {
  //     return;
  //   }
  //   const nextIndex = (leftside + 1) % arrayLength;
  //   setleftsidepara(nextIndex);
  //   const nextArrayValue = Arrayvalue[nextIndex].manualone;
  //   setnewparaleftsidepara(nextArrayValue)


  //   setselectedImage(() =>
  //     selectedImage > 0
  //       ? selectedImage - 1
  //       : 0
  //   );

  //   setselectedImagesecond((selectedImagesecond) =>
  //     selectedImagesecond > 0
  //       ? selectedImagesecond - 1
  //       : 0
  //   );
  // }
  return (
    <>
      <div className="container-fluid p-5  text-dark text-center" >
        <h1 className='header-manual' data-aos="fade-up" data-aos-anchor-placement="top-bottom" >{t('Sattvik Operational Manuals')}</h1>
      </div>

      <div className="container-fluid backgroundcolor  mt-5 "
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div className="row  no-column">
          {/* first column */}
          <div className="col-sm-3 custom-display-felx ">
            {parahover && (
              <p className='text-white text-center new-flex-hover-first'>{t(newparaleftside)}</p>
            )}
          </div>
          {/* first column end */}

          {/* second column */}
          <div className='col-sm-3 image-book-data' >
            <img className="bookstyle new-books-style-two" loading="auto" src={backgroundImg[selectedImage]} alt="error" onMouseEnter={() => setparahover(true)} onMouseLeave={() => setparahover(false)} />
          </div>
          {/* second column end */}


          {/* third column */}
          <div className='col-sm-3 image-custom-display image-book-data'>
            <img className='bookstyle new-books-style-two' loading="auto" src={backgroundImgsecond[selectedImagesecond]} alt="error" onMouseEnter={() => setparahover(true)} onMouseLeave={() => setparahover(false)} />
          </div>

          {/* third column end */}

          {/* fourth column */}
          <div className="col-sm-3 custom-display-felx ">
            {parahover && (

              <p className='text-white text-center newright  new-flex-hover-first'>{t(newpararightside)}</p>
            )}
          </div>
          {/* fourth column end */}


        </div>

        <div className="container-fluid text-center arrow-icon">
          {/* <i className="ri-arrow-left-s-line right-arrow" onClick={previousbutton}></i> */}
          <i className="ri-arrow-right-s-line left-arrow" onClick={Nextbutton} ></i>
        </div>

      </div>
    </>


  )
}
export default Book