import React, { useState, useEffect } from 'react'
import "../assests/css/whoweare/whoweare.css"
import { useLocation} from 'react-router-dom'




const Whowearenav = ({ subheadernav ,handleLocationClick ,handleLocationClickteam ,handleLocationClickservice ,representaivevalue }) => {
  const newLocation = useLocation()
  const exactLocation = newLocation.pathname
  
  // console.log(subheadernav)


  const [location, setlocation] = useState('')

  const handleclickwhoweare = () => {
    handleLocationClick(true) 
    setlocation("Who-css")
    // setourhistory(true)

  }

  const handleclickteam = () =>{
    handleLocationClickteam(true) 
    setlocation("Who-css-team")
  }

  const handleclickservice = () =>{
    handleLocationClickservice(true) 
    setlocation("Who-css-service")
  }

  const handlerepresentative = () =>{
    representaivevalue(true)
    setlocation("Who-css-represent")

  }


  useEffect(() => {
    if (exactLocation === "/Whoweare" || exactLocation === "/ourhistory" ) {
      setlocation("Who-css")
    } else if (exactLocation === "/ourteam" ||  exactLocation === "/ourfuture" ) {
      setlocation("Who-css-team")
    } else if (exactLocation === "/services" || exactLocation === "/operatingcountry") {
      setlocation("Who-css-service")
    } else if (exactLocation === "/representative"  || exactLocation === "/bussiness") {
      setlocation("Who-css-represent")
    }

  }, [exactLocation])

  return (
    <div className="border-design">
      <div className="container  container-border mt-5">
        <div className="row custom-css">
          <div className="col-sm-3 cursor-pointer ">
            <h5 className={`newclass-font-nav ${location === 'Who-css' ? 'activeClass' : ''}`}onClick={handleclickwhoweare}>{subheadernav.one}</h5>
          </div>
          
          <div className="col-sm-3 cursor-pointer ">
            <h5 className={`newclass-font-nav ${location === 'Who-css-team' ? 'activeClass' : ''}`} onClick={handleclickteam}>{subheadernav.two}</h5>

          </div>
          <div className="col-sm-3 cursor-pointer ">
            <h5 className={`newclass-font-nav ${location === 'Who-css-service' ? 'activeClass' : ''} `} onClick={handleclickservice} >{subheadernav.three}</h5>
          </div>
          <div className="col-sm-3 cursor-pointer ">
            <h5 className={`newclass-font-nav ${location === 'Who-css-represent' ? 'activeClass' : ''}`} onClick={handlerepresentative}>{subheadernav.four}</h5>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Whowearenav