
import React, { useState, useEffect, useMemo } from 'react'
import "../assests/css/training/trainingcode.css"
import Lapcomponent from './lapcomponent';
import Quality from "./quality"
import Railway from "./railwaycomonent"
import Preaudit from './preaudit';
import Postaudit from './postaudit';
import Kitchen from "./kitchen"
import Hospitality from './hospitality';







const Trainingcode = () => {

    const Countryname = useMemo(() => [

        { name: "Afghanistan", id: "AF-" },
        { name: "Land Islands", id: "AX-" },
        { name: "Albania", id: "AL-" },
        { name: "Algeria", id: "DZ-" },
        { name: "American Samoa", id: "AS-" },
        { name: "AndorrA", id: "AD-" },
        { name: "Angola", id: "AO-" },
        { name: "Anguilla", id: "AI-" },
        { name: "Antarctica", id: "AQ-" },
        { name: "Antigua and Barbuda", id: "AG-" },
        { name: "Argentina", id: "AR-" },
        { name: "Armenia", id: "AM-" },
        { name: "Aruba", id: "AW-" },
        { name: "Australia", id: "AU-" },
        { name: "Austria", id: "AT-" },
        { name: "Azerbaijan", id: "AZ-" },
        { name: "Bahamas", id: "BS-" },
        { name: "Bahrain", id: "BH-" },
        { name: "Bangladesh", id: "BD-" },
        { name: "Barbados", id: "BB-" },
        { name: "Belarus", id: "BY-" },
        { name: "Belgium", id: "BE-" },
        { name: "Belize", id: "BZ-" },
        { name: "Benin", id: "BJ-" },
        { name: "Bermuda", id: "BM-" },
        { name: "Bhutan", id: "BT-" },
        { name: "Bolivia", id: "BO-" },
        { name: "Bosnia and Herzegovina", id: "BA-" },
        { name: "Botswana", id: "BW-" },
        { name: "Bouvet Island", id: "BV-" },
        { name: "Brazil", id: "BR-" },
        { name: "British Indian Ocean Territory", id: "IO-" },
        { name: "Brunei Darussalam", id: "BN-" },
        { name: "Bulgaria", id: "BG-" },
        { name: "Burkina Faso", id: "BF-" },
        { name: "Burundi", id: "BI-" },
        { name: "Cambodia", id: "KH-" },
        { name: "Cameroon", id: "CM-" },
        { name: "Canada", id: "CA-" },
        { name: "Cape Verde", id: "CV-" },
        { name: "Cayman Islands", id: "KY-" },
        { name: "Central African Republic", id: "CF-" },
        { name: "Chad", id: "TD-" },
        { name: "Chile", id: "CL-" },
        { name: "China", id: "CN-" },
        { name: "Christmas Island", id: "CX-" },
        { name: "Cocos (Keeling) Islands", id: "CC-" },
        { name: "Colombia", id: "CO-" },
        { name: "Comoros", id: "KM-" },
        { name: "Congo", id: "CG-" },
        { name: "Congo, The Democratic Republic of the", id: "CD-" },
        { name: "Cook Islands", id: "CK-" },
        { name: "Costa Rica", id: "CR-" },
        { name: "Cote DIvoire", id: "CI-" },
        { name: "Croatia", id: "HR-" },
        { name: "Cuba", id: "CU-" },
        { name: "Cyprus", id: "CY-" },
        { name: "Czech Republic", id: "CZ-" },
        { name: "Denmark", id: "DK-" },
        { name: "Djibouti", id: "DJ-" },
        { name: "Dominica", id: "DM-" },
        { name: "Dominican Republic", id: "DO-" },
        { name: "Ecuador", id: "EC-" },
        { name: "Egypt", id: "EG-" },
        { name: "El Salvador", id: "SV-" },
        { name: "Equatorial Guinea", id: "GQ-" },
        { name: "Eritrea", id: "ER-" },
        { name: "Estonia", id: "EE-" },
        { name: "Ethiopia", id: "ET-" },
        { name: "Falkland Islands (Malvinas)", id: "FK-" },
        { name: "Faroe Islands", id: "FO-" },
        { name: "Fiji", id: "FJ-" },
        { name: "Finland", id: "FI-" },
        { name: "France", id: "FR-" },
        { name: "French Guiana", id: "GF-" },
        { name: "French Polynesia", id: "PF-" },
        { name: "French Southern Territories", id: "TF-" },
        { name: "Gabon", id: "GA-" },
        { name: "Gambia", id: "GM-" },
        { name: "Georgia", id: "GE-" },
        { name: "Germany", id: "DE-" },
        { name: "Ghana", id: "GH-" },
        { name: "Gibraltar", id: "GI-" },
        { name: "Greece", id: "GR-" },
        { name: "Greenland", id: "GL-" },
        { name: "Grenada", id: "GD-" },
        { name: "Guadeloupe", id: "GP-" },
        { name: "Guam", id: "GU-" },
        { name: "Guatemala", id: "GT-" },
        { name: "Guernsey", id: "GG-" },
        { name: "Guinea", id: "GN-" },
        { name: "Guinea-Bissau", id: "GW-" },
        { name: "Guyana", id: "GY-" },
        { name: "Haiti", id: "HT-" },
        { name: "Heard Island and Mcdonald Islands", id: "HM-" },
        { name: "Holy See (Vatican City State)", id: "VA-" },
        { name: "Honduras", id: "HN-" },
        { name: "Hong Kong", id: "HK-" },
        { name: "Hungary", id: "HU-" },
        { name: "Iceland", id: "IS-" },
        { name: "India", id: "IN-" },
        { name: "Indonesia", id: "ID-" },
        { name: "Iran, Islamic Republic Of", id: "IR-" },
        { name: "Iraq", id: "IQ-" },
        { name: "Ireland", id: "IE-" },
        { name: "Isle of Man", id: "IM-" },
        { name: "Israel", id: "IL-" },
        { name: "Italy", id: "IT-" },
        { name: "Jamaica", id: "JM-" },
        { name: "Japan", id: "JP-" },
        { name: "Jersey", id: "JE-" },
        { name: "Jordan", id: "JO-" },
        { name: "Kazakhstan", id: "KZ-" },
        { name: "Kenya", id: "KE-" },
        { name: "Kiribati", id: "KI-" },
        { name: "Korea, Democratic People Republic of", id: "KP-" },
        { name: "Korea, Republic of", id: "KR-" },
        { name: "Kuwait", id: "KW-" },
        { name: "Kyrgyzstan", id: "KG-" },
        { name: "Lao PeopleS Democratic Republic", id: "LA-" },
        { name: "Latvia", id: "LV-" },
        { name: "Lebanon", id: "LB-" },
        { name: "Lesotho", id: "LS-" },
        { name: "Liberia", id: "LR-" },
        { name: "Libyan Arab Jamahiriya", id: "LY-" },
        { name: "Liechtenstein", id: "LI-" },
        { name: "Lithuania", id: "LT-" },
        { name: "Luxembourg", id: "LU-" },
        { name: "Macao", id: "MO-" },
        { name: "Macedonia, The Former Yugoslav Republic of", id: "MK-" },
        { name: "Madagascar", id: "MG-" },
        { name: "Malawi", id: "MW-" },
        { name: "Malaysia", id: "MY-" },
        { name: "Maldives", id: "MV-" },
        { name: "Mali", id: "ML-" },
        { name: "Malta", id: "MT-" },
        { name: "Marshall Islands", id: "MH-" },
        { name: "Martinique", id: "MQ-" },
        { name: "Mauritania", id: "MR-" },
        { name: "Mauritius", id: "MU-" },
        { name: "Mayotte", id: "YT-" },
        { name: "Mexico", id: "MX-" },
        { name: "Micronesia, Federated States of", id: "FM-" },
        { name: "Moldova, Republic of", id: "MD-" },
        { name: "Monaco", id: "MC-" },
        { name: "Mongolia", id: "MN-" },
        { name: "Montenegro", id: "ME-" },
        { name: "Montserrat", id: "MS-" },
        { name: "Morocco", id: "MA-" },
        { name: "Mozambique", id: "MZ-" },
        { name: "Myanmar", id: "MM-" },
        { name: "Namibia", id: "NA-" },
        { name: "Nauru", id: "NR-" },
        { name: "Nepal", id: "NP-" },
        { name: "Netherlands", id: "NL-" },
        { name: "Netherlands Antilles", id: "AN-" },
        { name: "New Caledonia", id: "NC-" },
        { name: "New Zealand", id: "NZ-" },
        { name: "Nicaragua", id: "NI-" },
        { name: "Niger", id: "NE-" },
        { name: "Nigeria", id: "NG-" },
        { name: "Niue", id: "NU-" },
        { name: "Norfolk Island", id: "NF-" },
        { name: "Northern Mariana Islands", id: "MP-" },
        { name: "Norway", id: "NO-" },
        { name: "Oman", id: "OM-" },
        { name: "Pakistan", id: "PK-" },
        { name: "Palau", id: "PW-" },
        { name: "Palestinian Territory, Occupied", id: "PS-" },
        { name: "Panama", id: "PA-" },
        { name: "Papua New Guinea", id: "PG-" },
        { name: "Paraguay", id: "PY-" },
        { name: "Peru", id: "PE-" },
        { name: "Philippines", id: "PH-" },
        { name: "Pitcairn", id: "PN-" },
        { name: "Poland", id: "PL-" },
        { name: "Portugal", id: "PT-" },
        { name: "Puerto Rico", id: "PR-" },
        { name: "Qatar", id: "QA-" },
        { name: "Reunion", id: "RE-" },
        { name: "Romania", id: "RO-" },
        { name: "Russian Federation", id: "RU-" },
        { name: "RWANDA", id: "RW-" },
        { name: "Saint Helena", id: "SH-" },
        { name: "Saint Kitts and Nevis", id: "KN-" },
        { name: "Saint Lucia", id: "LC-" },
        { name: "Saint Pierre and Miquelon", id: "PM-" },
        { name: "Saint Vincent and the Grenadines", id: "VC-" },
        { name: "Samoa", id: "WS-" },
        { name: "San Marino", id: "SM-" },
        { name: "Sao Tome and Principe", id: "ST-" },
        { name: "Saudi Arabia", id: "SA-" },
        { name: "Senegal", id: "SN-" },
        { name: "Serbia", id: "RS-" },
        { name: "Seychelles", id: "SC-" },
        { name: "Sierra Leone", id: "SL-" },
        { name: "Singapore", id: "SG-" },
        { name: "Slovakia", id: "SK-" },
        { name: "Slovenia", id: "SI-" },
        { name: "Solomon Islands", id: "SB-" },
        { name: "Somalia", id: "SO-" },
        { name: "South Africa", id: "ZA-" },
        { name: "South Georgia and the South Sandwich Islands", id: "GS-" },
        { name: "Spain", id: "ES-" },
        { name: "Sri Lanka", id: "LK-" },
        { name: "Sudan", id: "SD-" },
        { name: "Suriname", id: "SR-" },
        { name: "Svalbard and Jan Mayen", id: "SJ-" },
        { name: "Swaziland", id: "SZ-" },
        { name: "Sweden", id: "SE-" },
        { name: "Switzerland", id: "CH-" },
        { name: "Syrian Arab Republic", id: "SY-" },
        { name: "Taiwan, Province of China", id: "TW-" },
        { name: "Tajikistan", id: "TJ-" },
        { name: "Tanzania, United Republic of", id: "TZ-" },
        { name: "Thailand", id: "TH-" },
        { name: "Timor-Leste", id: "TL-" },
        { name: "Togo", id: "TG-" },
        { name: "Tokelau", id: "TK-" },
        { name: "Tonga", id: "TO-" },
        { name: "Trinidad and Tobago", id: "TT-" },
        { name: "Tunisia", id: "TN-" },
        { name: "Turkey", id: "TR-" },
        { name: "Turkmenistan", id: "TM-" },
        { name: "Turks and Caicos Islands", id: "TC-" },
        { name: "Tuvalu", id: "TV-" },
        { name: "Uganda", id: "UG-" },
        { name: "Ukraine", id: "UA-" },
        { name: "United Arab Emirates", id: "AE-" },
        { name: "United Kingdom", id: "GB-" },
        { name: "United States", id: "US-" },
        { name: "United States Minor Outlying Islands", id: "UM-" },
        { name: "Uruguay", id: "UY-" },
        { name: "Uzbekistan", id: "UZ-" },
        { name: "Vanuatu", id: "VU-" },
        { name: "Venezuela", id: "VE-" },
        { name: "Viet Nam", id: "VN-" },
        { name: "Virgin Islands, British", id: "VG-" },
        { name: "Virgin Islands, U.S.", id: "VI-" },
        { name: "Wallis and Futuna", id: "WF-" },
        { name: "Western Sahara", id: "EH-" },
        { name: "Yemen", id: "YE-" },
        { name: "Zambia", id: "ZM-" },
        { name: "Zimbabwe", id: "ZW-" }



    ], []);


    const Strainingprogm = useMemo(() => [
        {
            id: "SCTN",
            name: "Sattvik Training"
        }

    ], []);


    const Vikram2079 = useMemo(() => [
        {
            id: "2079",
            name: "Vikram Samvat 2079"
        }

    ], []);


    const TrainingProgram = useMemo(() => [
        {
            id: "001",
            name: "Lead Auditor Program",
            code: "1"
        },
        {
            id: "I", name: "Industrial Training", code: "2",
        }

    ], []);


    const Trainingprofile = useMemo(() => [
        {
            id: "PR",
            code: "I",
            name: "Pre-Audit",

        },
        {
            id: "PO",
            code: "I",
            name: "Post-Audit",

        },
        {
            id: "001",
            code: "001",
            name: "LAP Training",

        },

        {
            id: "011",
            code: "001",
            name: "Quality Management",

        },
        {
            id: "021",
            code: "001",
            name: "Kitchen Management",

        },
        {
            id: "031",
            code: "001",
            name: "Hospitality Management",

        },
        {
            id: "041",
            code: "001",
            name: "Vegetarian Friendly Railway Services",

        }

    ], []);

    //  setting state for dropdown start
    const [country, setCountry] = useState([]);
    const [Straining, setSatvikTraining] = useState([])
    const [Vikram, setVikram] = useState([])
    const [Training, setTraining] = useState([])
    const [TrainingProfile, setTrainingProfile] = useState([])
    //  setting state for dropdown End

    // setting id START
    const [selectedCountryId, setSelectedCountryId] = useState("");
    const [selectedCertificationId, setCertificationId] = useState("");
    const [selectedvikram2079id, setselectedvikram2079id] = useState("");
    const [selectProfiledetailsid, setselectProfiledetailsid] = useState("")
    const [fordisplayingdivid, setfordisplayingdivid] = useState()
    const [Lapid, setLapid] = useState()
    const [Qualityid, setQualityid] = useState()
    const [kitchenid, setKitchenid] = useState()
    const [hospitality, sethospitality] = useState()
    const [railwayid, setRailwayid] = useState()
    const [preandpostid, setpreandpostid] = useState()
    const [Preauditid, setPreaudit] = useState()
    const [postaudit, setpostaudit] = useState()
    // setting id End





    // visibilty start
    const [dropone, setdropdown1] = useState(false)
    const [droptwo, setdropdowntwo] = useState(false)
    const [dropthree, setdropthree] = useState(false)
    const [dropfour, setdropfour] = useState(false)
    // visibilty end




    const handleCountryChange = (e) => {
        setSelectedCountryId(e.target.value);
        setdropdown1(true)
    };


    const handleCertificationchange = (e) => {
        setCertificationId(e.target.value)
        setdropdowntwo(true)
    }

    const handlevikram2079change = (e) => {
        setselectedvikram2079id(e.target.value)
        setdropthree(true)
    }
    const onhandlechangeTrainningProgram = (e) => {
        const selectedValue = e.target.value;
        const [selectedId] = selectedValue.split(',');
        // setselecttrainingprogramId(selectedId);
        // setselecttrainingprogramdetails(e.target.details);

        const dt = Trainingprofile.filter(item => item.code === selectedId);
        setTrainingProfile(dt);
        setdropfour(true)
    }

    const handleProfilechange = (e) => {
        const targetedvalue = e.target.value
        const parts = targetedvalue.split(/,\s|\n/);
        const filteredParts = parts.filter(part => part.trim() !== '');
        // console.log(filteredParts)
        const selectedIdtarget = filteredParts[0];
        setselectProfiledetailsid(selectedIdtarget)




    }



    useEffect(() => {
        if (selectProfiledetailsid !== undefined) {

            const numAsString = selectProfiledetailsid.toString();

            // const empty = "";
            const lap = "001"
            const num = "011";
            const numtwo = "021";
            const numthree = "031";
            const numfour = "041";
            const newlap = lap.toString()
            const newnum = num.toString();
            const newnumtwo = numtwo.toString();
            const newnumthree = numthree.toString();
            const newnumfour = numfour.toString();
            // const newempty = empty.toString();
            setfordisplayingdivid(parseInt(numAsString))
            setLapid(parseInt(newlap))
            setQualityid(parseInt(newnum))
            setKitchenid(parseInt(newnumtwo))
            sethospitality(parseInt(newnumthree))
            setRailwayid(parseInt(newnumfour))

        }
    }, [selectProfiledetailsid,]);





    useEffect(() => {
        if (selectProfiledetailsid !== undefined) {
            const newpreauditid = selectProfiledetailsid.toString();
            const codePreAudit = "PR";
            const codePostAudit = "PO";
            const newPreAudit = codePreAudit.toString();
            const newProAudit = codePostAudit.toString();
            setpreandpostid(newpreauditid.trim())
            setPreaudit(newPreAudit.trim())
            setpostaudit(newProAudit.trim())
        }
    }, [selectProfiledetailsid]);






    useEffect(() => {
        setCountry(Countryname)
        setSatvikTraining(Strainingprogm)
        setVikram(Vikram2079)
        setTraining(TrainingProgram)
        setTrainingProfile(Trainingprofile)


    }, [Countryname, Strainingprogm, Vikram2079, TrainingProgram, Trainingprofile])




    return (
        <>
            <div className="container mt-5">
                <div className="row new-row">
                    {/* //for country start */}
                    <div className="col-sm-5 first-row">
                        <div className="row code-container">
                            <div className="select-container">
                                <select className="custom-select" onChange={handleCountryChange}>
                                    <option value="" className='country-data'>Select-Country</option>
                                    {country && country !== undefined ? (
                                        country.map((cname) => (
                                            <option key={cname.id} value={cname.id}>{cname.name}</option>
                                        ))
                                    ) : (
                                        <option disabled>No countries available</option>
                                    )}
                                </select>
                            </div>
                            {/* for country end */}

                            {/* training program start */}
                            <div className="select-container">
                                <select className={`custom-select ${dropone ? '' : 'drop1'}`} onChange={handleCertificationchange}>
                                    <option value="">Please-Select</option>
                                    {
                                        Straining &&
                                            Straining !== undefined ?
                                            Straining.map((Strain, id) => {
                                                return (
                                                    <option key={Strain.id} value={Strain.id}>{Strain.name}</option>
                                                )
                                            })
                                            : "No Certification"
                                    }
                                </select>
                            </div>
                            {/* training program start */}


                            {/* vikram program start */}
                            <div className="select-container">
                                <select className={`custom-select ${droptwo ? '' : 'drop2'}`} onChange={handlevikram2079change}>
                                    <option value="">Please-Select</option>
                                    {
                                        Vikram &&
                                            Vikram !== undefined ?
                                            Vikram.map((Vikramnew, id) => {
                                                return (
                                                    <option key={Vikramnew.id} value={Vikramnew.id}>{Vikramnew.name}</option>
                                                )
                                            })
                                            : "no Straining"
                                    }
                                </select>
                            </div>
                            {/* vikram program start */}

                            {/* training start */}
                            <div className="select-container">
                                <select className={`custom-select ${dropthree ? '' : 'drop3'}`} onChange={onhandlechangeTrainningProgram}>
                                    <option value="">Training-Program</option>
                                    {Training && Training !== undefined ? (
                                        Training.map((current) => {
                                            const value = `${current.id},${current.details}`;
                                            return (
                                                <option key={current.id} value={value}>{current.name}</option>
                                            );
                                        })
                                    ) : (
                                        <option disabled>No Training Program Available</option>
                                    )}
                                </select>
                            </div>

                            {/* training end */}

                            {/* industrial profile start */}
                            <div className="select-container">
                                <select className={`custom-select ${dropfour ? '' : 'drop5'} `} onChange={handleProfilechange}>
                                    <option value="">Select-Industrial-Training-Profile</option>
                                    {
                                        TrainingProfile &&
                                            TrainingProfile !== undefined ?
                                            TrainingProfile.map((elem) => {

                                                const value = `${elem.id} , ${elem.name}, ${elem.heading} , `;
                                                return (
                                                    <option key={elem.id} value={value} >{elem.name}</option>
                                                )
                                            })

                                            : "NO PROFILE"

                                    }

                                </select>
                            </div>
                            {/* industrial profile End */}
                        </div>
                    </div>



                    <div className="col-sm-5 second-row">
                        <div className="code-border">
                            <h1 className='code'>{selectedCountryId}{selectedCertificationId}{selectedvikram2079id}{selectProfiledetailsid}</h1>
                        </div>
                        {fordisplayingdivid === Lapid && <div><Lapcomponent /></div>}
                        {fordisplayingdivid === Qualityid && <div><Quality /></div>}
                        {fordisplayingdivid === hospitality && <div><Hospitality /></div>}
                        {fordisplayingdivid === kitchenid && <div><Kitchen /></div>}
                        {fordisplayingdivid === railwayid && <div><Railway /></div>}
                        {preandpostid === Preauditid && <div><Preaudit /></div>}
                        {preandpostid === postaudit && <div><Postaudit /></div>}
                    </div>
                </div>
            </div>

        </>
    )
}

export default Trainingcode