import React from 'react'
import "../assests/css/tourism/buddhaimpact.css"


const Buddhaimpact = ({Buddha}) => {
 
    return (
        <div className="container mt-5 new-imapct-tourism">
            <div className="row custome-data-row">
                {Buddha.map((element, index) => (
                    <div className="col-sm-12 custom-css-info" key={index}>
                        <h3 className='title-main'>{element.title}</h3>
                        <p className='title-content'>{element.content}</p>
                        {/* <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
}
export default Buddhaimpact