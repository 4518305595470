import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Compsubheader from '../components/training/trainingsubheader'
import Subheader from "../components/whoweare/whowearenav"
import NewImage from "../components/assests/images/indo/image-min.jpg"
import Historysinga from "../components/history/newhistory"
import HistoryImage from "../components/assests/images/indo/vin-fotor-2023121817264.jpg"
import Pradhan from "../components/assests/images/indo/vintage.jpg"
// import SecondPara from "../components/tourism/lastsatvikbuddha"
// import NewHistory from "../components/assests/images/indo/one-two-min.jpg"
// import Historycomponent from '../components/history/history'
// import Futurecontents from "../components/future/future"
import Bussiness from "../components/bussiness/bussiness"
import Country from "../components/operatingcountry/country"
import FirstPointBusiness from "../components/tourism/lastsatvikbuddha"
import Futureone from "../components/assests/images/indo/futureone.jpg"
import NewFuture from "../components/tourism/buddhaimpact"
import Bussinesspoints from "../components/tourism/imptourpoint"
import Artmin from "../components/assests/images/indo/content.-min.jpg"


const Ourcompany = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const newLocation = useLocation();
  const exactLocation = newLocation.pathname;
  // console.log(exactLocation)

  const [location, setlocation] = useState(false);
  const [future, setfuture] = useState(false);
  const [bussines, setbussiness] = useState(false)
  const [country, setcountry] = useState(false)


  useEffect(() => {
    if (exactLocation === "/ourhistory") {
      setlocation(true)
      setfuture(false)
      setbussiness(false)
    } else if (exactLocation === "/ourfuture") {
      setfuture(true)
      setlocation(false)
      setbussiness(false)
    } else if (exactLocation === "/bussiness") {
      setbussiness(true)
      setlocation(false)
      setfuture(false)
      setcountry(false)

    } else if (exactLocation === "/operatingcountry") {
      setbussiness(false)
      setlocation(false)
      setfuture(false)
      setcountry(true)

    }

    else {
      setlocation(true)
    }
  }, [exactLocation])


  const handleLocationClick = (historyvalue) => {
    setlocation(historyvalue)
    setbussiness(false)
    setfuture(false)
    setcountry(false)
  }


  const handleLocationClickteam = (futurevalue) => {
    setfuture(futurevalue)
    setbussiness(false)
    setlocation(false)
    setcountry(false)

  }


  const handleLocationClickservice = (countryvalue) => {
    setcountry(countryvalue)
    setbussiness(false)
    setfuture(false)
    setlocation(false)
  }

  const representaivevalue = (bussinessvalue) => {
    setbussiness(bussinessvalue)
    setfuture(false)
    setlocation(false)
    setcountry(false)
  }



  const props = {
    titleone: "Our Company",
    Imgurl: NewImage,
  }



  const subheadernav = {
    one: "",
    two: "",
    three: "",
    four: ""
  }

  const NewdataBuddhism = {
    titleonebuddha: "Our History",
    contentonebuddha: "Sattvik Certifications, founded by Mr. Abhishek Biswas, emerged as a response to the transformative changes in the food industry during the early 2000s, particularly in the realm of food processing. Recognizing the need for assurance in food safety for vegetarian, vegan, Jain, Sattvic, and Buddhist consumers worldwide, Mr. Biswas initiated a comprehensive effort to establish standards and certification in the vegetarian food segment. His dedication led to extensive research and the authoring of research papers, along with seminars to promote awareness of vegetarianism in Thailand's universities. This collaborative endeavour with scholars and researchers eventually shaped the foundation of Sattvik Certification. With six years of rigorous research behind them, Sattvik was officially established in India in 2015, taking advantage of the country's deep-rooted cultural and religious affinity for vegetarianism. ",
    contentwobuddha: "Sattvik has made significant strides in spreading awareness about vegetarianism/veganism, conducting seminars in universities worldwide, and promoting incubation programs in numerous colleges. In 2018, Sattvik achieved a major milestone by launching its first manual on Sattvik Quality Management, further advancing its mission and concepts.",
    contenthreebuddha: "In 2020, Sattvik achieved a significant milestone by re-registering itself at the national level and rebranded as the Sattvik Council of India. This transformation marked a pivotal moment in the organisation's journey. Alongside this transition, Sattvik unveiled its second manual, which places a strong emphasis on quality kitchen management for vegetarian practices. This manual provides a comprehensive guide to kitchen management systems that align with Sattvik principles, ensuring that kitchens operate in accordance with the organisation's values and standards. This step further solidified Sattvik Council of India's commitment to promoting and upholding vegetarian principles in kitchen operations and management.In 2021, Sattvik Certifications marked a significant expansion of its reach by extending its services to New York, Canada, and South Africa. This expansion represented a notable step in making Sattvik Certifications accessible to a broader international audience. Furthermore, Sattvik achieved a major milestone by establishing its office in Singapore, which serves as the registered office and headquarters for South East Asia. This move positioned Sattvik to better serve the South East Asian region and expand its presence in the global market.",
    Imagedatabuddha: Pradhan,
    bgbuddha: HistoryImage,
    newcolor: 'black'
  }

  // const secondparadata = {
  //   headingone: "",
  //   headingtwo: "",
  //   headingfuturetitle: "",
  //   contentsecondpara: "history-data",
  //   contentnewpara: "",
  //   ImageOne: NewHistory
  // }


  const futureContent = {
    headingone: "Embracing the Future: Revolutionizing Food Certification",
    headingtwo: "Introducing World’s first code for Buddhist Community",
    headingfuturetitle: "Our Future-one",
    contentsecondpara: "future-embracing",
    contentnewpara: "font-buddha",
    ImageOne: Futureone
  }

  const OurFuture = [

    {
      title: "Our Vision for 2023 and beyond",
      content: "ourfutureoneT1",
    },

    {
      title: "Embracing the Future: Revolutionising Food Certification",
      content: "ourfutureoneT2",

    },

    {
      title: "Looking to the Future",
      content: "ourfutureoneT3",
    },

    {
      title: "Creating Global Impact and Sustainable Development",
      content: "ourfutureoneT4",
    },

    {
      title: "The importance of Diversity and Inclusiveness",
      content: "ourfutureoneT5",
    },

    {
      title: "Amplifying Social Impact through Sattvik Certifications Ripple’s ",
      content: "ourfutureoneT6",

    },
  ]

  const OurBussiness = [

    {
      title: "Shaping a World of Trust by Ensuring 100% Vegetarian & Vegan Food & Lifestyle",
      heading: "BussinessB1",
    },

    {
      title: "Vegetarianism/Veganism- Should we rethink its role in Sustainability",
      heading: "BussinessB2",

    },

    {
      title: "Creating Value for Stakeholders",
      heading: "BussinessB3",

    },

    {
      title: "Knowledge Transfer",
      heading: "BussinessB4",

    },

  ]

  const Impacttourismbackone = {
    BuddhismImg: Artmin,
  }



  return (
    <>

      <section>
        <Compsubheader Props={props} />
      </section>
      <section>
        <Subheader subheadernav={subheadernav} handleLocationClick={handleLocationClick} handleLocationClickteam={handleLocationClickteam} representaivevalue={representaivevalue} handleLocationClickservice={handleLocationClickservice} />
      </section>

      <section>
        {location && <Historysinga NewdataBuddhism={NewdataBuddhism} />}
      </section>

      {/* <section>
        {location && <SecondPara secondparadata={secondparadata} />}
      </section> */}

      {/* <section>
        {location && <Historycomponent />}
      </section> */}

      <section>
        {/* {future && <Futurecontents />} */}
        {future && <FirstPointBusiness secondparadata={futureContent} />}
        {future && <NewFuture Buddha={OurFuture} />}
      </section>

      <section>
        {bussines && <Bussiness />}
        {bussines && <Bussinesspoints Impacttourism={OurBussiness} Impacttourismback={Impacttourismbackone} />}
      </section>

      <section>
        {country && <Country />}
      </section>
    </>
  )
}
export default Ourcompany