import React from 'react';
import logo from "../assests/images/logo/logo.png"
import "../assests/css/bot/bot.css"

const Avatar = () => {
  return (
    <div className="imgaeclass">
      <img className = "image-custom" src={logo} alt='SAT' />
    </div>
  );
};

export default Avatar;