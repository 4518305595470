import React from 'react'
import "../assests/css/codex/codex.css"

const Trainingdata = () => {

  return (
    <>
    <h1 className='color-code-code'>Training in hotel/establishment</h1>
   <p> </p>
   </>
  )
}

export default Trainingdata