import React , {useEffect} from 'react';
import "../assests/css/card/card.css";
import vitur from "../assests/images/founder/vitur.jpg";
// import Purnima from "../assests/images/founder/purnima.jpg"
// import Purva from "../assests/images/founder/purva.jpg"
import Parul from "../assests/images/founder/parul.png"
import Annu from "../assests/images/founder/annu-min-removebg-preview.png"
import Babus from "../assests/images/founder/babusnew-removebg-preview (1).png"
import { NavLink } from 'react-router-dom';
import AOS from "aos"
import 'aos/dist/aos.css'

const Card = () => {
  useEffect(()=>{
  AOS.init({duration:2000})
  },[])
 
  const teamMembers = [
    
    // {
    //   Image:Purva,
    //   Designation: "International Head",
    //   Name: "Purva Sharma",
    //   description: "Sattvik stands a special place in my heart professionally and personally. It has always allow me to expand my horizon.",
    //   linkedin:"https://www.linkedin.com/in/purva-sharma-b252a1189?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    //   gmail:"purva@sattvikcouncilofindia.org",
    //   twitter:"#",
    //   instagram:"#",
    // },

   

    // {
    //   Image:Purnima,
    //   Designation: "Marketing & PR Head",
    //   Name: "Purnima Rawat",
    //   description: "SCI is a unification of strong talent pool, seasoned industry experts and world-class infrastructure to build high-impact analysis in Vegetarian Food & Lifestyle.",
    //   linkedin:"https://www.linkedin.com/in/purnima-rawat-311298204?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    //   gmail:"pr@sattvikcouncilofindia.org",
    //   twitter:"#",
    //   instagram:"#",
    // },


   

    {
      Image:Parul,
      Designation: "Training & Education Associate",
      Name: "Parul Singh Maurya ",
      description: "As a leading certification body, we understand your development needs, creating training programmes to align with and meet your strategic objectives.",
      linkedin:"https://www.linkedin.com/in/ft-parul-maurya-63876a185?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      gmail:"parul@sattvikcouncilofindia.org",
      twitter:"#",
      instagram:"#",
    },


    {
      Image:vitur,
      Designation: "Analyst",
      Name: "Viturv Pathak",
      description: "Sattvik is not just a symbol of food purity but also a symbol of lifestyle.",
      linkedin:"#",
      gmail:"viturv@sattvikcouncilofindia.org",
      twitter:"#",
      instagram:"#",
    },

    {
      Image:Annu,
      Designation: "Research Analyst & Lead Auditor",
      Name: "Annu Kadyan",
      description: "Sattvik Certifications is a unification of a strong talent pool, seasoned industry experts and world-class infrastructure.",
      linkedin:"https://www.linkedin.com/in/annu-kadyan-676221275?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      gmail:"annu@sattvikcouncilofindia.org",
      twitter:"#",
      instagram:"#",
    },

    {
      Image:Babus,
      Designation: "Director",
      Name: "Mr. Bagus Kestiadi",
      description: "Sattvik Certifications is a unification of a strong talent pool, seasoned industry experts and world-class infrastructure.",
      linkedin:" https://www.linkedin.com/in/muhammad-bagus-kestiadi-9609b565/",
      gmail:"#",
      twitter:"#",
      instagram:"#",
    },


   
  ];

  return (
    <>
 <div className="container-fluid p-5 text-dark text-center">
        <h1 className="whoweare-css-heading" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Meet the team of Sattvik Council of Singapore</h1>
        <p className="whoweare-css-heading" data-aos="fade-up" data-aos-anchor-placement="top-bottom">A perfect blend of creativity and technical wizardry</p>
      </div>

      <div className='container'>
        <div className="row new-row-class">
          {teamMembers.map((member, index) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
              <div className="card new-card-one cardstyle">
                <img src={member.Image} loading="auto" className="card-img-top image-size" alt="error" />
                <div className="card-body">
                  <p className="text-center custom-analyst">{member.Designation}</p>
                  <h5 className="card-title name-desing text-center">{member.Name}</h5>
                  <p className="card-text custom-margin text-center">{member.description}</p>
                </div>
                <ul className="d-flex justify-content-center custom-css">
                <a  href={`mailto:${member.gmail}`}>
                  <li className="check-padding"><i class="ri-google-fill"></i></li>
                  </a>
                
                  <NavLink to={member.linkedin}>
                  <li className="check-padding"><i className="ri-linkedin-fill"></i></li>
                  </NavLink>
                  {/* <NavLink to={(member.twitter)}>
                  <li className="check-padding"><i className="ri-twitter-fill"></i></li>
                  </NavLink> */}

                  <NavLink to={member.instagram} >
                  <li className="check-padding"><i className="ri-instagram-fill"></i></li>
                 </NavLink>
                  
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Card;
