import React, { useState } from 'react'
import "../assests/css/contact/contact.css"
import Pop from '../pop/pop'

const Contact = () => {

  const [form, setForm] = useState({})
  const [formdisplay, setFormDisplay] = useState(true)
  const [popupDisplay, setpopupDisplay] = useState(false)


  const handleform = async (e) => {
    e.preventDefault()
    // https://api.sattvikcertifications.id/api/formdata
    const res = await fetch('https://api.scsg.sg/api/singa/formdata', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    // const data = await res.text()
    // console.log(data)

    if (res.status === 200) {
      // Email sent successfully
      setFormDisplay(false);
      setpopupDisplay(true);
    } else {
      // Handle email sending failure
      setFormDisplay(true);
      setpopupDisplay(false);
    }
  }

  const handlechange = (e) => {
    // console.log(e.target.value , e.target.name)
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }
  return (

    <>
      {formdisplay &&
        <div >
          <h2 className="common-heading text-center">Contact Us</h2>
          <div className="new-column">
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7903252919723!2d103.84446578477595!3d1.3006663583633835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19bc8a338d27%3A0xc1bfd283b2585ce4!2s35%20Selegie%20Rd%2C%20%2309%2002%2C%20Singapore%20188307!5e0!3m2!1sen!2sin!4v1703156446299!5m2!1sen!2sin" title="Example Website" width="100%" height="550" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
            <div>
              <h2 className="common-heading text-center">For more query fill the form</h2>
            </div>

            <div className="container input-margin-top new-form-contact " >
              <div className="contact-form">
                <form className="contact-inputs" onSubmit={handleform}>
                  <input type="text" placeholder="Company Name" name="companyname" required autoComplete="off" onChange={handlechange} />
                  <input type="text" placeholder="Office Address" name="address" required autoComplete="off" onChange={handlechange} />
                  <input type="email" name="Email" placeholder="Email" autoComplete="off" onChange={handlechange} required />
                  <input type="text" name="url" id="url" placeholder="Website Url" size="70" autoComplete="off" onChange={handlechange} />

                  <input type="text" placeholder="Your Name" name="name" autoComplete="off" onChange={handlechange} required />
                  <input type="text" placeholder="Designation" name="designation" autoComplete="off" onChange={handlechange} required />
                  <input type="number" name="contactnumber" placeholder="Contact Number" autoComplete="off" required size="15" onChange={handlechange} />
                  <input type="number" name="Altnumber" placeholder="Alternative Number" autoComplete="off" size="15" onChange={handlechange} />
                  <select className="select-industry" name='industry' required onChange={handlechange}>
                    <option >Select Industry</option>
                    <option value="Food">Food</option>
                    <option value="Hospitality">Hospitality</option>
                    <option value="Marines">Marines</option>
                    <option value="Railway">Railway</option>
                    <option value="Agriculture">Agriculture</option>
                    <option value="Dairy">Dairy</option>
                    <option value="Cosmetics">Cosmetics</option>
                    <option value="Textiles">Textiles</option>
                    <option value="Nutraceutical">Nutraceutical</option>
                  </select>

                  <select className="select-industry" name='certification' required onChange={handlechange}>
                    <option >Select-Certfication-Code</option>
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Vegan">Vegan</option>
                    <option value="Jain">Jain</option>
                    <option value="Sattvam">Sattvam</option>
                    <option value="Budhist">Budhist</option>
                    <option value="RTE">RTE(Ready to eat)</option>
                    <option value="Cosmetics">Ayurvedic</option>
                    <option value="Textiles">Organic</option>
                  </select>

                  <select className="select-industry" name="plant" required onChange={handlechange}>
                    <option >Number-Of-Plant</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="More then 5">More then 5</option>
                  </select>
                  <textarea name="Messageone" cols="30" rows="5" required autoComplete="off" placeholder="Please describe the products, activities and services of your company" onChange={handlechange}></textarea>
                  <textarea name="Messagetwo" cols="30" rows="5" autoComplete="off" placeholder="Does manufacturing Plant process Egg ingredients, Meat ingredients, Alcohol ingredients,Fish  ingredients, Nicotine/Prohibited substances" onChange={handlechange}></textarea>
                  <textarea name="Messagethree" cols="30" rows="5" autoComplete="off" placeholder="References" onChange={handlechange}></textarea>
                  <button className='btn btn-dark text-size-weight'>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
      {popupDisplay && <Pop />}
    </>


  )
}

export default Contact