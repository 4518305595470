import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  // console.log(children)


  const Name = () => {
    const botMessage = createChatBotMessage('Hi there! What is your Name , Mobile Number & Email?');
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }


  const handleHello = () => {
    const botMessage = createChatBotMessage('How can we make your experience with Sattvik better?(Please mention Sattvik)');
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };


  const handleDog = () => {
    const botMessage = createChatBotMessage("Thank you for contacting us!We will get back to you soon!");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleAreYou = () => {
    const botMessage = createChatBotMessage("Are you looking for any particular certification ?",
      {
        widget: "Button"
      }

    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }


  const handleYesNo = (messagenew) => {
    // console.log(messagenew)
    const botMessage = createChatBotMessage("Do you have any questions about our other services?",
      {
        widget: "ButtonNew"
      });


    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
      condition: messagenew

    }));
  }



  const handleYesNoServices = (newvalue) => {
    // console.log(newvalue)
    const botMessage = createChatBotMessage("Experiencing technical issues? Mail us on :- info@scsg.sg");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
      conditionnew: newvalue
    }));
  }

  const handleValue = () => {
    const botMessage = createChatBotMessage("We value your feedback! Is there anything you'd like to share about your experience with our products or services? Mail us on :- info@scsg.sg or Contact Us on :- +6593861039");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const handleValuelast = () => {
    const botMessage = createChatBotMessage("Click the below Link In order to send the Response",
      {
        widget: "ButtonSendEmail"
      }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }


  const ThankuforyourResponse = () => {
    const botMessage = createChatBotMessage("Thankyou for Your response ! We will reply to your response Soon!",

    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }



  const ThankuforyourResponseNo = () => {
    const botMessage = createChatBotMessage("Thank You! For your time");
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            Name,
            handleHello,
            handleDog,
            handleAreYou,
            handleYesNo,
            handleYesNoServices,
            handleValue,
            handleValuelast,
            children,
            ThankuforyourResponse,
            ThankuforyourResponseNo,


          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
