import React from 'react'
import "../assests/css/tourism/impacttourism.css"


const Impacttourism = ({Propsdata}) => {

    const divContainerStyle = {
        backgroundImage: `url(${Propsdata.bgimage})`, // Corrected backgroundImage property and template literal
        backgroundAttachment: 'fixed', // Fixed backgroundAttachment property
        backgroundRepeat: 'no-repeat', // Fixed backgroundRepeat property
        backgroundSize: 'cover', // Fixed backgroundSize property
    };
   

    return (
        <div className="container-fluid p-2 my-5   text-white custom-data text-center"  style={divContainerStyle}>
            <h1 className="tour-one">{Propsdata.headingdata}</h1>
            <p className="tour-second">{Propsdata.content} <span className='para-color'>{Propsdata.secondcontent}</span></p>
        </div>
    )
}

export default Impacttourism