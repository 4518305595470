import React from 'react'
import "../assests/css/codex/codex.css"

const Madhya = () => {
 
  return (
    <>
    <div>
    <h1 className = "color-code">Laghu :</h1>
    <p className='color-code-new'>Laghu Kitchen are found in large hotels, restaurants, private clubs, catering and banquet services and full service restaurants. It has an area of 300-1000 Sq. ft., capacity of 100 above meals per day, working hours from 8AM to 10 PM and less than 50 employees.</p>
    </div>
 </>
 )
}

export default Madhya