import React from 'react'
import "../assests/css/brand/brand.css"
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'


const SubBrands = ({ Audit, AuditHeading }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* <div className="container-fluid p-5  text-dark text-center">
      <h1 className='brand-heading-content'>Our Global Sattvik Certified Brands</h1>
    </div> */}

      <div className="container-fluid p-5 text-dark text-center">
        <h1 className='brand-heading-content'>{AuditHeading.titleoneheading}</h1>
      </div>

      <div className="container-fluid new-brand-container mt-5">
        <div className="row brand-row-group">
          {Audit.map((brandItem) => (
              <div className="col-sm-3 brandclass">
              <NavLink to={brandItem.link} key={brandItem.id}>
                <img src={brandItem.imageone} loading="auto" className='brand-css-image' alt="error" />
                </NavLink>
              </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SubBrands