import React, { useEffect } from 'react'
import "../assests/css/privacy/privacy.css"





const LegalNotice = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="container main-container-privacy">
        <div className="container-fluid margin-top-merge">
          <h1 className="privacy-h1-indo">Sattvik Certifications Singapore</h1>
          <p className='subheadin-address-pri'>Address - 35 Selegie Road
            T# 09-02, Parklane Shopping Mall , Singapore 188 307</p>
          <p className='email-privacy'>Email-
            <a href="mailto:info@scsg.sg">info@scsg.sg</a>
          </p>
          <p className='subheadin-address-pri'>All rights reserved</p>
          <h3 className='privacy-pt'>Sattvik Certifications Singapore</h3>
          <hr />

          <h3 className='privacy-pt-two'>RESPONSIBILITY FOR THIS WEBSITE</h3>
          <p className='privacy-pt-two'>Sattvik Certifications Singapore</p>
          <p className='italic-today-where'>N.B.: This website has been designed with utmost care. Nevertheless we cannot guarantee the accuracy of all services and information contained therein. We assume no liability of any kind for direct or indirect damage resulting from the use of this website, unless such liability is compulsory under statute law.</p>

          <p className='italic-today-where'>All texts, images and graphics and their positioning on our websites are protected by copyright and other intellectual property rights.</p>


          <p className='italic-today-where'>The reproduction, distribution, modification or public display of the contents of these websites for commercial purposes shall be prohibited.</p>



          <p className='italic-today-where'>Sattvik Certifications Singapore has no influence on the contents of third-party sites that can be accessed from the Sattvik Certifications Singapore website via hyperlinks and does not assume any responsibility for such contents. It expressly distances itself from any unlawful or ambiguous third-party contents.</p>

          <h3 className="copyrigth-ptone">© Copyright PT Sattvik Certifications Singapore</h3>
        </div>
      </div>
    </>
  )
}

export default LegalNotice